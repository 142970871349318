html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
    background-color: var(--color-light-grey);
    transition: background-color 0.6s ease-out, color 0.6s ease-out; /*needed for experience-area */
    will-change: background-color, color;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}

.main-content--no-hero{
    padding-top: calc(20rem/16);
    @media screen and (min-width: 768px){
       padding-top: calc(100rem/16);
    }
}
.main-content--no-hero-small {
    padding-top: calc(20rem/16);
    @media screen and (min-width: 768px){
        padding-top: calc(70rem/16);
    }
}
.main-content--btob {
    padding-top: 10vh;
    padding-bottom: 15vh;
    min-height: calc(100vh - calc(105rem/16));
    @media screen and (min-width: 768px){
        min-height: calc(100vh - calc(51rem/16));
        padding-top: 25vh;
    }
}
.main-content--full-height {
    @media screen and (min-width: 768px){
        min-height: calc(100vh - calc(252rem/16));
    }
}
.main-content--btob-bg {
    @media screen and (min-width: 768px){
        background: url('/static/img/btob/backgroundimage-b2b.jpg');
        background-size: cover;
        margin-top: calc(-100rem/16);
    }
}
.container-outer{
    overflow: hidden;
    
    @media screen and (max-width: 767px){
       padding-bottom: calc(60rem/16); /* because of sticky console mobile */
    }
}

.container.container {
    max-width: calc(1340rem/16); /* 1310px breite + 30px  padding */
    width: 100%;
}

.container-xx-large {
    max-width: calc(1620rem/16);
    width: 100%;
    margin: 0 auto;
    padding-left: calc(15rem/16);
    padding-right: calc(15rem/16);

    @media screen and (min-width: 768px){
        padding-left: calc(30rem/16);
        padding-right: calc(30rem/16);
    }
}

.container-x-large {
    max-width: calc(1265rem/16);
    width: 100%;
    margin: 0 auto;
    padding-left: calc(15rem/16);
    padding-right: calc(15rem/16);

    @media screen and (min-width: 768px){
        padding-left: calc(30rem/16);
        padding-right: calc(30rem/16);
    }
}

.container-large {
    max-width: calc(1147rem/16);
    width: 100%;
    margin: 0 auto;
    padding-left: calc(15rem/16);
    padding-right: calc(15rem/16);

    @media screen and (min-width: 768px){
        padding-left: calc(30rem/16);
        padding-right: calc(30rem/16);
    }
}

.container-x-medium{
    max-width: calc(1032rem/16); /* 972px breite  */
    width: 100%;
    margin: 0 auto;
    padding-left: calc(15rem/16);
    padding-right: calc(15rem/16);

    @media screen and (min-width: 768px){
        padding-left: calc(30rem/16);
        padding-right: calc(30rem/16);
    }
}

.container-medium{
    max-width: calc(870rem/16); /* 840px breite + 30px padding */
    width: 100%;
    margin: 0 auto;
    padding-left: calc(15rem/16);
    padding-right: calc(15rem/16);

    @media screen and (min-width: 768px){
        padding-left: calc(30rem/16);
        padding-right: calc(30rem/16);
    }
}

.container-narrow{
    max-width: calc(670rem/16); /* 640px breite + 30px padding */
    width: 100%;
    margin: 0 auto;
    padding-left: calc(15rem/16);
    padding-right: calc(15rem/16);

    @media screen and (min-width: 768px){
        padding-left: calc(30rem/16);
        padding-right: calc(30rem/16);
    }
}


/* For mobile gutter */
:root{
    --bs-gutter-x: calc(15rem/16);
}

.row{
    @media screen and (max-width: 767px){
        --bs-gutter-x: 10px;
        --bs-gutter-y: 0;
       /*margin-left: calc(-5rem/16);
       margin-right: calc(-5rem/16);*/
    }
}

.row>*{
    @media screen and (max-width: 767px){
       padding-left: calc(var(--bs-gutter-x)/2);
       padding-right: calc(var(--bs-gutter-x)/2);
    }
}


.js-fade-in {will-change: opacity;}
.js-parallax {will-change: transform}