.ct-series-a .ct-bar, .ct-series-a .ct-line, .ct-series-a .ct-point, .ct-series-a .ct-slice-donut {
    stroke: var(--color-default-dark-grey);
}
.ct-series-b .ct-bar, .ct-series-b .ct-line, .ct-series-b .ct-point, .ct-series-b .ct-slice-donut {
    stroke: var(--color-turquoise);
}
.ct-circle-label {
    fill:#fff;
    font-size: calc(12rem/16);
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size: calc(10rem/16);
    }
}
.ct-horizontal {
    display:none;
}
.ct-grid {
    stroke-dasharray: 0;
    stroke: rgba(0,0,0,.1);
    stroke-width: calc(0.7rem/16);
}
.ct-series-b .ct-point.ct-circle {
    stroke: var(--color-turquoise-dark);
    stroke-width: calc(2rem/16);
    box-shadow: calc(5rem/16) calc(5rem/16) calc(10rem/16) rgba(0,0,0,0.39);
}
.ct-point.ct-circle,  .ct-series-a .ct-point.ct-circle {
    stroke: var(--color-default-dark-grey);
    stroke-width: calc(2rem/16);
    box-shadow: calc(5rem/16) calc(5rem/16) calc(10rem/16) rgba(0,0,0,0.39);
}