.authors-row .author-teaser{
    width: calc(150rem/16);
    padding: 0 calc(9rem/16);

    @media screen and (min-width: 768px){
        width: calc(168rem/16);
    }
}

.author-teaser__img,
.author-teaser__img-wrapper{
    border-radius: 50%;
    transition: transform 150ms ease;
    max-width: calc(150rem/16);
    margin: 0 auto;
}

@media screen and (min-width: 768px){
    .author-teaser:hover .author-teaser__img-wrapper{
        transform: translateY(calc(-5rem/16));
    }
}


.author-teaser__title{
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    margin-bottom: calc(5rem/16);
    margin-top: calc(18rem/16);
    text-align: center;

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
    }
}

.author-teaser__text{
    font-size: calc(11rem/16);
    text-transform: uppercase;
    letter-spacing: calc(2rem/16);

    @media screen and (min-width: 768px){
        letter-spacing: calc(3rem/16);
        font-size: calc(12rem/16);
    }
}

.author-teaser__icon{
    margin-right: calc(12rem/16);
    position: relative;
    top: calc(-1rem/16);
    font-size: calc(15rem/16);
}

/*
.single-author .author-teaser{
    @media screen and (min-width: 768px){
       width: calc(270rem/16);
        margin: 0 auto;
    }
}*/

.scrolling-area--author-teaser{
    @media screen and (min-width: 768px){
       max-width: calc(1000rem/16);
        margin: 0 auto;
    }
}

.scrolling-area--author-teaser .scrolling-area__arrows{
    @media screen and (min-width: 768px){
       display: none!important; /* because of d-flex important */
    }
}

.scrolling-area--author-teaser.show-arrows .scrolling-area__arrows{
    @media screen and (min-width: 768px){
        display: flex!important; /* because of d-flex important */
    }
}