.text-img-grid-gallery__title{
    margin-bottom: calc(29rem/16);

    @media screen and (min-width: 768px){
        line-height: calc(55/60);
        font-size: clamp(calc(40rem/16), 4vw, calc(52rem/16));
        letter-spacing: clamp(calc(7rem/16), 0.9vw, calc(11rem/16));
    }

}

.text-img-grid-gallery__toptitle{
    margin-bottom: calc(15rem/16);
}