.scrolling-area {
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: calc(4rem / 16);
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
}

@media screen and (min-width: 768px){
    .scrolling-area--xs {
        overflow-y: unset;
        overflow-x: unset;
    }
}
.scrolling-area::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 calc(8rem / 16) rgba(0, 0, 0, 0.2);
    background-color: transparent;
    transition: all 120ms ease;
    will-change: scroll-position;
}

.scrolling-area--xs::-webkit-scrollbar,
.scrolling-area::-webkit-scrollbar {
    width: calc(4rem / 16);
    height: calc(4rem / 16);
    background: var(--color-light-grey);
    transition: all 120ms ease;
    will-change: scroll-position;
}

.scrolling-area--xs::-webkit-scrollbar-thumb,
.scrolling-area::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    transition: all 120ms ease;
    will-change: scroll-position;
}

@media screen and (max-width: 767px) {
    .scrolling-area--xs {
        overflow-y: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;
    }
    /* .container .scrolling-area--xs {
         margin-left: calc(-20rem / 16);
         margin-right: calc(-20rem / 16);
         padding-left: calc(20rem / 16);
         padding-right: calc(20rem / 16);
     }*/
}

.scrolling-area--slider, .scrolling-area--move-left {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.scrolling-area--slider::-webkit-scrollbar, .scrolling-area--move-left::-webkit-scrollbar {
    display: none;
}
.scrolling-area--slider, .scrolling-area--move-left {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 0;
    position: relative;
}
.scrolling-area__item {
    /*width: 100%;*/
    flex-shrink: 0;
    scroll-snap-align: start;
}
.scrolling-area__arrows {
    display: flex;
}
.scrolling-area__arrow{
    background-color: var(--color-dark);
    color: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: calc(40rem/16);
    height: calc(40rem/16);
    font-size: calc(9rem/16);
    transition: all 150ms ease;

    @media screen and (min-width: 1200px){
       width: calc(80rem/16);
       height: calc(80rem/16);
        font-size: calc(14rem/16);
    }

}
.scrolling-area__arrow:focus{
    outline: 0;
}

.scrolling-area__arrow[disabled] {
    opacity: .7;
}

.scrolling-area__arrow:not([disabled]):hover{
    background-color: var(--color-primary);
}

.scrolling-area__arrow--next{
    border-left: 1px solid #424242;
}
.scrolling-area__nav__dot + .scrolling-area__nav__dot {
    margin-left: calc(4rem / 16);
}
.scrolling-area__nav__dot {
    width: calc(8rem / 16);
    height: calc(8rem / 16);
    background: #000;
    border: 0;
    box-shadow: none;
    border-radius: 50%;
    flex-shrink: 0;
    padding: 0;
}
.scrolling-area__nav__dot.is-active {
    background: var(--color-primary);
}

.scrolling-area__counter{
    text-align: right;
    padding: calc(5rem/16) calc(7rem/16);
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;

    @media screen and (min-width: 768px){
        padding: calc(13rem/16) calc(25rem/16);
    }
}
.scrolling-area__counter-current{
    font-family: var(--font-default-bold);
    font-size: calc(15rem/16);
    line-height: calc(32/25);
    display: block;
    position: relative;
    margin-bottom: calc(3rem/16);
    white-space: nowrap;

    @media screen and (min-width: 768px){
        font-size: calc(25rem/16);
    }
}

.scrolling-area__counter-current:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(1rem/16);
    right: calc(1rem/16);
    height: calc(2rem/16);
    background-color: var(--color-primary);

    @media screen and (min-width: 768px){
        bottom: calc(2rem/16);
        height: calc(3rem/16);
    }
}

.scrolling-area__counter-total{
    font-size: calc(12rem/16);
    line-height: calc(18/14);
    white-space: nowrap;
    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
    }
}

.scrolling-area__caption{
    color: #A2A1A1;
    text-transform: uppercase;
    font-size: calc(9rem/16);
    letter-spacing: calc(2rem/16);
    margin-top: calc(8rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(15rem/16);
        font-size: calc(10rem/16);
        letter-spacing: calc(2.5rem/16);
    }
}

.scrolling-vertical-centered-arrows {
    position: relative;
    @media screen and (max-width: 1399px) {
        padding-left: calc(15rem/16);
        padding-right: calc(15rem/16);
    }
}
@media screen and (min-width: 768px) {
    .scrolling-vertical-centered-arrows .scrolling-area {
        position: relative;
        padding-bottom: calc(70rem / 16);
    }
    .scrolling-vertical-centered-arrows .scrolling-area__arrow--prev {
        position: absolute;
        left: 0;
        top: calc(50% - 50px);
        transform: translateY(-50%);
        z-index: 9;
    }
    .scrolling-vertical-centered-arrows .scrolling-area__arrow--next {
        position: absolute;
        right: 0;
        top: calc(50% - 50px);
        transform: translateY(-50%);
        z-index: 9;
    }
}
@media screen and (min-width: 1400px) {
    .scrolling-vertical-centered-arrows .scrolling-area__arrow:disabled {
        opacity: 0;
    }
    .scrolling-area--move-left {
        justify-content: flex-start;
        overflow-y:initial;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .scrolling-area--move-left .scrolling-area__item:first-of-type {
        margin-left: calc((100vw - 1340px) / 2 + 10px);
    }
    .scrolling-area--move-left .scrolling-area__item:last-of-type {
        margin-right: calc((100vw - 1340px) / 2 + 10px);
    }

    .scrolling-area--move-left .scrolling-area__item {
        display: inline-block;
        float: none;
        white-space: normal;
        scroll-snap-align: initial;
    }

    .scrolling-area-fade {
        position: relative;
    }

    .scrolling-area-fade:before {
        content: '';
        left: 0;
        top: 0;
        position: absolute;
        width: calc(300rem / 16);
        height: 100%;
        z-index: 2;
        transform: scale(-1);
        pointer-events: none;
        background: linear-gradient(90deg, rgba(240, 240, 240, 0) 0%, #F0F0F0 100%);
    }

    .scrolling-area-fade:after {
        content: '';
        right: 0;
        top: 0;
        position: absolute;
        width: calc(300rem / 16);
        pointer-events: none;
        height: 100%;
        background: linear-gradient(90deg, rgba(240, 240, 240, 0) 0%, #F0F0F0 100%);
    }


}