.form-collapse-btn{
    border: none;
    background-color: #fff;
    padding: calc(8rem/16) calc(20rem/16);
    height: calc(50rem/16);
    width: 100%;
    text-align: left;
    position: relative;
}

.form-collapse-btn__icon.icon{
    font-size: calc(9rem/16);
    position: absolute;
    right: calc(20rem/16);
    top: calc(20rem/16);
    color: var(--color-dark);
    transform: rotate(180deg);
}

.collapsed .form-collapse-btn__icon.icon{
    transform: rotate(0deg);
}

.form-collapse .card{
    border: none;
}