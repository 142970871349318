.step-nav {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: calc(60rem/16) calc(20rem/16) calc(20rem/16);
    @media screen and (min-width: 768px) {
        margin: calc(40rem/16) auto;
        max-width: calc(520rem/16);
    }
}
.step-nav--checkout {
    margin: calc(10rem/16) auto calc(15rem/16);
    @media screen and (min-width: 768px) {
        margin: calc(20rem/16) auto;
        max-width: calc(520rem/16);
    }
}
.step-nav__counter {
    height: calc(16rem/16);
    width: calc(16rem/16);
    display: flex;
    justify-content: center;
    align-items:center;
    font-size: calc(14rem/16);
    margin: 0 auto;
    text-align:center;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.4s ease border-color, 0.4s ease color, 0.4s ease background-color;
    will-change: border-color, color, background-color;
}
.step-nav__item.active .step-nav__counter {
    animation-delay: 0.2s;
    transition: 0.4s ease border-color, 0.4s ease color, 0.4s ease background-color;
    animation: bounce 2s cubic-bezier(0.280, 0.840, 0.420, 1) forwards;
    border-color: var(--color-primary);
    background-color: var(--color-primary);
    color: var(--color-primary);
}
.step-nav.has-error .step-nav__item.active .step-nav__counter {
    border-color: var(--color-danger);
    color: var(--color-danger);
}
.step-nav__item.is-previous .step-nav__counter {
    border-color: var(--color-primary);
    background-color: var(--color-primary);
    color:#fff;
    font-size: calc(8rem/16);
}
.step-nav.has-error .step-nav__item.is-previous .step-nav__counter  {
    border-color: var(--color-danger);
    background-color: var(--color-danger);
}
.step-nav__item:not(:last-of-type):after {
    content:'';
    height: 2px;
    background-color: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    transition: 0.2s ease background-color;
    will-change: background-color;
}
.step-nav__item.is-previous:after {
    background-color: var(--color-primary);
}
.step-nav.has-error .step-nav__item.is-previous:after  {
    background-color: var(--color-danger);
}
.step-nav__text {
    font-size: calc(10rem/16);
    position: absolute;
    left: 0;
    text-align:center;
    width: calc(80rem/16);
    color: var(--color-grey);
    margin-top: calc(35rem/16);
    font-family: var(--font-default-bold);
    line-height: 1;
    margin-left: calc(-30rem/16);
    letter-spacing: calc(2rem/16);
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.step-nav__item {
    position: relative;
}
.step-nav__legend {
    align-self:center;
    border-top: 1px solid var(--color-dark-grey);
}
.step-nav__item:last-of-type .step-nav__counter {
    color:#fff;
    background-color:transparent;
    padding: calc(3rem/16);
    font-size: calc(12rem/16);
    width: calc(20rem/16);
    height: calc(20rem/16);
}
.step-nav__item:not(:last-of-type) {
    display: grid;
    grid-template-columns: calc(32rem/16) minmax(calc(15rem/16), calc(70rem/16));
    @media screen and (min-width: 768px) {
        grid-template-columns: calc(16rem/16) minmax(calc(140rem/16),calc(240rem/16));
    }
}
.step-nav__item:not(:last-of-type) > div:nth-child(1) { -ms-grid-column: 1; }
.step-nav__item:not(:last-of-type) > div:nth-child(2) { -ms-grid-column: 2; }

.step-nav--checkout .step-nav__item:not(:last-of-type) {
    grid-template-columns: calc(32rem/16) calc(70rem/16);
    @media screen and (min-width: 768px) {
        grid-template-columns: calc(16rem/16) minmax(calc(40rem/16),calc(100rem/16));
    }
}
.step-nav--checkout .step-nav__item:first-of-type {
    grid-template-columns: calc(32rem/16) calc(70rem/16);
    @media screen and (min-width: 768px) {
        grid-template-columns: calc(16rem/16) minmax(calc(90rem/16),calc(200rem/16));
    }
}

.step-nav__item.active .step-nav__text {
    color: var(--color-default);
}
.step-nav.has-error .step-nav__item.active .step-nav__text  {
    color: var(--color-danger);
}
.step-nav__item.active:last-of-type .step-nav__counter{
    color: var(--color-primary);
}

.step-nav__btn-title-wrapper{
    position: absolute;
    top: calc(25rem/16);
    color: black;
    left: calc(-22rem/16);
    @media screen and (max-width: 767px) {
        left: calc(12rem/16);
    }

}

.step-nav__btn-title{
    @media screen and (min-width: 768px) {
        max-width: calc(147rem/16);
    }
}


@keyframes bounce {
    0%   { transform: scale(1) }
    40%  { transform: scale(1.3) }
    100% { transform: scale(1) }
}