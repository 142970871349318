/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Text Color Helper */
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-turquoise { color: var(--color-turquoise); }
.text-grey { color: var(--color-grey); }
.text-light-grey {color: var(--color-light-grey);}
.text-red {color: var(--color-primary);}
.text-green {color: var(--color-success);}


.fz-9 {
    font-size: calc(9rem/16);
}
.fz-10 {font-size: calc(10rem/16);}
.fz-12 {font-size: calc(12rem/16);}
.fz-14 {font-size: calc(14rem/16);}
.fz-16 {font-size: calc(16rem/16);}
.fz-18 {font-size: calc(18rem/16);}
.fz-70 {font-size: calc(70rem/16);}

/* Background Color Helper */

.bg-dark-grey{
    background-color: var(--color-dark-grey);
    color: #fff;
}

.bg-light-grey{
    background-color: var(--color-light-grey);
}

.opacity-70 {
    opacity: 0.7;
}
.opacity-50 {
    opacity: 0.5;
}
.opacity-30 {
    opacity: 0.3;
}


/* Additional */

figure{
    margin: 0;
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}

.cursor-pointer {
    cursor: pointer;
}

.pointer-events-auto {
    pointer-events: auto;
}

.letter-spacing-0{
    letter-spacing: 0;
}

.list-md-inline{
    padding-left: 0;
    list-style: none;
}

.list-md-inline .list-inline-item{
    @media screen and (max-width: 767px){
        display: block;
        margin-right: 0;
    }
}

.letter-spacing-2 {letter-spacing: calc(2rem/16) !important;}
.letter-spacing-3 {letter-spacing: calc(3rem/16) !important;}

.legend--no-styling {
    font-family: var(--font-default);
    font-size: var(--font-size-default)
}

.base-price {
    display: inline-block;
    color: var(--color-red-light);
    position: relative;
    white-space: nowrap;
    font-size: calc(14rem/16);
}
.base-price:after {
    border-top: 1px solid var(--color-red-light);
    position: absolute;
    content: "";
    right: 0;
    top:40%;
    left: 0;
}
.discount-badge {
    color: var(--color-red-light);
    font-size: calc(18rem/16);
}

.pointer-events-none{
    pointer-events: none;
}

.pointer-events-auto{
    pointer-events: auto;
}

.alert .close{
    background: transparent;
}

.text-underline{
    text-decoration: underline;
}

.lh-0 { line-height: 0;}


.h-md-100{
    @media screen and (min-width: 768px){
        height: 100%;
    }
}

.min-height--100vh{
    min-height: 100vh;
}
.max-height--100vh{
    max-height: 100vh;
}
.circle--red {
    width: calc(6rem/16);
    height: calc(6rem/16);
    background-color: var(--color-primary);
    border-radius: 50%;
    display: inline-block;
    margin-bottom: calc(3rem/16);
}

.legend-headline {
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    letter-spacing: calc(2rem/16);
}
.hr-light-grey {
    opacity:1;
    color: var(--color-light-grey);
}

.anchor-target:target {
    padding-top: 100px;
    margin-top: -100px;

    @media screen and (min-width: 768px) {
        padding-top: 150px;
        margin-top: -150px;
    }
}

.z-index-1 {
    z-index: 1;
}