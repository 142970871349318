:root{
    --img-box-shadow: 0 23px 27px 0 rgba(25,25,25,0.5);
    --img-box-shadow-xs: 0 23px 27px 0 rgba(25,25,25,0.5);
}

.quote{
    @media screen and (min-width: 768px){
       padding-bottom: calc(40rem/16);
    }
}


.quote__img, .quote__icon-wrapper{
    position: relative;
    z-index: 2;
    border: 10px solid rgba(255,255,255,0.2);
    border-radius: 50%;
    box-shadow: 0 17px 18px 0 rgba(25,25,25,0.2);
    
    @media screen and (max-width: 767px){
       margin-bottom: calc(-55rem/16);
        box-shadow: var(--img-box-shadow-xs);
    }
}

.quote__img{
    box-shadow: none;
}

.quote__img img {
    border-radius: 50%;
    box-shadow: var(--img-box-shadow);
    z-index: 3;
    
    @media screen and (max-width: 767px){
        box-shadow: var(--img-box-shadow-xs);
    }
}

.quote__icon {
    display: flex;
    font-size: calc(100rem/16);
    color: var(--color-secondary);
    z-index: 3;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #FFFFFF 0%, #CFCFCF 100%);

    @media screen and (max-width: 767px){
        font-size: calc(50rem/16);
    }
}

.quote__body{
    background-color: var(--color-dark);
    color: #fff;
    padding: calc(80rem/16) calc(22rem/16) calc(40rem/16);
    position: relative;
    
    @media screen and (min-width: 768px){
        padding: calc(67rem/16) calc(50rem/16) calc(67rem/16) calc(22rem/16);
    }
    
    @media screen and (max-width: 767px){
       text-align: center;
    }
}

.quote-area.is-small .quote__body{
    @media screen and (min-width: 768px){
       padding: calc(35rem/16) calc(30rem/16) calc(45rem/16) calc(20rem/16);
    }
}

.quote__body:before{
    @media screen and (min-width: 768px){
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(1rem/16);
        transform: translateX(-100%);
        background-color: var(--color-dark);
        width: 27.5%;
    }

}

/*

.quote__body:after{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: calc(1rem/16);
    transform: translateX(100%);
    width: calc(10000rem/16);
    background-color: var(--color-dark);
}
*/


.quote__text{
    font-size: calc(25rem/16);
    line-height: calc(51/45);
    font-family: var(--font-default-bold);
    padding-bottom: calc(25rem/16);
    position: relative;

    @media screen and (min-width: 768px){
        font-size: calc(45rem/16);/* fallback */
        font-size: clamp(calc(23rem/16), 3vw, calc(35rem/16));
    }
}

.quote-area.is-small .quote__text{
    @media screen and (min-width: 768px){
       font-size: calc(25rem/16);
        font-size: clamp(calc(19rem/16), 1.5vw, calc(25rem/16));
    }
}

.quote__text:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(4rem/16);
    width: calc(40rem/16);
    background-color: var(--color-primary);

    @media screen and (max-width: 767px){
       left: 50%;
        transform: translateX(-50%);
    }
}

.quote__author{
    font-size: calc(10rem/16);
    letter-spacing: calc(2rem/16);
    text-transform: uppercase;
    margin-top: calc(20rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);
        letter-spacing: calc(3rem/16);
    }
}

.scrolling-area--quote-slider .scrolling-area__arrow{
    background-color: transparent;
    width: calc(50rem/16);
    height: calc(50rem/16);
    font-size:calc(15rem/16);
    color: var(--color-default);
    position: absolute;
    top: calc(85rem/16);

    @media screen and (min-width: 768px){
        top: calc(50% - 20px);
        transform: translateY(-50%);
        font-size:calc(20rem/16);
    }
}

.scrolling-area--quote-slider .scrolling-area__arrow:not([disabled]):hover{
    background-color: transparent;
    color: var(--color-primary);
}

.scrolling-area--quote-slider .scrolling-area__arrow--next{
    border: none;
    right: 0;

    @media screen and (min-width: 1550px){
        right: calc(-80rem/16);
    }
}

.quote-area.is-small .scrolling-area--quote-slider .scrolling-area__arrow--next{
    @media screen and (min-width: 1550px) {
        right: calc(-50rem / 16);
    }
    @media screen and (min-width: 768px){
        font-size: calc(13rem/16);
    }
}

.scrolling-area--quote-slider .scrolling-area__arrow--prev{
    left: 0;

    @media screen and (min-width: 1550px){
        left: calc(-80rem/16);
    }
}

.quote-area.is-small .scrolling-area--quote-slider .scrolling-area__arrow--prev{
    @media screen and (min-width: 1550px){
        left: calc(-50rem/16);
    }
    @media screen and (min-width: 768px){
        font-size: calc(13rem/16);
    }
}

.scrolling-area--quote-slider{
    @media screen and (min-width: 768px) and (max-width: 1550px){
       padding: 0 calc(80rem/16);
    }
}

.quote-area.is-small .scrolling-area--quote-slider{
    @media screen and (min-width: 768px) and (max-width: 1550px){
        padding: 0 calc(50rem/16);
    }
}