.modal {
    padding-left: 0 !important;
}
.modal--fullscreen{
    padding-right: 0!important; /* because of inline-style */
}
.modal-fullscreen {
    width: 100%;
    height: 100%;
    margin: 0;
    max-width: none;
}
.modal-light-grey .modal-content {
    background-color: var(--color-light-grey);
}
.modal-fullscreen .modal-content{
    height: auto;
    min-height: 100%;
}
.modal__close {
    width: calc(50rem/16);
    height: calc(50rem/16);
    position: absolute;
    right:0;
    top:0;
    box-shadow: 0 calc(5rem/16) calc(60rem/16) 0 rgba(0,0,0,0.2);
    background-color:#fff;
    border: none;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    padding: calc(5rem/16);
    z-index: 31;
    
    @media screen and (min-width: 768px){
        width: calc(100rem/16);
        height: calc(100rem/16);
        padding: calc(10rem/16) calc(20rem/16) calc(15rem/16);
    }
}
.modal__close-text {
    letter-spacing: calc(1rem/16);
    font-family: var(--font-default-bold);
    text-align:center;
    color: var(--color-middle-grey);
    text-transform: uppercase;
    padding-top: calc(8rem/16);
    
    @media screen and (min-width: 768px){
        letter-spacing: calc(2rem/16);
        padding-top: calc(15rem/16);
        font-size: calc(10rem/16);
    }
    
    @media screen and (max-width: 767px){
        font-size: calc(8rem/16);
        letter-spacing: 0;
    }
}

.modal-dialog:not(.modal-fullscreen) .modal-content{
    padding: calc(50rem/16) calc(30rem/16) calc(30rem/16);

    @media screen and (min-width: 768px){
        padding: calc(120rem/16) calc(64rem/16) calc(95rem/16);

    }
}

.modal-title{
   margin-bottom: calc(30rem/16); 
    @media screen and (min-width: 768px){
       margin-bottom: calc(50rem/16);
    }
}