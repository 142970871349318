.horizontal-teaser__body {
    background-color:#fff;
    height: 100%;
    padding: calc(20rem/16);
    display: flex;
    align-items: center;

    @media screen and (min-width: 768px) {
        padding: calc(60rem/16);
    }
}
.horizontal-teaser__img-wrapper {
    overflow: hidden;
    position: relative;
}
.horizontal-teaser__img-wrapper:before {
    display: block;
    content:'';
    position: absolute;
    z-index: 2;
    bottom:0;
    height: calc(100rem/16);
    width: 100%;
    background: linear-gradient(0deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);

    @media screen and (min-width: 768px) {
        background: linear-gradient(90deg, #FFFFFF 0%, rgba(255,255,255,0) 98.26%);
        width: calc(277rem/16);
        height:105%;
        right:0;
        transform: scale(-1);
        top:calc(-1rem/16);
    }
}
.horizontal-teaser--img-right .horizontal-teaser__img-wrapper:before {
    @media screen and (min-width: 768px) {
        left:0;
        transform: none;
        right: initial;
    }
}

.horizontal-teaser__img{
    @media screen and (min-width: 768px){
       object-fit: cover;
    }
}
.horizontal-teaser__title {
    font-size: calc(20rem/16);
    -webkit-hyphenate-limit-before: 10;
    -webkit-hyphenate-limit-after: 10;
    -moz-hyphenate-limit-chars: 20 3 2;
    -webkit-hyphenate-limit-chars: 20 3 2;
    -ms-hyphenate-limit-chars: 20 3 2;
    hyphenate-limit-chars: 20 3 2;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    @media screen and (min-width: 768px) {
        font-size: calc(32rem/16);
        letter-spacing: calc(2rem/16);
    }
    @media screen and (max-width: 767px) {
        letter-spacing: calc(1.5rem/16);
    }
}
.horizontal-teaser__title:after {
    content: "";
    height: .25rem;
    width: 2.5rem;
    position: relative;
    display: block;
    margin: calc(10rem/16) 0 calc(15rem/16);
    background-color: var(--color-primary);
    @media screen and (min-width: 768px) {
        margin: calc(20rem/16) 0;
    }
}
.horizontal-teaser__wysiwyg {
    max-width: calc(380rem/16);
    margin-bottom: calc(20rem/16);
}
.horizontal-teaser__link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.horizontal-teaser__badge {

}