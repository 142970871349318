.floating-label {
    position: absolute;
    left: calc(12rem/16);
    top: 50%;
    transform: translateY(-50%);
    transition: transform 200ms ease-in-out;
    transform-origin: left top;
    pointer-events: none;
    z-index: 4;
    font-size: calc(12rem/16);
    white-space: nowrap;

    @media screen and (min-width: 768px){
        left: calc(20rem/16);
        font-size: calc(14rem/16);
    }
}

.text-area .floating-label{
    top: calc(15rem/16);
    transform: translateY(0);
}

.form-control:focus ~ .floating-label,
.form-control.has-value ~ .floating-label,
.form-group.has-value .floating-label,
.form-group:focus .floating-label {
    transform: translateY(-16px) scale(.62);
    text-transform: uppercase;
    letter-spacing: calc(1.5rem/16);
    color: var(--color-grey);

    @media screen and (min-width: 768px){
        letter-spacing: calc(2rem/16);
    }
}

.form-group.has-value .floating-label--textarea,
.form-group:focus .floating-label--textarea {
    transform: translateY(-7px) scale(.62);
    text-transform: uppercase;
    letter-spacing: calc(1.5rem/16);
    color: var(--color-grey);

    @media screen and (min-width: 768px){
        letter-spacing: calc(2rem/16);
    }
}

.form-control:-webkit-autofill ~ .floating-label,
.form-control:-webkit-autofill:focus ~ .floating-label {
    transform: translateY(-16px) scale(.62);
    text-transform: uppercase;
    letter-spacing: calc(1.5rem/16);
    color: var(--color-grey);

    @media screen and (min-width: 768px){
        letter-spacing: calc(2rem/16);
    }

}

.form-group--icon .floating-label{
    left: calc(15rem/16);
}