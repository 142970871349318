.content-block .darkpage.content-block-small{ /* fix for content-block problem in portal */
    margin: 0;
}

.darkpage .modal-content{
    background: linear-gradient(180deg, #191919 0%, rgba(25,25,25,0.9) 100%);
}

.darkpage .modal-body{
    color: #fff;
    @media screen and (min-width: 768px){
       padding-top: calc(55rem/16);
    }
}

.darkpage__title{
    line-height: calc(72/50);
    font-size: calc(30rem/16);
    padding-bottom: calc(15rem/16);
    margin-bottom: calc(25rem/16);
    position: relative;
    text-transform: uppercase;
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px){
        font-size: calc(50rem/16);
        line-height: calc(72/50);
        letter-spacing: calc(7rem/16);
        margin-bottom: calc(40rem/16);

    }
}

.darkpage__title:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: calc(4rem/16);
    width: calc(40rem/16);
    background-color: var(--color-primary);
}

.darkpage__toptitle{
    font-size: calc(12rem/16);
    text-transform: uppercase;
    letter-spacing: calc(3rem/16);
    margin-bottom: calc(15rem/16);
}

.darkpage__subtitle{
    font-size: calc(18rem/16);
    font-family: var(--font-default-bold);
    margin-bottom: calc(10rem/16);
}

.darkpage-bottom{
    margin-top: calc(40rem/16);
    @media screen and (min-width: 768px){
       margin-top: calc(70rem/16);
    }
}

.darkpage-bottom__title{
    font-size: calc(25rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    letter-spacing: calc(5rem/16);
    margin-bottom: calc(15rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(32rem/16);
        letter-spacing: calc(10rem/16);
    }
}

.darkpage-bottom .btn{
    text-transform: none;
    letter-spacing: normal;
    font-size: calc(14rem/16);
}

.darkpage-bottom .wysiwyg-block__btn-list{
    margin-top: calc(15rem/16);
}