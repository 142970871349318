.author__img-wrapper,
.author__img{
    border-radius: 50%;
}

.author__title{
    position: relative;
    padding-bottom: calc(20rem/16);
    @media screen and (min-width: 768px){
       font-size: calc(70rem/16);
        padding-bottom: calc(30rem/16);
        margin-right: calc(-20rem/16);
    }
}

.author__title:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(40rem/16);
    height: calc(4rem/16);
    background-color: var(--color-primary);

    @media screen and (min-width: 768px){
        right: calc(20rem/16);
        left: auto;
    }
}

.author__body{
    margin-top: calc(30rem/16);
    @media screen and (min-width: 768px){
       margin-top: calc(60rem/16);
    }
}

.author__text{
    @media screen and (max-width: 767px){
       margin-top: calc(10rem/16);
    }
}