.box{
    padding: calc(30rem/16) calc(25rem/16);
    background-color: #fff;

    @media screen and (min-width: 1200px){
        padding: calc(40rem/16) calc(80rem/16);
    }
    @media screen and (min-width: 768px){
        padding: calc(30rem/16) calc(50rem/16);
    }
}

.content-block.box+.content-block.box,
.content-block-small.box+.content-block-small.box{
    margin-top: calc(15rem/16);
}

.box--large{
    padding: calc(30rem/16) calc(25rem/16);

    @media screen and (min-width: 1200px){
        padding: calc(60rem/16) calc(80rem/16);
    }
    @media screen and (min-width: 768px){
        padding: calc(40rem/16) calc(50rem/16);
    }
}

.box--x-large{
    padding: calc(30rem/16) calc(25rem/16);

    @media screen and (min-width: 1200px){
        padding: calc(135rem/16) calc(94rem/16);
    }
    @media screen and (min-width: 768px){
        padding: calc(80rem/16) calc(50rem/16);
    }
}

.box--small{
    padding: calc(30rem/16) calc(25rem/16);

    @media screen and (min-width: 1200px){
        padding: calc(40rem/16) calc(60rem/16);
    }
    @media screen and (min-width: 768px){
        padding: calc(30rem/16) calc(50rem/16);
    }
}

.box--x-small{
    padding: calc(20rem/16) calc(20rem/16);

    @media screen and (min-width: 1200px){
        padding: calc(20rem/16) calc(30rem/16);
    }
    @media screen and (min-width: 768px){
        padding: calc(30rem/16) calc(40rem/16);
    }
}

.box--transparent{
    background-color: transparent;
    border: 1px solid var(--color-middle-grey);
}

.box__intro{
    font-size: calc(18rem/16);
    line-height: calc(26/18);
}

.box__title{
    font-size: calc(20rem/16);
    line-height: calc(25/20);
    letter-spacing: calc(5rem/16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    margin-bottom: calc(25rem/16);
}

.box__big-title {
    font-size: calc(20rem/16);
    line-height: calc(40/25);
    letter-spacing: calc(1rem/16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    margin-bottom: calc(5rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(32rem/16);
        letter-spacing: calc(2rem/16);
    }
}

.box__small-label {
    color: var(--color-grey);
    text-transform: uppercase;
    letter-spacing: calc(3rem/16);
    font-size: calc(12rem/16);
    display: flex;
}

.box__date-item {
    position: relative;
    padding-right: calc(80rem/16);
}

.box__sub-title {
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    letter-spacing: calc(5rem/16);
}

.box__date-item:not(:last-of-type):after {
    @media screen and (min-width: 768px){
        content: "";
        height: .25rem;
        width: 1.5625rem;
        background-color: var(--color-primary);
        top:50%;
        transform: translateY(-50%);
        display: block;
        margin-left: 20px;
        position: absolute;
        right: 22px;
    }
}

.box__title-icon{
    font-size: calc(40rem/16);
    margin-right: calc(10rem/16);
    @media screen and (min-width: 768px){
       font-size: calc(45rem/16);
    }
}

.box__text-link {
    & a {
        text-decoration: underline;
    }
}

.box--sidebar{
    font-family: var(--font-default-bold);
    color: var(--color-dark);
    padding: calc(30rem/16) calc(25rem/16);

    @media screen and (min-width: 1200px){
        padding: calc(40rem/16) calc(52rem/16);

    }
    @media screen and (min-width: 768px){
        padding: calc(30rem/16) calc(30rem/16);
    }
}

.box--sidebar .btn{
    height: calc(40rem/16);
    padding: calc(8rem/16) calc(10rem/16) calc(5rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: calc(2rem/16);
}

.box--sidebar .btn .icon-map{
    vertical-align: 0;
}

.box--sidebar .nav-tabs--primary .nav-link{
    @media screen and (min-width: 768px) and (max-width: 1200px){
        padding: calc(10rem/16) calc(10rem/16)  calc(12rem/16);

    }
}

.box--sidebar-event,
.box--sidebar-event-placeholder{
    @media screen and (min-width: 768px){
       width: calc(350rem/16);
    }
}

.box--sidebar-infrastructure,
.box--sidebar-infrastructure-placeholder{
    @media screen and (min-width: 768px){
       width: calc(310rem/16);
    }
}

.box--sidebar-press,
.box--sidebar-press-placeholder{
    @media screen and (min-width: 768px){
        width: calc(335rem/16);
    }
}

.box--sidebar.box--sidebar-press{
    @media screen and (min-width: 768px){
       padding: calc(50rem/16) calc(60rem/16);
    }
}

.box--sidebar-activity {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}


.box--gradient{
    background: linear-gradient(180deg,#fff,#f0f0f0);
}

.box-button--is-fixed-mobile {
    @media screen and (max-width: 767px) {
        position: fixed;
        bottom:0;
        width: 100%;
        left:0;
        height: calc(60rem/16);
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.box__detail-price__tooltip{
    color: var(--color-text-default);
}