.wysiwyg table{
    width: 100%;
    border-color: transparent;
    float: none;
}

.wysiwyg table>thead{
    background-color: var(--color-dark-grey);
}

.wysiwyg table>thead th{
    font-size: calc(12rem/16);
    line-height: calc(20/15);
    letter-spacing: calc(1rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    background-color: var(--color-dark-grey);
    color: #fff;
    vertical-align: top;
    @media screen and (min-width: 768px) {
        letter-spacing: calc(2rem/16);
        font-size: calc(15rem/16);
    }
}
.wysiwyg table>thead th span:not(.icon){
    display: block;
    font-family: var(--font-default);
    font-size: calc(10rem/16);
    letter-spacing: calc(1.7rem/16);
    line-height: calc(12/10);
    margin-top: calc(3rem/16);
    
    @media screen and (min-width: 768px){
        letter-spacing: calc(2.5rem/16);
    }
}

.wysiwyg table td {
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}

.wysiwyg table th,
.wysiwyg table td{
    padding: calc(7rem/16) calc(6rem/16);
    @media screen and (min-width: 768px) {
        padding: calc(17rem/16) calc(20rem/16);
    }
}

.wysiwyg table>tbody>tr{
    border-bottom: 1px solid #dedede;
}

.wysiwyg-table__icon{
    font-size: calc(32rem/16);
}

.wysiwyg thead p{
    margin-bottom: calc(6rem/16);
}


/* Table Opening hours */

.table--opening-hours__title{
    font-family: var(--font-default-bold);
    font-size: calc(12rem/16);
    letter-spacing: calc(2.5rem/16);
    text-align: center;
    margin-bottom: calc(10rem/16);
}

.table--opening-hours tbody th{
    color: var(--color-grey);
    text-transform: uppercase;
    letter-spacing: calc(3rem/16);
    font-size: calc(12rem/16);
    font-family: var(--font-default);
    vertical-align: middle;
}

.table--opening-hours td{
    font-size: calc(13rem/16);
}

.table--opening-hours td,
.table--opening-hours th{
    border-color: #f0f0f0;
}


.table--opening-hours tr:first-child td,
.table--opening-hours tr:first-child th{
    border-top: 1px solid #f0f0f0;
}


.table--opening-hours tr:last-child td,
.table--opening-hours tr:last-child th{
    border-bottom: 0;
}

.box--sidebar-event-placeholder .table--opening-hours tr:first-child td,
.box--sidebar-event-placeholder .table--opening-hours tr:first-child th{
    border-top: 0;
}