.history-wrapper {
    position: relative;
    padding-bottom: calc(40rem / 16);
    @media screen and (max-width: 767px) {
        padding-left: calc(50rem/16);
    }
}

.history-item {
    position: relative;
}

.history-item {
    padding-top: calc(60rem / 16);
    padding-bottom: calc(60rem / 16);
    @media screen and (max-width: 767px) {
        padding-top: calc(20rem / 16);
        padding-bottom: calc(20rem / 16);
    }
}

.history-item__claim {
    font-size: clamp(calc(20rem / 16), 6.5vw, calc(140rem / 16));
    letter-spacing: clamp(calc(20rem / 16), 6.5vw, calc(120rem / 16));
    opacity: 0.6;
    color: #fff;
    z-index: -1;
    top: 0;
    font-family: var(--font-default-bold);
    position: absolute;
    left: 0;
    right: 0;
    pointer-events: none;
    text-align: center;
    @media screen and (max-width: 767px) {
        margin-top: calc(5rem/16);
        z-index: 2;
        display: none;
    }
}

.history-item__years {
    font-size: calc(60rem / 16);
    letter-spacing: calc(12rem / 16);
    font-family: var(--font-default-bold);
    line-height: 1;
    margin-bottom: calc(10rem / 16);
    @media screen and (max-width: 767px) {
        font-size: calc(36rem / 16);
    }
}

.history-item__title {
    letter-spacing: calc(5rem / 16);
    font-size: calc(20rem / 16);
    margin-bottom: calc(15rem / 16);
    font-family: var(--font-default-bold);
}
.history-wrapper:before {
    content: '';
    position: absolute;
    z-index: -1;
    width: calc(20rem / 16);
    height: calc(100% + 70px);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.6222864145658263) 0%, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 0) 100%);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
        width: calc(10rem/16);
        left: calc(10rem/16);
        margin: 0;
        top:0;
        height: 100%;
        background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,1) 90%, rgba(255,255,255,0) 100%);
    }
}
.history-item__wysiwyg:after {
    content: '';
    position: absolute;
    right: 100%;
    width: 100%;
    max-width: calc(1310px * 0.16 + 50px);
    height: 4px;
    background: red;
    top: 6px;
    margin-right: calc(10rem / 16);
    z-index: 1;
    @media screen and (max-width: 1349px) {
        max-width: calc(16vw + 50px);
    }
    @media screen and (max-width: 767px) {
        max-width: calc(35rem/16);
    }
}
.history-item__title-wrapper {
    margin-left: calc(-70rem / 16);
    @media screen and (max-width: 767px) {
        margin-left: calc(-20rem/16);
    }
}

.history-item__body{
    position: relative;
    z-index: 2;
}
@media screen and (min-width: 768px) {
    .history-item__body--left {
        text-align: right;
    }

    .history-item__body--left .history-item__title-wrapper {
        margin-right: calc(-70rem / 16);
    }

    .history-item__body--left .history-item__wysiwyg:after {
        left: 100%;
        margin-left: calc(10rem / 16);
        margin-right: 0;
        right: initial;
    }
}

.history-item__wysiwyg {
    position: relative;
}

.history-item__img-block {
    margin-top: calc(40rem / 16);
    z-index: 0;
    position: relative;
    @media screen and (max-width: 767px) {
        margin-top: calc(10rem/16);
        margin-bottom: calc(15rem/16);
        margin-left: calc(-20rem/16);
    }
}

.history-item__img-two {
    margin-top: calc(-120rem / 16);
    box-shadow: 0 42px 50px 0 rgba(0, 0, 0, 0.2);
}

.history-item__body--has-no-img .history-item__wysiwyg:after {
    max-width: calc((1310px * 0.16 + 25px) / 2);
    @media screen and (max-width: 1349px) {
        max-width: calc(8vw + 5px);
    }
}
