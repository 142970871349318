.cart__collapse-btn {
    letter-spacing: 0;
    text-transform: none;
    font-size: calc(12rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(14rem/16);
    }
}
.cart__collapse-btn .icon {
    font-size: calc(8rem/16);
    padding: calc(7rem/16);
}
.cart__collapse-btn-closed {
    display: none;
}
.cart__collapse-btn.collapsed .cart__collapse-btn-opened {
    display: none;
}
.cart__collapse-btn.collapsed .cart__collapse-btn-closed {
    display: block;
}
.cart-footer__info {
    display: inline-flex;
    align-items: center;
    margin-top: calc(24rem/16);
}
.cart-footer__info-circle {
    width: calc(30rem/16);
    height: calc(30rem/16);
    border: 2px solid var(--color-text-default);
    border-radius: 50%;
    margin-right: calc(12rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(11rem/16);
    min-width: calc(30rem/16);
}
.cart-footer__info-text {
    font-size: calc(12rem/16);
    line-height: calc(15/12);
}