.status {
    border-radius: 50%;
    border: none;
    vertical-align: 0;
    width: calc(8rem/16);
    height: calc(8rem/16);
    display: inline-flex;
}
.status--medium {
    width: calc(10rem/16);
    height: calc(10rem/16);
    @media screen and (min-width: 768px) {
        width: calc(14rem/16);
        height: calc(14rem/16);
    }
}
.status--success {
    background-color: var(--color-success);
}
.status--danger {
    background-color: var(--color-danger);
}
.status--warning {
    background-color: var(--color-warning);
}
.status-season--peak_season {
    background-color: var(--color-season-peak);
}
.status-season--pre_season {
    background-color: var(--color-season-pre);
}
.status-season--off_season {
    background-color: var(--color-season-off);
}
.status-special {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    font-size: calc(10rem/16);
    text-rendering: auto;
}
.status-special--medium {
    font-size: calc(10rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(14rem/16);
    }
}
.status-special:before {
    content: var(--icon-star-filled);
}
.status-special--first_special {
    color: var(--color-special-primary);
}
.status-special--second_special {
    color: var(--color-special-secondary);
}
.status-special--third_special {
    color: var(--color-special-third);
}