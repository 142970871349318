.switch-input {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.switch-input__switch {
    height: calc(20rem / 16);
    width: calc(40rem / 16);
    border-radius: calc(12rem / 16);
    border: calc(2rem / 16) solid #848484;
    display: block;
    position: relative;
}

.is-active.switch-input__switch{
    border: calc(2rem / 16) solid var(--color-default);
}

.switch-input__label {
    font-size: calc(10rem / 16);
    letter-spacing: calc(2rem / 16);
    text-transform: uppercase;
    margin-right: calc(10rem / 16);
}

.switch-input__switch-toggle {
    position: absolute;
    cursor: pointer;
    top: calc(-7rem / 16);
    left: calc(-7rem / 16);
    color: #fff;
    background: var(--circle-gradient-grey);
    height: calc(30rem / 16);
    width: calc(30rem / 16);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(0);
    transition: all ease-in .2s;
    @media screen and (min-width: 768px){
        top: calc(-6rem / 16);
        left: calc(-6rem / 16);
    }
}

.is-active .switch-input__switch-toggle {
    background: var(--circle-gradient-black);
}

.is-active .switch-input__switch-toggle,
.is-pending .switch-input__switch-toggle {
    transform: translateX(calc(20rem / 16));
    transition: all ease-in .2s;
}

.switch-input__switch-toggle__inner {
    border-radius: 50%;
    transition: all ease-in .2s;
    background: var(--circle-gradient-grey-reverse);
    width: calc(26rem / 16);
    height: calc(26rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
}

.is-active .switch-input__switch-toggle__inner {
    background: var(--circle-gradient-black-reverse);
}

.switch-input__switch-toggle__inner:before {
    content: var(--icon-close);
    color: #fff;
    font-size: calc(10rem / 16);
    font-family: iconfont;
}

.is-active .switch-input__switch-toggle__inner:before {
    content: var(--icon-open);
}

.is-pending .switch-input__switch-toggle__inner:before {
    content: var(--icon-in-preparation);
}

