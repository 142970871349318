.login-box{
    overflow: hidden;
    @media screen and (max-width: 767px){
       margin-bottom: calc(100rem/16);
    }
}

.login-box__box-hl{
    font-size: calc(15rem/16);
    position: relative;
    padding: calc(5rem/16) calc(15rem/16);
    background-color: var(--color-light-grey);
    display: inline-block;
    letter-spacing: calc(2.5rem/16);
}

.login-box__box-hl:before{
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) translateX(-100%);
    height: calc(1rem/16);
    background-color: var(--color-middle-grey);
    width: calc(75rem/16);

    @media screen and (min-width: 768px){
        width: calc(200rem/16);
    }
}

.login-box__box-hl:after{
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) translateX(100%);
    height: calc(1rem/16);
    background-color: var(--color-middle-grey);
    width: calc(75rem/16);

    @media screen and (min-width: 768px){
        width: calc(200rem/16);
    }
}

.nav-tabs--btn-style.nav-tabs--login .nav-link{
    @media screen and (max-width: 767px){
       font-size: calc(13rem/16);
    }
}

.login-box__box-block-bottom{
    margin: 0 calc(-30rem/16) calc(-31rem/16);
    padding: calc(30rem/16) calc(30rem/16) calc(30rem/16);
    background: #fff;

    @media screen and (min-width: 768px){
        margin: 0 calc(-64rem/16) calc(-92rem/16);
        padding: calc(40rem/16) calc(64rem/16) calc(40rem/16);
    }
}