
.wysiwyg-block--has-numeration{
    position: relative;
    padding-top: calc(20rem/16);

    @media screen and (min-width: 768px){
        padding-top: calc(69rem/16);
    }
}

.two-col-text-item__title{
    text-transform: none;
    letter-spacing: normal;
    
    @media screen and (max-width: 767px){

    }
}

.two-col-text-area .wysiwyg-block__content{
    position: relative;
    padding-top: calc(30rem/16);
    @media screen and (min-width: 768px){
       margin-left: 24%;
    }
    
    @media screen and (max-width: 767px){
       padding-left: calc(55rem/16);
    }
}

.two-col-text-area .title-block__title--red-line{
    padding-bottom: 0;

    @media screen and (max-width: 767px){
       font-size: calc(22rem/16);
        letter-spacing: calc(2rem/16);
    }
}

.two-col-text-area .title-block__title--red-line:after{
    display: none;
}

.two-col-text-area .wysiwyg-block__content:before{
    content: "";
    position: absolute;
    background-color: var(--color-primary);
    height: .25rem;
    width: 2.5rem;
    top: 0;
    left: calc(55rem/16);
    
    @media screen and (min-width: 768px){
        left: 0;
    }
}

.two-col-text-item + .two-col-text-item{
    margin-top: calc(28rem/16);
}

.two-col-text__numeration{
    font-size: calc(70rem/16);
    line-height: 1;
    font-family: var(--font-default-bold);
    color: #fff;
    letter-spacing: calc(15rem/16);
    position: absolute;
    top: calc(8rem/16);
    left: calc(12rem/16);
    z-index: -1;

    @media screen and (min-width: 768px){
        letter-spacing: calc(50rem/16);
        font-size: calc(140rem/16);
        left: calc(-15rem/16);
        top: 0;
    }
}

.two-col-text-area .title-block__top-title{
    min-height: calc(15rem/16);
}