.topic-teaser{
    position: relative;
    color: #fff;
    
    @media screen and (min-width: 768px){
       height: 100%;
    }
}
.topic-teaser:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: var(--color-third);
    opacity: 0.9;
}

@media screen and (min-width: 768px) {
    .topic-teaser:after {
        content: '';
        box-shadow: 0 calc(42rem / 16) calc(60rem / 16) 0 rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        opacity: 0;
        transition: opacity 0.3s ease;
        will-change:opacity;
    }
    .topic-teaser:hover:after {
        opacity:1;
    }
}


.topic-teaser__content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    padding: 0 calc(20rem/16);
    text-align: center;
    z-index: 2;
}

.topic-teaser__title{
    font-size: calc(32rem/16);
    letter-spacing: calc(10rem/16);
    margin-bottom: calc(10rem/16);
}

.topic-teaser__subtitle{
    font-size: calc(12rem/16);
    letter-spacing: calc(3rem/16);
    text-transform: uppercase;
}

.topic-teaser__icon{
    margin-right: calc(12rem/16);
    position: relative;
    top: calc(-1rem/16);
    font-size: calc(15rem/16);
}

.topic-teaser__btn{
    position: absolute;
    bottom: calc(50rem/16);
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    width: 100%;
    text-align: center;
}