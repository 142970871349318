.video-teaser{
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: all 0.2s ease;
    position: relative;
    cursor: pointer;
}

.video-teaser__body{
    padding: calc(15rem/16);
    background-color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1200px){
        padding: calc(25rem/16) calc(30rem/16);
    }

    @media screen and (min-width: 768px){
        padding: calc(20rem/16);

    }
}

.video-teaser__title{
    font-size: calc(15rem/16);
    line-height: calc(24/20);
    letter-spacing: calc(1rem/16);
    text-transform: none;

    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
        letter-spacing: calc(4rem/16);
    }
}

.video-teaser__toptitle{
    text-transform: uppercase;
    font-size: calc(10rem/16);
    line-height: calc(15/12);
    letter-spacing: calc(3rem/16);
    margin-bottom: calc(5rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);
    }
}

@media screen and (min-width: 768px) {
    .video-teaser:before {
        content: '';
        box-shadow: 0 calc(42rem / 16) calc(40rem / 16) 0 rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        opacity: 0;
        transition: opacity 0.3s ease;
        will-change:opacity;
    }
    .video-teaser:hover:before {
        opacity:1;
    }
    .video-teaser .video-teaser__img {
        transform-origin: center;
        transition: transform 0.3s ease;
        will-change:transform;
    }
    .video-teaser:hover .video-teaser__img {
        transform: scale(1.03);
    }
}

.video-teaser__play-btn {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: calc(40rem /16);
}

.video-teaser__video{
    height: calc(200rem /16);
    @media screen and (min-width: 768px){
        height: calc(500rem /16);
    }
}