.img-teaser-grid-area {
    position: relative;
    z-index: 3;
}

.img-teaser-grid-area__wysiwyg {
    @media screen and (min-width: 768px) {
        margin-top: calc(40rem/16);
    }
}

.is-text-top .img-teaser-grid-area__wysiwyg{
    margin-top: 0;
}

.img-teaser-grid-area__left-item {
    @media screen and (min-width: 768px) {
        padding-top: calc(48rem/16);
    }
    @media screen and (max-width: 767px) {
        padding-top: calc(15rem/16);
    }
}

.is-text-top .img-teaser-grid-area__left-item{
    padding-top: 0;
}

.img-teaser-grid-area__img-teaser--landscape {
    @media screen and (max-width: 767px) {
        max-height: calc(152rem/16);
        margin: 0 auto;
        width: 100%;
    }
}

.img-teaser-grid-area__img-teaser--portrait {
    @media screen and (max-width: 767px) {
        max-height: calc(152rem/16);
        margin: 0 auto;
        width: 100%;
    }
}

.img-teaser-grid-area__right-col{
    @media screen and (min-width: 768px){
       margin-top: calc(80rem/16);
    }
    margin-top: calc(1rem/16);
}

.img-teaser-grid-area .ratio-16x19{
    @media screen and (max-width: 767px){
       /*padding-top: 61.90476%;*/
        --aspect-ratio: 61.90476%;
    }
}

.img-teaser-grid-area .ratio{
    @media screen and (max-width: 767px){
        position: initial;
        min-height: calc(152rem/16);
        min-width: calc(247rem/16);
    }
}
.img-teaser-grid-area .ratio-item,
.img-teaser-grid-area .ratio>:not(button):not(.not-ratio){
    @media screen and (max-width: 767px){
        position: relative;
        width: auto;
        height: auto;
    }
}

.img-teaser-grid-area .ratio:before{
    @media screen and (max-width: 767px){
       display: none;
    }
}

.img-teaser-grid-area .scrolling-area__item:not(:last-child){
    @media screen and (max-width: 767px){
       margin-right: calc(10rem/16);
    }
}

.img-teaser-grid-area .scrolling-area--xs{
    @media screen and (max-width: 767px){
       margin-top: calc(55rem/16);
    }
}

.img-teaser-grid-area .scrolling-area--slider{
    @media screen and (min-width: 768px){
       display: block;
    }
}

.img-teaser-grid-area .gy-gutter--grid>.gy-gutter__item{
    @media screen and (max-width: 767px){
       margin-top: 0;
    }
}