:root {
    --mega-nav-level-0-item-spacing: calc(16rem / 16);
}

.mega-nav__item--level-0{
    @media screen and (min-width: 768px){
        width: calc(450rem/16);
    }
}

.mega-nav__item--level-0:after {
    @media screen and (min-width: 768px){
        content: '';
        position: absolute;
        height: calc(5rem/16);
        width: calc(95rem/16);
        background-color: var(--color-primary);
        right: calc(-28rem/16);
        margin-top: calc(-23rem/16);
        transform: translateX(100%);
        display: none;
    }

}
.mega-nav__item--level-0.is-open:after {
    @media screen and (min-width: 768px){
       display: block;
    }
}

.mega-nav__item--level-0:not(:last-child){
    margin-bottom: calc(22rem/16);
    
    @media screen and (min-width: 768px){
        margin-bottom: calc(35rem/16);
    }

    @media screen and (min-width: 768px) and (max-height: 500px){
        margin-bottom: calc(20rem/16);
    }

    @media screen and (min-width: 768px) and (max-height: 700px){
       margin-bottom: calc(30rem/16);
    }

}

.mega-nav__item-content--level-0{
    font-size: calc(28rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(44/35);

    @media screen and (max-width: 767px){
        display: inline-block;
        text-align: left;
        font-size: calc(22rem/16);
        padding: calc(7rem/16) 0;
    }

    
    @media screen and (min-width: 768px) and (max-height: 700px){
       font-size: calc(28rem/16);
    }


    @media screen and (min-width: 768px){
        font-size: calc(35rem/16);
    }
}


.mega-nav__collapse-icon--level-0{
    @media screen and (min-width: 768px){
        font-size: 16px;
        vertical-align: calc(1rem/16);
        opacity: 0.4;
    }
    font-size: calc(17rem/16);
    margin-left: calc(5rem/16);
}

.mega-nav__item--level-0.is-open .mega-nav__collapse-icon--level-0{
    transform: rotate(45deg);
}

.mega-nav--level-0.is-active .mega-nav__item-content--level-0{
    @media screen and (min-width: 768px){
        opacity: 0.4;
    }
}

.mega-nav--level-0.is-active .mega-nav__item--level-0.is-active .mega-nav__item-content--level-0,
.mega-nav--level-0.is-active .mega-nav__item-content--level-0:focus{
    @media screen and (min-width: 768px){
        opacity: 1;
    }
}


@media screen and (min-width: 768px){
    .mega-nav__list--level-0:hover .mega-nav__item-content--level-0,
    .nav-list-open .mega-nav__list--level-0 .mega-nav__item-content--level-0 {
        opacity: 0.4;
    }

    .mega-nav__list--level-0 .mega-nav__item--level-0:hover .mega-nav__item-content--level-0,
    .nav-list-open .mega-nav__list--level-0 .mega-nav__item-content--level-0.is-open {
        opacity: 1;
    }
}
