.breadcrumb-container{
    padding: calc(10rem/16)  calc(15rem/16);

    @media screen and (min-width: 768px){
        padding: calc(20rem/16)  calc(30rem/16);
    }
}

.breadcrumb-item{
    font-size: calc(12rem/16);
    opacity: 0.3;
}

.breadcrumb-item .icon{
    font-size: calc(17rem/16);
    position: relative;
    top: calc(-2rem/16);
}

.breadcrumb-item.active{
    opacity: 1;
}

.breadcrumb-item+.breadcrumb-item{
    padding-left: calc(10rem/16);
}

.breadcrumb-item+.breadcrumb-item:before{
    content: var(--icon-arrow-right);
    font-family: iconfont;
    font-size: calc(8rem/16);
    top: calc(2rem/16);
    position: relative;
    padding-right: calc(10rem/16);
}