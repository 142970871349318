.ticket-configuration {
    max-width: calc(1130rem/16);
    width: 100%;
    margin: 0 auto;
    padding: calc(140rem/16) calc(20rem/16) calc(70rem/16);
    @media screen and (min-width: 768px){
        padding: calc(140rem / 16) calc(30rem / 16) calc(140rem / 16);
    }
}
.ticket-configuration--wide {
    max-width: calc(1680rem/16);
}
.ticket-configuration--small {
    padding:0;
    @media screen and (min-width: 768px){
        padding:0;
    }
}
.ticket-configuration__title-block {
    height: auto;
    margin-bottom: calc(25rem/16);
    @media screen and (min-width: 768px){
        min-height: calc(120rem/16);
        margin-bottom: calc(20rem/16);
    }
}
.ticket-configuration__title {
    display: flex;
    align-items: center;
    font-size: calc(20rem/16);
    @media screen and (min-width: 768px) {
        font-size: clamp(20rem/16, 10vw, 2rem);
    }
}
.ticket-configuration__header {
    position: fixed;
    z-index: 9999;
    top:0;
    left:0;
    width: 100%;
    background-color: var(--color-light-grey);
}
.ticket-configuration__footer {
    position: fixed;
    z-index: 9999;
    bottom:0;
    left:0;
    width: 100%;
    background-color:#fff;
    height: fit-content;
    padding-top: calc(10rem/16);
    padding-bottom: calc(10rem/16);

    @media screen and (min-width: 768px) {
        height: calc(100rem/16);
    }
}
.ticket-configuration__footer-btn {
    max-width: calc(245rem/16);
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    place-items: center;
    padding-top: calc(11rem/16);
    padding-bottom: calc(11rem/16);

    @media screen and (max-width: 767px) {
        letter-spacing: 1px;
        max-width: none;
        width: auto;
        font-size: calc(12rem/16);
        padding: calc(11rem/16) calc(60rem/16);
    }
}
.ticket-configuration__footer-btn-text {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    transition: .35s ease-in-out all;
}
.ticket-configuration__brand{
    position: absolute;
    left:0;
    top:0;
    z-index: 30;
    display: block;
}
.ticket-configuration__subtitle {
    font-size: calc(20rem/16);
}
.ticket-configuration__headline {
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    letter-spacing: calc(2rem/16);
    font-size: calc(16rem/16);
}
.ticket-configuration__headline--big {
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    letter-spacing: calc(2rem/16);
    font-size: calc(20rem/16);
}
.ticket-configuration__brand img{
    @media screen and (min-width: 768px) and (max-width: 1200px){
        margin-top: calc(-14rem/16);
        width: calc(170rem/16);
    }

    @media screen and (max-width: 767px){
        width: 100%;
        max-width: calc(143rem/16);
        height: auto;
        max-height: calc(60rem/16);
    }
}
.ticket-configuration__box {
    background-color:#fff;
    padding: calc(15rem/16);
    @media screen and (min-width: 768px) {
        padding: calc(30rem/16);
    }
}
.ticket-configuration__box-header {
    padding-bottom: calc(10rem/16);
    border-bottom: 1px solid var(--color-light-grey);
}
.ticket-configuration__availability {
    color: var(--color-grey);
    @media screen and (min-width: 768px) {
        text-align:right;
    }
}
.ticket-configuration__box-title {
    letter-spacing: calc(3rem/16);
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
}
.ticket-configuration__box-body {
    font-size: calc(14rem/16);
    padding: calc(20rem/16) 0;
    font-family: var(--font-default-bold);
    border-bottom: 1px solid var(--color-light-grey);
    @media screen and (min-width: 768px) {
        padding: calc(30rem/16) 0;
    }
}
.ticket-configuration__box-footer {
    padding-top: calc(20rem/16);
    font-size: calc(14rem/16);
    font-family:var(--font-default-bold);
    @media screen and (min-width: 768px) {
        padding-top: calc(30rem/16);
    }
}
.ticket-configuration__box-sum {
    font-size: calc(18rem/16);
}
.ticket-configuration__box-label {
    font-family: var(--font-default);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}
.ticket-configuration__red-base-price {
    color: var(--color-red-light);
    font-family: var(--font-default);
    position: relative;
    white-space: nowrap;
    text-decoration: line-through;
}
.ticket-configuration__red-base-price:after {
    border-top: 1px solid var(--color-red-light);
    position: absolute;
    content: "";
    right: 0;
    top:40%;
    left: 0;
}
.ticket-configuration__discount-badge {
    color: var(--color-red-light);
    font-size: calc(18rem/16);
}
.ticket-configuration__check {
    color: var(--color-success);
    font-size: calc(60rem/16);
}
.ticket-configuration__box-item + .ticket-configuration__box-item {
    @media screen and (max-width: 767px) {
        border-top: calc(1rem / 16) solid var(--color-light-grey);
        margin-top: calc(20rem / 16);
        padding-top: calc(20rem / 16);
    }
}
.ticket-configuration__edit {
    font-size: calc(14rem/16);
    text-transform: uppercase;
    letter-spacing: calc(1.5rem/16);
    font-family: var(--font-default);
    display: block;
    margin-bottom: calc(6rem/16);
    @media screen and (min-width: 768px) {
        font-family: var(--font-default-bold);
        font-size: calc(20rem/16);
        letter-spacing: calc(3rem/16);
        padding-right: calc(25rem/16);
        display: inline-block;
    }
}
.ticket-configuration__edit--light {
    font-family: var(--font-default);
    @media screen and (min-width: 768px) {
        font-family: var(--font-default);
    }
}
.ticket-configuration__edit-icon {
    font-size: calc(14rem/16);
    margin-left: calc(6rem/16);
}
.ticket-configuration__summary-title {
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    margin-bottom: calc(15rem/16);
    letter-spacing: calc(2rem/16);
    text-transform: uppercase;
    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
        letter-spacing: calc(4rem/16);
    }
}
.ticket-configuration__edit-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    text-transform: uppercase;
    font-size: calc(12rem/16);
    margin-left: calc(15rem/16);
    padding: calc(10rem/16) calc(16rem/16) calc(8rem/16);
    margin-bottom: calc(8rem/16);
    letter-spacing: calc(2rem/16);
    border-radius:0;
}
.ticket-configuration__show-more {
    box-shadow: none;
    background-color: transparent;
    border: none;
    color:inherit;
    letter-spacing: 0;
    font-family: var(--font-default);
    padding:0;
    font-size: calc(14rem/16);
    text-transform: none;
}
.ticket-configuration__show-more:hover, .ticket-configuration__show-more:active, .ticket-configuration__show-more:focus, .ticket-configuration__show-more:active:focus {
    color: var(--color-primary);
    box-shadow: none;
    background-color:transparent;
    border: transparent;
}
.ticket-configuration__show-more:hover:before, .ticket-configuration__show-more:active:before, .ticket-configuration__show-more:focus:before {
    display: none;
}
.ticket-configuration__alert--grey {
    align-items: center;
    display: inline-flex;
    padding: calc(10rem/16);
    background-color: var(--color-light-grey);
    font-size: calc(12rem/16);
    line-height: 1;
    border-radius: calc(2rem/16);
}

.ticket-configuration__summary-icon{
    top: 2px;
    position: relative;
    margin-right: 5px;
    color: var(--color-primary);
}