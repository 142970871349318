.sidebar-status{
    @media screen and (min-width: 768px){
        width: calc(310rem/16);
    }
}
.sidebar-status .circle-icon__icon{
    font-size: calc(15rem/16);
}

.sidebar-status__btn{
    text-align: left;
    border: none;
    font-size: calc(12rem/16);
    letter-spacing: calc(3rem/16);
    line-height: calc(15/12);
    text-transform: uppercase;
    padding: calc(12rem/16) calc(10rem/16);
    background-color: #fff;
    width: 100%;
    position: relative;
    min-height: calc(57rem/16);
}

.sidebar-status__btn:focus{
    outline: none;
}

.sidebar-status__btn:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc(30rem/16);
    transform: translateX(-100%);
    background-color: #fff;
    z-index: -1;
}

.sidebar-status__btn:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(-24rem/16);
    width: calc(40rem/16);
    transform: translateX(-100%);
    background-color: #fff;
    z-index: -1;
    height: calc(36rem/16);
    opacity: 1;
}

.sidebar-status__btn.collapsed:after {
    opacity: 0;
    transition: opacity 200ms ease;
    transition-delay: 300ms;
}

.sidebar-status__title{
    font-family: var(--font-default-bold);
    margin-bottom: calc(3rem/16);
}

.sidebar-status__info{
    color: var(--color-grey);
}

.sidebar-status__time{
    display: block;
}

.sidebar-status__collapse .card-body{
    border: 0;
}
.sidebar-status__collapse-arrow{
    color: var(--color-grey);
    margin-left: calc(10rem/16);
    font-size: calc(10rem/16);
    transform: rotate(180deg);
}

.collapsed .sidebar-status__collapse-arrow{
    transform: rotate(0deg);
}

.scrolling-area--opening-hours .scrolling-area__nav{
    height: calc(40rem/16);
    margin-bottom: calc(-31rem/16);
    position: relative;
    z-index: 1;
}

.scrolling-area--opening-hours .scrolling-area__arrow{
    background-color: transparent;
    width: calc(30rem/16);
    height: calc(30rem/16);
    font-size: calc(11rem/16);
    color: var(--color-default);
    position: absolute;
}

.scrolling-area--opening-hours .scrolling-area__arrow:hover{
    background-color: transparent;
}

.scrolling-area--opening-hours .scrolling-area__arrow--next{
    border: none;
    right: 0;
}


.scrolling-area--opening-hours .scrolling-area__arrow--prev{
   left: 0;
    right: auto;
}