.btn{
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    letter-spacing: calc(3rem/16);
    line-height: 1.6;
    padding: calc(10rem/16) calc(20rem/16) calc(7rem/16);
    position: relative;
    transition: background-color 0.2s ease;
}

.btn-lg {
    padding: calc(15rem/16) calc(20rem/16) calc(12rem/16);
}
.btn-xl {
    padding: calc(21rem/16) calc(30rem/16) calc(18rem/16);
}
.btn-hero {
    width:100%;
    padding: calc(23rem/16) calc(30rem/16);
    @media screen and (min-width: 768px){
        padding-top:2.5rem;
        padding-bottom:2.5rem;
    }
}
.btn:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left:0;
    box-shadow: 0 5px 30px 0 rgba(0,0,0,0.4);
    opacity:0;
    transition: opacity 0.2s ease;
    will-change: opacity;
}
.btn:hover:before {
    opacity:1;
}

.btn__icon{
    margin-right: calc(5rem/16);
    vertical-align: calc(-2rem/16);
    font-size: calc(12rem/16);
}

.btn__icon-big{
    margin-right: calc(3rem/16);
    vertical-align: calc(-8rem/16);
    font-size: calc(25rem/16);
}
.btn__icon-medium {
    margin-right: calc(3rem/16);
    vertical-align: calc(-5rem/16);
    font-size: calc(18rem/16);
}
.btn .icon-map{
    margin-top: calc(-4rem/16);
}

.btn-no-styling{
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}
.btn-no-styling:before, .btn.btn-no-styling:before {
    display: none;
}

.btn-white{
    background-color: #fff;
    color: var(--color-text-default);
}
.btn-white:hover {
    background-color: var(--color-white-dark);
}

.btn-primary{
    box-shadow: 0 5px 30px 0 rgba(104,0,0,0.3);
}
.btn-primary,
.btn-primary:hover,
.btn-primary:focus{
    color: #fff;
}
.btn-primary:hover {
    background-color:var(--color-primary-dark);
}

.btn-dark{
    box-shadow: 0 5px 30px 0 rgba(0,0,0,0.3);
}
.btn-dark:hover {
    background-color: #000;
}
.btn-outline-white{
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
}

.btn-outline-white:hover{
    background-color: #fff;
    color: var(--color-dark);
}

.btn-outline-dark{
    background-color: transparent;
    color: var(--color-dark);
    border: 2px solid var(--color-dark);
}

.btn-outline-dark:hover{
    background-color: var(--color-dark);
    color: #fff;
}

.btn-outline-primary {
    background-color: transparent;
    color: var(--color-primary);
    border: 2px solid var(--color-primary);
}

.btn-outline-primary-solid {
    background-color: white;
    color: var(--color-primary);
    border: 2px solid var(--color-primary);
}

.btn-outline-primary-solid:hover {
    background-color: var(--color-primary);
    color: #fff;
}

.btn-outline-primary:hover {
    background-color: var(--color-primary);
    color: #fff;
}

.btn-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    letter-spacing: normal;
}
.btn-tooltip {
    color: currentColor;
    background-color: transparent;
    box-shadow: none;
}
.btn-tooltip:before {
    display:none;
}
.btn-tooltip:hover {
    background-color: transparent;
    color: var(--color-primary);
    box-shadow: none;
    border: none;
}

.btn-tooltip__icon{
    pointer-events: none;
}

.btn-date{
    font-size: calc(15rem/16);
    padding: calc(15rem/16);
    letter-spacing: 0;
    height: calc(50rem/16);
    @media screen and (max-width: 767px) {
        height: calc(44rem/16);
        padding: calc(10rem/16) calc(15rem/16);
    }
}

.btn-info, .btn-info:active, .btn-info:focus, .btn-info:hover, .btn-info:active:focus {
    background-color: var(--color-light-grey);
    color: var(--color-text-default);
    border-radius: 50%;
    width: calc(17rem/16);
    height: calc(17rem/16);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: calc(10rem/16);
    text-indent: calc(3rem/16);
    box-shadow: none;
    outline: none;
}
.btn-info.btn-tooltip {
    text-indent: 0;
}

.btn-facebook{
    background-color: #025BE1;
    color: #fff;
    height: calc(40rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 768px){
        height: calc(50rem/16);
    }
    @media screen and (max-width: 767px){
        padding: calc(8rem/16) calc(10rem/16);
    }
}

.btn-google{
    background-color: #F14336;
    color: #fff;
    height: calc(40rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 768px){
        height: calc(50rem/16);
    }
    @media screen and (max-width: 767px){
       padding: calc(8rem/16) calc(10rem/16);
    }
}


.btn-facebook .btn__icon-medium,
.btn-google .btn__icon-medium {
    margin-right: calc(5rem/16);
    margin-top: calc(-4rem/16);
    font-size: calc(19rem/16);
}

.btn-google .btn__icon-medium{
    margin-right: calc(10rem/16);
}

.btn-facebook:hover,
.btn-google:hover{
    color: #fff;
}

.btn-success-light {
    background-color: var(--color-success-light);
    color:#fff;
}

.btn-success-muted {
    background-color: var(--color-success-muted);
    color:#fff;
}

.btn-success {
    color: #fff;
    background-color: #198754;

}

.btn.form-validation-not-valid {
    opacity: 0.6;

}

.btn-success-personalization-modal.form-validation-not-valid{
    background-color: var(--color-success-muted);
    color:#fff;

}
.btn-transparent:hover:before {
    display: none;
}
.btn-transparent {
    transition: color 0.2s ease;
}
.btn-transparent:hover {
    color: var(--color-primary-dark);
}
.btn-read-more{
    text-decoration: underline;
    text-transform: unset;
}
