.additional-service-radio {
   position: relative;
    @media screen and (min-width: 768px){
        height: calc(70rem/16);
    }
}

.additional-service-radio:before,
.additional-service-radio:after {
    content: " ";
    display: table;
}

.additional-service-radio:after {
    clear: both;
}

.additional-service-radio>label{
    cursor: pointer;
    position: relative;
    padding: calc(5rem/16) calc(20rem/16) calc(5rem/16) calc(55rem/16);
    border: 2px solid var(--color-middle-grey);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.additional-service-radio__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.additional-service-radio__box {
    position: absolute;
    left: calc(20rem/16);
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 1.8px solid var(--color-default);
    background: #ffffff;
    width: calc(18rem/16);
    height: calc(18rem/16);
    margin-top: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
}
.additional-service-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    border-radius: 50%;
    background: var(--color-default);
    visibility: hidden;
}
.additional-service-radio__input:checked ~ .additional-service-radio__box:before {
    visibility: visible;
}
.additional-service-radio__input:focus ~ .additional-service-radio__box {
    border-color: var(--color-grey);
}
.has-error .additional-service-radio__box {
    border-color: var(--color-danger)
}
.additional-service-radio__text {
    display: block;
    overflow: hidden;
}

.additional-service-radio__text a{
    color: var(--color-primary);
    text-decoration: underline;
}

/* inline */
.form-check-inline .form-check-input {
    position: absolute; /* override bootstrap */
}


.additional-service-radio__price{
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    font-size: calc(14rem/16);
    line-height: 1;
}

.additional-service-radio__price-info{
    text-transform: uppercase;
    font-size: calc(9rem/16);
    letter-spacing: calc(1.5rem/16);
    color: var(--color-grey);
    display: block;

    @media screen and (min-width: 768px){
        letter-spacing: calc(2rem/16);
    }

    @media screen and (max-width: 767px){
        margin-top: calc(5rem/16);
    }
}

.additional-service-radio__icon{
    color: var(--color-dark);
    font-size: calc(28rem/16);
    width: calc(32rem/16);
    line-height: 1;
}

.additional-service-radio__checked-border{
    position: absolute;
    top: calc(-2rem/16);
    bottom: calc(-2rem/16);
    left: calc(-2rem/16);
    right: calc(-2rem/16);
    border: 2px solid var(--color-primary);
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: opacity 250ms ease;
}
.additional-service-radio__input:checked ~ .additional-service-radio__checked-border,
.additional-service-radio:hover .additional-service-radio__checked-border{
    visibility: visible;
    opacity: 1;
}