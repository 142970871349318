.img-gallery-open{
    position: relative;
}

.img-gallery-open:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--color-third);
    opacity: 0.8;
    z-index: 1;
}

.img-gallery-open__btn{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
    white-space: nowrap;
    
    @media screen and (max-width: 767px){
        white-space: normal;
        width: 90%;
        font-size: calc(10rem/16);
        padding: calc(10rem/16) calc(15rem/16);
    }
}

.row--icon-text .icon-pin{
    font-size: calc(17rem/16);
}

.row--icon-text .icon-mail{
    font-size: calc(11rem/16);
}

.row--icon-text .icon-external-link{
    font-size: calc(15rem/16);
}

.link-list .list-inline-item:not(:last-child){
    margin-right: calc(20rem/16);
}

.link-with-arrow{
    font-size: calc(12rem/16);
    font-family: var(--font-default-bold);
    letter-spacing: calc(3rem/16);
    text-transform: uppercase;
    transition: all 200ms ease;
}

.link-with-arrow:hover{
    color: var(--color-primary);
}

.text-block__intro{
    font-size: calc(18rem/16);
    line-height: calc(26/18);
}

.result-bar{
    font-size: calc(9rem/16);
    letter-spacing: calc(2rem/16);
    color: var(--color-grey);
    text-transform: uppercase;
    margin-bottom: calc(12rem/16);
}

.drop-cap:first-letter {
    float: left;
    font-size: calc(120rem/16);
    line-height: 0.42;
    font-family: var(--font-default-bold);
    color: var(--color-primary);
    margin-top: calc(20rem/16);
    margin-right: calc(7rem/16);
    margin-bottom: calc(10rem/16);
}

.drop-cap{
    position: relative;
}

.drop-cap:before{
    content: '';
    position: absolute;
    width: calc(45rem/16);
    height: calc(4rem/16);
    background-color: var(--color-primary);
    top: calc(12rem/16);
    left: calc(-30rem/16);
    transform: translateX(-100%);
}

.back-btn{
    margin-bottom: calc(30rem/16);
    @media screen and (min-width: 768px){
        margin-bottom: calc(60rem/16);
    }
}

.panorama-iframe{
    height: calc(270rem/16);
    
    @media screen and (min-width: 768px){
        height: calc(100vh - 100px);
    }
}

.image-portrait-area__img{
    text-align: center;
}

.image-caption{
    background: linear-gradient(180deg, rgba(44,43,46,0) 0%, rgba(44,43,46,0.5) 100%);
    color: #fff;
    font-size: calc(10rem/16);
    padding: calc(20rem/16) calc(8rem/16) calc(5rem/16);
    letter-spacing: 0.3px;

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.dropdown-item.disabled, .dropdown-item:disabled{
    color: var(--color-default);
    opacity: 0.5;
}
.title-small {
    font-size: calc(12rem/16);
    letter-spacing: calc(5rem/16);
    color: var(--color-grey);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    margin-bottom: calc(12rem/16);
}
