.mega-nav__list--level-2{
    @media screen and (min-width: 768px){
        padding: calc(10rem/16) 0 calc(10rem/16);
    }
}

.mega-nav__title--level-2{
    @media screen and (max-width: 767px){
        display: none;
    }
}

.mega-nav.mega-nav--level-2{
    font-size: calc(14rem/16);
    margin-left: calc(20rem/16);

    @media screen and (max-width: 767px){
       margin-top: calc(8rem/16);
    }
    
    @media screen and (min-width: 768px){
       width: 100%;
    }

}

.mega-nav__list--level-2>li{
    @media screen and (min-width: 768px){
        opacity: 0.4;
        margin-bottom: calc(5rem/16);
    }
}

.mega-nav__list--level-2>li:hover,
.mega-nav__list--level-2>li.is-active{
    @media screen and (min-width: 768px){
        opacity: 1;
    }
}


.mega-nav__item--level-2{
    font-family: var(--font-default);
    @media screen and (max-width: 767px){
       margin-bottom: calc(5rem/16);
    }
}
