h1, .h1, h2, .h2, h3, .h3 {
    font-family:var(--font-bold);
}
h1, h2, h3, .h1, .h2, .h3 {
    text-transform:var(--text-transform-uppercase);
}
h2, .h2 {
    letter-spacing:0;
}
@media screen and (min-width: 768px){
    h2, .h2 {
        letter-spacing:0;
    }
}