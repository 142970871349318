.inquiry-box{
    border: 1px solid var(--color-middle-grey);
}

.inquiry-box+.inquiry-box{
    margin-top: calc(60rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(80rem/16);
    }
}

.inquiry-box__item{
    padding: calc(12rem/16) calc(10rem/16) calc(15rem/16);

    @media screen and (min-width: 768px){
        padding: calc(20rem/16) calc(40rem/16) calc(40rem/16);
    }
}

.inquiry-box__item:not(:first-child),
.inquiry-box__reload .inquiry-box__item{
    border-top: 1px solid var(--color-middle-grey);
}

.inquiry-box__title{
    background-color: var(--color-light-grey);
    padding: calc(10rem/16) calc(5rem/16);
    margin-top: calc(-25rem/16);
    margin-bottom: calc(-9rem/16);
    display: inline-block;
    position: relative;
    top: calc(-16rem/16);

    @media screen and (min-width: 768px){
        top: calc(-22rem/16);
        margin-bottom: calc(-5rem/16);

    }
}

