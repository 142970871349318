.hero .category-list{
    @media screen and (min-width: 1200px){
        max-width: 80%;
    }
}

.category-list.is-top{
    margin-bottom: calc(25rem/16);

    @media screen and (min-width: 1200px){
        margin-bottom: calc(30rem/16);
    }
}

.category-list.is-bottom{
    margin-top: calc(20rem/16);

    @media screen and (min-width: 1200px){
        margin-top: calc(35rem/16);
    }
}

.category-list .list-inline-item:not(:last-child){
    margin-right: calc(1rem/16);
}

.category{
    font-size: calc(10rem/16);
    letter-spacing: calc(1.8rem/16);
    text-transform: uppercase;
    background-color: #fff;
    padding: calc(6rem/16) calc(8rem/16);
    margin-bottom: calc(5rem/16);

    @media screen and (min-width: 768px){
        letter-spacing: calc(2.22rem/16);
        padding: calc(8rem/16) calc(12rem/16);

    }
}

.category--outline{
    border: 1px solid var(--color-grey);
    color: var(--color-grey);
}