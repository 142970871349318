.hero {
    position: relative;
}
.hero__overlay {
    position: absolute;
    height: 100%;
    z-index: 2;
    top:0;
    display: flex;
    color: var(--color-dark-grey);
    align-items: center;
    width: 100%;
    @media screen and (max-width: 767px) {
        padding: calc(60rem/16) calc(10rem/16) calc(20rem/16);
        align-items:flex-end;
    }
    @media screen and (min-width: 768px) {
        max-width: 45%;
        padding-top: calc(110rem/16);
        right: calc(90rem/16);
    }
}

.has-back-btn .hero__overlay{
    @media screen and (max-width: 767px){
       padding-bottom: calc(45rem/16);
    }
}


.hero__overlay-inner {
    width: 100%;
    @media screen and (max-width: 767px) {
        text-align:center;
    }
}
.hero__image {
    position: relative;
}
.hero__image:after {
    content:'';
    position: absolute;
    right:calc(-1rem/16);
    top:0;
    height: 100%;
    width: 40%;
    transform: scaleX(-1);
    background: linear-gradient(90deg, #F0F0F0 0%, rgba(255,255,255,0) 100%);
    @media screen and (max-width: 767px) {
        width: 100%;
        height: 90%;
        top:initial;
        bottom:calc(-2rem/16);
        background: linear-gradient(0deg, #F0F0F0 0%, rgba(255,255,255,0) 100%);
    }
}
.hero__title {
    font-size: calc(32rem/16);
    @media screen and (min-width: 768px) {
        letter-spacing: calc(10rem/16);
        font-size: clamp(calc(40rem/16), 3.9vw, calc(75rem/16));
    }
    @media screen and (max-width: 767px){
       letter-spacing: calc(3rem/16);
    }
}

.hero__sub-title{
    margin-bottom: calc(10rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 768px){
       justify-content: flex-start;
        margin-bottom: calc(20rem/16);

    }
}

.hero__sub-title .icon{
    font-size: calc(18rem/16);
    position: relative;
    top: -1px;
    margin-right: calc(5rem/16);
}

.hero-info-box{
    padding: calc(15rem/16);
    background-color: #fff;
    display: inline-block;
    margin-top: calc(10rem/16);
    text-align: left;
    @media screen and (min-width: 1200px){
        padding: calc(25rem/16) calc(40rem/16);
        margin-top: calc(30rem/16);
    }
    @media screen and (min-width: 768px){
        padding: calc(15rem/16) calc(18rem/16);
        margin-top: calc(12rem/16);
    }
}

.hero-info-box__subitle{
    color: var(--color-grey);
    text-transform: uppercase;
    font-size: calc(12rem/16);
    letter-spacing: calc(3rem/16);
}
.hero-info-box__title{
    font-size: calc(22rem/16);
    font-family: var(--font-default-bold);
    letter-spacing: calc(1rem/16);
    text-transform: uppercase;
    display: inline-block;

    @media screen and (min-width: 1200px){
        font-size: calc(27rem/16);
    }

    @media screen and (min-width: 768px){
        letter-spacing: calc(3rem/16);
    }
}

.hero-info-box__text{
    text-transform: uppercase;
    margin-top: calc(3rem/16);

    @media screen and (min-width: 1200px){
        margin-top: calc(8rem/16);
    }
    
    @media screen and (max-width: 767px){
       font-size: calc(12rem/16);
    }
}


.hero-info-box__item{
    position: relative;
}

.hero-info-box__item+.hero-info-box__item{
    margin-left: calc(60rem/16);
    @media screen and (min-width: 768px){
        margin-left: calc(80rem/16);
    }
}

.hero-info-box__item:not(:first-child):before{
    content: '';
    position: absolute;
    height: calc(4rem/16);
    width: calc(25rem/16);
    background-color: var(--color-primary);
    left: calc(-18rem/16);
    top: calc(30rem/16);
    z-index: 1;
    transform: translateX(-100%);
    
    @media screen and (min-width: 768px){
        left: calc(-28rem/16);
    }
}

.hero-info-box__item .hero-info-box__title{
    font-size: calc(20rem/16);
    white-space: nowrap;
    @media screen and (min-width: 1200px){
       font-size: calc(25rem/16);
        letter-spacing: calc(1.56rem/16);
    }

    @media screen and (min-width: 768px){
        font-size: calc(22rem/16);
        letter-spacing: calc(1.2rem/16);
    }
}

.hero__back-btn{
    position: relative;
    z-index: 10;
    
    @media screen and (max-width: 767px){
       text-align: center;
    }

    @media screen and (min-width: 768px){
        position: absolute;
        bottom: calc(25rem/16);
        left: calc(25rem/16);
    }
}

@media screen and (max-width: 767px){
   .hero__back-btn .btn{
       height: calc(30rem/16);
       padding: calc(9rem/16) calc(10rem/16);
       font-size: calc(10rem/16);
   }
}

.hero__video {
    object-fit: cover;
}