.radio-button label {
    display: flex;
}

.radio-button__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.radio-button__label {
    border-radius: 0;
    padding: calc(13rem/16) calc(25rem/16);
    background: #fff;
    cursor: pointer;
    font-size: calc(12rem/16);
    line-height: 1;
    letter-spacing: calc(3rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    transition: all 150ms ease;
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16) calc(15rem/16);
    }
}

.radio-button__label:hover {
    background: var(--color-dark);
    color: #fff;
}

.radio-button__input:checked ~ .radio-button__label {
    background: var(--color-dark);
    color:#fff;
    padding: calc(18rem/16) calc(25rem/16);
    box-shadow: 0 calc(10rem/16) calc(20rem/16) 0 rgba(0,0,0,0.3);
    @media screen and (max-width: 767px) {
        box-shadow: 0 calc(10rem/16) calc(10rem/16) 0 rgba(0,0,0,0.2);
        padding: calc(13rem/16) calc(15rem/16);
    }
}

.radio-button__input:disabled ~ .radio-button__label.radio-button__label {
    background: var(--color-grey);
    color: var(--color-dark-grey);
    pointer-events: none;
}

.radio-button-wrapper {
    display:flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
        overflow-x: auto;
        overflow-y:hidden;
        flex-wrap: nowrap;
        white-space: nowrap;
        justify-content: flex-start;
    }
}

.radio-button-wrapper--underlined {
    width: 100%;
    background-color:#fff;
}
.radio-button--underlined {
    height: 100%;
}
.radio-button--underlined label {
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.radio-button--underlined .radio-button__label {
    padding: calc(19rem / 16) calc(15rem / 16);
    color: var(--color-text-muted);
    justify-content: center;
    align-items: center;
    letter-spacing: calc(1rem/16);
    position: relative;
    text-align:center;
    @media screen and (min-width: 768px) {
        padding: calc(19rem / 16) calc(10rem / 16);
    }
}
.radio-button--underlined .radio-button__label:hover,
.radio-button--underlined .radio-button__input:checked ~ .radio-button__label {
    background: #fff;
    padding: calc(19rem / 16) calc(10rem / 16);
    color: var(--color-secondary);
    box-shadow: none;

    &:before {
        content:'';
        position: absolute;
        height: calc(2rem/16);
        width: auto;
        left: calc(23rem/16);
        right: calc(23rem/16);
        bottom: calc(15rem/16);
        background-color: var(--color-primary);
    }

    @media screen and (max-width: 767px) {
        box-shadow: 0 calc(10rem/16) calc(10rem/16) 0 rgba(0,0,0,0.2);
        padding: calc(19rem / 16) calc(15rem / 16);
    }
}