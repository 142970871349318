
.download__body{
    background-color: #fff;
    padding: calc(15rem/16);
    display: flex;
    position: relative;
    transition: all 250ms ease;

    @media screen and (min-width: 768px){
        padding: calc(25rem/16) calc(30rem/16);
    }
}

.download:hover .download__body{
    box-shadow: -6px 19px 23px -2px rgba(0,0,0,.2);
}

.download--small .download__body{
    display: block;
    @media screen and (min-width: 768px){
       padding: calc(20rem/16) calc(30rem/16);
    }
}

.download__info{
    font-size: calc(10rem/16);
    letter-spacing: calc(1rem/16);
    line-height: 1.5;
    color: var(--color-grey);
    white-space: nowrap;
    margin-left: auto;

    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);
        letter-spacing: calc(3rem/16);
    }
}

.download--small .download__info{
    margin-top: calc(3rem/16);
}

.download__title{
    font-size: calc(12rem/16);
    line-height: calc(20/15);
    letter-spacing: calc(1.5rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    margin-right: calc(15rem/16);
    @media screen and (min-width: 768px){
        margin-right: calc(20rem/16);
        font-size: calc(15rem/16);
        letter-spacing: calc(2.5rem/16);
    }
}

.download__body:before {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    width: calc(40rem/16);
    background-color: #fff;
}


.download .circle-icon{
    position: relative;
    z-index: 2;

    @media screen and (max-width: 767px){
       width: calc(50rem/16);
       height: calc(50rem/16);
    }
}

.download .circle-icon__inner{
    @media screen and (max-width: 767px){
        width: calc(45rem/16);
        height: calc(45rem/16);
    }
}

