.quickfilter{
    background-color: #fff;
    border: none;
    font-size: calc(10rem/16);
    letter-spacing: calc(2.22rem/16);
    line-height: 1;
    text-transform: uppercase;
    color: var(--color-grey);
    padding: calc(8rem/16) calc(12rem/16);
    transition: all 150ms ease;

    @media screen and (min-width: 768px){
       height: calc(30rem/16);
    }
}

.quickfilter:hover{
    background-color: #fafafa;
}

.quickfilter>span{
    pointer-events: none;
}

.quickfilter__icon{
    font-size: calc(5rem/16);
    margin-left: calc(3rem/16);
}

.quickfilter--all{
    background-color: transparent;
    color: var(--color-dark);
    font-family: var(--font-default-bold);
}

.quickfilter--all .quickfilter__icon{
    font-size: calc(13rem/16);
    vertical-align: calc(-2rem/16);
    margin-left: 0;
}
.quickfilter-list .list-inline-item:not(:last-child){
    margin-right: calc(5rem/16);
}