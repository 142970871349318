.loading-overlay-container {
    position: relative;
    overflow: hidden;
}
.loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, .7);
    z-index: 10;
    text-align: center;
}
.loading-overlay--shop-modal {
    position: relative;
    width: 100%;
    height: calc(300rem/16);
    display: flex;
    background: rgba(255, 255, 255, .3);
    justify-content: center;
    align-items: center;
    font-size: calc(18rem/16);
}