@media screen and (min-width: 768px) {

    .is-open>.mega-nav--level-1,
    .is-open--only-desktop>.mega-nav--level-1{
        visibility: visible;
        opacity: 1;
    }
    
    .mega-nav__item--level-1:not(:last-child){
        margin-bottom: calc(7rem/16);
    }

}

.mega-nav--level-1{
    display: block;
    position: fixed;
    margin: 0;
    z-index: 20;

    @media screen and (min-width: 768px){
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(100%);
        padding-left: calc(170rem/16);
        padding-right: calc(10rem/16);
        visibility: hidden;
        opacity: 0;
        overflow-y: auto;
        height: 100%;
    }
    
    @media screen and (max-width: 767px){
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        visibility: hidden;
        transform: translateX(100%);
        transition: 120ms ease;
        padding: calc(30rem/16);
        background-color: var(--color-default);
        left: 0;
        bottom: 0;
        top: calc(60rem/16);
        width: 100%;
    }
}

.is-open>.mega-nav--level-1{
    visibility: visible;

    @media screen and (min-width: 768px){
        opacity: 1;
    }

    @media screen and (max-width: 767px){
        z-index: 9;
        transform: translateX(0);
        margin-bottom: 5.5rem;
    }
}

.is-open--only-desktop>.mega-nav--level-1{
    @media screen and (min-width: 768px){
        opacity: 1;
    }
}

.mega-nav__item--level-1{
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    
    @media screen and (max-width: 767px){
       opacity: 0.4;
    }
}

.mega-nav__item--level-1.is-open{
    @media screen and (max-width: 767px){
       opacity: 1;
    }
}

.mega-nav__item--level-1:not(:last-child){
    @media screen and (max-width: 767px){
        margin-bottom: calc(7rem/16);
    }
}

.mega-nav__list--level-1{
    padding: 0 calc(20rem/16);
}

.mega-nav__item-content--level-1{

    @media screen and (max-width: 767px){
       padding: calc(2rem/16) 0;
    }
}

@media screen and (min-width: 768px){
    .mega-nav__list--level-1>li {
        opacity: 0.4;
    }

    .mega-nav__list--level-1 .mega-nav__item--level-1:hover,
    .mega-nav__list--level-1 .mega-nav__item--level-1.is-active {
        opacity: 1;
    }
}

/* width */
.mega-nav--level-1::-webkit-scrollbar {
    width: 3px;
    height: 4px;
}

/* Track */
.mega-nav--level-1::-webkit-scrollbar-track {
    background: var(--color-light-grey);
}

/* Handle */
.mega-nav--level-1::-webkit-scrollbar-thumb {
    background: var(--color-grey);
    border-radius: 1000px;
}


/* Handle on hover */
.mega-nav--level-1::-webkit-scrollbar-thumb:hover {
    background: #c5bfb7;
}