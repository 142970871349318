.hero-inspire-wrapper {
    position: relative;
    padding-bottom: calc(40rem/16);
    @media screen and (max-width: 767px) {
        padding-bottom: calc(30rem/16);
    }
}
.hero-inspire {
    overflow:hidden;
    position: relative;
}
.hero-inspire__title {
    font-size: clamp(3rem, 6.7vw, 8.125rem);
    position: absolute;
    z-index: 9;
    letter-spacing: calc(26rem/16);
    width: 100%;
    text-align:center;
    line-height: 1;
    text-transform: uppercase;
    font-family:var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size: calc(40rem/16);
        letter-spacing: calc(10rem/16);
        margin-top:0;
    }
}
.hero-inspire__title--color {
    font-size: clamp(3rem, 6.7vw, 8.125rem);
    color: var(--color-turquoise);
    display: block;
    text-transform: uppercase;
    letter-spacing: calc(26rem/16);
    line-height: 1;
    font-family: var(--font-default-bold);
    position: absolute;
    z-index: -3;
    width: 100%;
    text-align:center;
    @media screen and (max-width: 767px) {
        font-size: calc(40rem/16);
        letter-spacing: calc(10rem/16);
        margin-top:0;
    }
}

.hero-inspire__title--color.color-blue{
    color: #93B8D1;
}

.hero-inspire__title--color.color-green{
    color: #A2AF69;
}

.hero-inspire__title--color.color-brown{
    color: #B39875;
}

.hero-inspire__title--color.color-grey-brown{
    color: #ACA3A3;
}

.hero-inspire__image {
    z-index: 1;
}