.blog-teaser-highlight{
    background-color: #fff;
}

.blog-teaser-highlight__img-wrapper{
    position: relative;
}
.blog-teaser-highlight__img-wrapper:after{
    content: "";
    position: absolute;
    right: calc(-1rem/16);
    top: 0;
    height: 100%;
    width: 40%;
    transform: scaleX(-1);
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
}

@media screen and (min-width: 768px) {
    .blog-teaser-highlight:before {
        content: '';
        box-shadow: 0 calc(42rem / 16) calc(60rem / 16) 0 rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        opacity: 0;
        transition: opacity 0.3s ease;
        will-change:opacity;
    }
    .blog-teaser-highlight:hover:before {
        opacity:1;
    }
    .blog-teaser-highlight__img-wrapper{
        overflow: hidden;
    }
    .blog-teaser-highlight__img {
        transform-origin: center;
        transition: transform 0.3s ease;
        will-change:transform;
    }
    .blog-teaser-highlight:hover .blog-teaser-highlight__img {
        transform: scale(1.03);
    }
}

.blog-teaser-highlight__title{
    font-size: calc(22rem/16);
    font-family: var(--font-default-bold);
    text-transform: none;
    margin-top: calc(15rem/16);
    margin-bottom: calc(20rem/16);
    line-height: calc(28/22);
    letter-spacing: normal;
    @media screen and (min-width: 768px){
        transform: translateX(-70px);
        margin-top: calc(30rem/16);
        font-size: calc(28rem/16);
    }
}

.blog-teaser-highlight .author-item{
    @media screen and (min-width: 768px){
        transform: translateX(-70px);
    }
}

.blog-teaser-highlight__text{
    position: relative;
    
    @media screen and (max-width: 767px){
       padding-top: calc(10rem/16);
    }
}

.blog-teaser-highlight__text:before{
    content: '';
    position: absolute;
    height: calc(4rem/16);
    width: calc(40rem/16);
    background-color: var(--color-primary);
    top: calc(-8rem/16);
    left: 0;    
    @media screen and (min-width: 768px){
        top: calc(4rem/16);
        left: calc(-30rem/16);
        transform: translateX(-100%);
    }
}

.blog-teaser-highlight__body{
    @media screen and (max-width: 767px){
       padding: calc(10rem/16) calc(30rem/16) calc(25rem/16);
    }
}

.blog-teaser-highlight .author-item__img{
    @media screen and (max-width: 767px){
        margin-top: calc(-40rem/16);
        position: relative;
        z-index: 3;
    }

}