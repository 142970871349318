.cart-item {
    background: #fff;
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}
.cart-item-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.cart-item__header {
    padding: calc(20rem/16) calc(10rem/16) 0 calc(20rem/16);
    @media screen and (min-width: 768px) {
        padding: calc(30rem/16) calc(20rem/16) 0 calc(30rem/16);
    }
}
.cart-item__title {
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    letter-spacing: calc(2rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(16rem/16);
        letter-spacing: calc(4rem/16);
    }
}
.cart-item__date, .cart-item__time {
    font-family: var(--font-default-bold);
    letter-spacing: calc(1rem/16);
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 768px) {
        letter-spacing: calc(2rem/16);
    }
}
.cart-item__footer {
    padding: 0 calc(20rem/16) calc(20rem/16);
    font-family: var(--font-default-bold);
    display: flex;
    flex-direction: column;
    margin-top: auto;
    @media screen and (min-width: 768px) {
        padding: 0 calc(30rem/16) calc(30rem/16);
    }
}
.cart-item__footer-inner {
    padding-top: calc(20rem/16);
    border-top: calc(1rem/16) solid var(--color-light-grey);
}
.cart-item__body {
    padding: calc(10rem/16) calc(20rem/16);
    @media screen and (min-width: 768px) {
        padding: calc(20rem/16) calc(30rem/16);
    }
}
.cart-item__price {
    font-size: calc(16rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(18rem/16);
    }
}
.cart-item__delete-btn, .cart-item__edit-btn {
    font-size: calc(16rem / 16);
    padding: calc(8rem/16);
    display: inline-flex;
    @media screen and (min-width: 768px) {
        padding: 0 calc(10rem / 16) calc(10rem / 16);
    }
}

.cart-item--small .cart-item__body {
    padding: calc(5rem/16) calc(10rem/16);
    @media screen and (min-width: 768px) {
        padding: calc(5rem/16) calc(20rem/16);
    }
}
.cart-item--small .cart-item__footer {
    padding: 0 calc(20rem/16) calc(10rem/16);
    @media screen and (min-width: 768px) {
        padding: 0 calc(10rem/16) calc(20rem/16);
    }
}
.cart-item--small .cart-item__footer-inner {
    padding-top: calc(10rem/16);
}
.cart-item--small .cart-item__header {
    padding: calc(10rem/16) calc(10rem/16) 0 calc(10rem/16);
    @media screen and (min-width: 768px) {
        padding: calc(20rem/16) calc(20rem/16) 0 calc(20rem/16);
    }
}