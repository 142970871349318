.snow-item {
    background: linear-gradient(180deg, #FFFFFF 0%, #F0F0F0 100%);
    padding: calc(50rem/16) calc(20rem/16) calc(20rem/16);
    color: var(--color-secondary);
    @media screen and (max-width: 767px) {
        padding: calc(15rem/16) calc(5rem/16);
    }
}
.snow-item__title {
    text-align: center;
    letter-spacing: calc(4rem/16);
    font-size: calc(21rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
        letter-spacing: calc(1rem/16);
    }
}
.snow-item__title:after {
    content: '';
    position: relative;
    background-color: var(--color-primary);
    height: calc(4rem/16);
    width: calc(40rem/16);
    display: block;
    margin: calc(15rem/16) auto calc(30rem/16);
}
.snow-item__text-item-title {
    font-size: calc(18rem/16);
    font-family: var(--font-default-bold);
    letter-spacing: calc(2.7rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(13rem/16);
        letter-spacing: calc(1rem/16);
    }
}
.snow-item__text-item-text {
    text-transform: uppercase;
    font-size: calc(12rem/16);
    letter-spacing: calc(3rem/16);
    color: var(--color-grey);
    margin-top: calc(10rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(10rem/16);
        letter-spacing:calc(1rem/16);
        margin-top: calc(5rem/16);
    }
}
.snow-item__avalanche-img {
    margin: 0 auto calc(22rem/16);
    display: flex;
    max-width: calc(80rem/16);
}