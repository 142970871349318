.mega-nav{
    position: relative;
    @media screen and (min-width: 768px){
       width: calc(440rem/16);
        height: 100%;
    }
}

.mega-nav__item-content--toggle {
    display: none;
}

.mega-nav__item-content{
    background-color: transparent;
    border: none;
    color: #fff;
    padding: 0;
    text-align: left;
    position: relative;
   /* opacity: 0.4;*/

}

.mega-nav__item-content:hover,
.is-open>.mega-nav__item-content,
.is-active>.mega-nav__item-content,
.mega-nav__item-content:focus{
    opacity: 1;
    outline: 0;
}

@media screen and (min-width: 768px){
    .is-open--only-desktop>.mega-nav__item-content{
        opacity: 1;
    }
}

.mega-nav__collapse-icon{
    font-size: calc(9rem/16);
    margin-left: calc(8rem/16);
    position: absolute;
    right: calc(-23rem/16);
    top: calc(7rem/16);
    transform: rotate(180deg);
}

.collapsed .mega-nav__collapse-icon{
    transform: rotate(0deg);
}

@media screen and (max-width: 767px){
    .mega-nav__go-back{
        background-color: transparent;
        border: none;
        font-family: var(--font-default-bold);
        font-size: calc(12rem/16);
        letter-spacing: calc(2.77rem/16);
        text-transform: uppercase;
        color: #fff;
        margin-bottom: calc(20rem/16);
        padding: 0;
    }

    .mega-nav__go-back .icon{
        font-size: calc(9rem/16);
    }

    .mega-nav__title{
        font-size: calc(28rem/16);
        font-family: var(--font-default-bold);
        padding-bottom: calc(10rem/16);
        margin-bottom: calc(20rem/16);
        position: relative;
    }

    .mega-nav__title:after{
        content: '';
        position: absolute;
        width: calc(36rem/16);
        height: calc(4rem/16);
        background-color: var(--color-primary);
        bottom: 0;
        left: 0;
    }

}
/*.simple-nav--mega-level-1 {
    right: 0;
}
@media screen and (min-width: 768px) {
    .simple-nav__item--mega-level-0 {
        position: static;
    }
    .simple-nav--mega-level-1 {
    }
    .simple-nav__list--mega-level-1 {
        flex-direction: column;
        flex: auto;
    }
}*/
