.booking-teaser{
    position: relative;
    padding-bottom: calc(23rem/16);
    
    @media screen and (min-width: 768px){
        padding-bottom: calc(25rem/16);
    }
}

.booking-teaser:after{
    content: '';
    position: absolute;
    top: 0;
    bottom: calc(23rem/16);
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
    z-index: 1;
    transition: opacity 350ms ease;

    @media screen and (min-width: 768px){
        bottom: calc(25rem/16);
    }
}

.booking-teaser:hover:after{
    opacity: 0.45;
}

.booking-teaser__body{
    color: #fff;
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 calc(12rem/16) calc(60rem/16);

    @media screen and (min-width: 1200px){
        padding: 0 calc(30rem/16) calc(75rem/16);
    }

    @media screen and (min-width: 768px){
        padding: 0 calc(20rem/16) calc(75rem/16);
    }
}

.booking-teaser__toptitle{
    @media screen and (max-width: 767px){
        margin-bottom: calc(3rem/16);
        font-size: calc(10rem/16);
        letter-spacing: calc(1.8rem/16);
    }
}

.booking-teaser__title{
    text-transform: uppercase;
    letter-spacing: calc(1rem/16);

    @media screen and (max-width: 767px){
        font-size: calc(16rem/16);
        letter-spacing: calc(0.8rem/16);
        -webkit-hyphenate-limit-before: 10;
        -webkit-hyphenate-limit-after: 10;
        -moz-hyphenate-limit-chars: 13 3 2;
        -webkit-hyphenate-limit-chars: 13 3 2;
        -ms-hyphenate-limit-chars: 13 3 2;
        hyphenate-limit-chars: 13 3 2;
        -ms-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }
}

.booking-teaser__btn-wrapper {
    position: absolute;
    left:0;
    bottom:0;
    right:0;
    margin-left: calc(12rem/16);
    margin-right: calc(12rem/16);
    @media screen and (min-width: 768px){
        margin-left: calc(30rem/16);
        margin-right: calc(30rem/16);
    }
}

.booking-teaser__btn{
    margin-top: calc(22rem/16);
    padding: calc(10rem/16) calc(10rem/16) calc(7rem/16);
    min-height: calc(49rem/16);
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 1200px){
        padding: calc(19rem/16) calc(20rem/16) calc(17rem/16);
    }

    @media screen and (min-width: 768px){
        padding: calc(10rem/16) calc(20rem/16) calc(9rem/16);
        margin-top: calc(30rem/16);
        min-height: calc(57rem/16);
    }

    @media screen and (max-width: 767px){
        line-height: 1.3;
    }
}

/* Booking Teaser Scrolling Area */

.scrolling-area--teaser .scrolling-area__arrows{
    @media screen and (min-width: 768px){
        position: absolute;
        transform: translateX(100%);
        bottom: calc(25rem/16);
        right: 0;
    }

    @media screen and (min-width: 1200px){
        bottom: calc(55rem/16);
    }

    @media screen and (max-width: 767px){
        margin: 10px 0;
    }
}

.scrolling-area--teaser .scrolling-area__item{
    @media screen and (max-width: 767px){
       width: 66%;
    }
}

