.search-teaser{
    background-color: #fff;
    padding: calc(25rem/16) calc(30rem/16);
    position: relative;

    @media screen and (min-width: 768px){
        padding: calc(35rem/16) calc(50rem/16);
    }
}

@media screen and (min-width: 768px) {
    .search-teaser:before {
        content: '';
        box-shadow: 0 calc(12rem / 16) calc(30rem / 16) 0 rgba(0, 0, 0, 0.25);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        opacity: 0;
        transition: opacity 0.3s ease;
        will-change:opacity;
    }
    .search-teaser:hover:before {
        opacity:1;
    }

}

.search-teaser__href{
    color: var(--color-grey);
    margin-bottom: calc(15rem/16);
}

.search-teaser__title{
    text-transform: none;
    letter-spacing: normal;
    margin-bottom: calc(5rem/16);
}

