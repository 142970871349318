.title-block--main .title-block__top-title{
    font-size: calc(12rem/16);
    text-transform: uppercase;
    letter-spacing: calc(3rem/16);
    margin-bottom: calc(10rem/16);

}

.title-block__top-title{
    margin-bottom: calc(5rem/16);
}

.title-block__title{
    position: relative;
    padding-bottom: calc(12rem/16);
    margin-bottom: calc(30rem/16);
}

.title-block__title--red-line{
    padding-bottom: calc(22rem/16);
}

.title-block__title--red-line:after{
    content: '';
    position: absolute;
    background-color: var(--color-primary);
    height: calc(4rem/16);
    width: calc(40rem/16);
    bottom: 0;
    left: 0;
}

.title-block--is-centered {
    text-align: center;
    justify-content: center;
    display: flex;
}

.title-block--is-centered .title-block__title--red-line:after {
    left: 50%;
    transform: translateX(-50%);
}

.title-block__icon-img{
    margin-right: calc(10rem/16);
    margin-top: calc(-12rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(-28rem/16);
        margin-right: calc(15rem/16);
    }
}