.img-download-content__body{
    padding: calc(30rem/16);
    background-color: #fff;
    
    @media screen and (min-width: 768px){
        padding: calc(150rem/16) calc(70rem/16);

    }
}

.img-download-content__wrapper{
    background-color: var(--color-light-grey);
}

.img-download-content__title{
    font-family: var(--font-default-bold);
    font-size: calc(20rem/16);
    text-transform: uppercase;
    letter-spacing: calc(5rem/16);
    margin-bottom: calc(10rem/16);
}


.img-download-content__toptitle{
    font-size: calc(12rem/16);
    color: var(--color-grey);
    margin-bottom: calc(5rem/16);
}