.img-download-teaser{
    border: 1px solid var(--color-middle-grey);
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.img-download-teaser__img-wrapper{
    position: relative;
}
.img-download-teaser__body{
    padding: calc(15rem/16) calc(20rem/16);
    background-color: #fff;
    height: 100%;
}

.img-download-teaser__title{
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    text-transform: none;
    letter-spacing: normal;

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
    }
}

.img-download-teaser__subtitle{
    color: var(--color-grey);
    font-size: calc(12rem/16);
    margin-top: calc(5rem/16);
}

.img-download-teaser-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 11;
    background-color: #fff;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    visibility: hidden;
    transition: all 200ms ease;
    pointer-events: none;
    
    @media screen and (max-width: 767px){
       display: none;
    }
}

.img-download-teaser:hover .img-download-teaser-overlay{
    opacity: 0.7;
    visibility: visible;
}

.img-download-teaser-overlay__body{
    position: relative;
    z-index: 12;


}


.img-download-teaser-overlay__icon{
    font-size: calc(40rem/16);
    margin-bottom: calc(20rem/16);
}

.img-download-teaser-overlay__text{
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    letter-spacing: calc(1.5rem/16);
    text-transform: uppercase;
}

.img-download-teaser .teaser-badge{
    position: relative;
    top: 0;
    background: #ffffff;
    border: 1px solid #ffffff;
    margin-bottom: calc(9rem/16);
    display: inline-block;
    letter-spacing: calc(1.5rem/16);
    font-size: calc(10rem/16);
    padding: 0;
}
