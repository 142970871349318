.img-with-text-wide-area{
    position: relative;
}

.img-with-text-wide-area__img-small img{
    filter: grayscale(1);
    opacity: 0.5;
}

.img-with-text-wide-area__img-big:after{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 25%;
    right: 0;
    z-index: 1;
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%)
}

.img-with-text-wide-area__content{
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
    max-width: calc(800rem/16);
    padding: 0 calc(10rem/16);
}

.img-with-text-wide-area__title{
    text-shadow: 0 0 70px #000000;
    font-size: calc(20rem/16);
    letter-spacing: calc(4rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(60rem/16); /* fallback */
        font-size: clamp(calc(40rem/16), 4.1vw, calc(60rem/16));
        letter-spacing: calc(12rem/16);
        line-height: 1.2;
    }
}

.img-with-text-wide-area__toptitle{
    text-shadow: 0 0 70px #000000;
    
    @media screen and (min-width: 768px){
        margin-bottom: calc(15rem/16);
    }
    
    @media screen and (max-width: 767px){
       font-size: calc(10rem/16);
    }

}