@import './microanimations-checkmark.css'; /* just for testing, later remove and add to style-shop - Todo Johannes*/

:root {
    --microanimations-stagger-factor: 0;
    --microanimations-stagger-base: 0.15s; /* can be overwritten through inline css variable definition */
}

.microanimations--fade {
    opacity: 0;
    transition-delay: calc(var(--microanimations-stagger-factor) * var(--microanimations-stagger-base));
}

.microanimations--fade-active {
    opacity: 1;
    transition-duration: 0.4s;
    transition-property: all;
    transition-timing-function: ease-in-out;
}

.microanimations--first-fold {
    animation-name: microanimations--fade-in;
    animation-duration: 0.4s;
    animation-delay: calc(var(--microanimations-stagger-factor) * var(--microanimations-stagger-base));
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
}

@keyframes microanimations--fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.microinteractions__order-first {
    order: -1;
}
/* ============================================== */
/* configurator summary animation */
/* ============================================== */

.ticket-configuration__checkout-animation-wrapper {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 100%;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: .5rem;
}

.ticket-configuration__checkout-animation--cart,
.ticket-configuration__checkout-animation--check {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    margin-right: 0;
}

.ticket-configuration__checkout-animation--cart {
    scale: 2;
    animation: ticket-configuration__checkout-animation--cart 2.75s 0.2s cubic-bezier(0.65, 0, 0.35, 1) forwards;
    stroke: black;
    fill: none;
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    position: relative;
    z-index: 1;
    overflow: visible;
}

.ticket-configuration__checkout-animation--cart path {
    fill: none;
}

.ticket-configuration__checkout-animation--check {
    scale: 2;
    --checkmark-foreground: var(--color-success);
}

.ticket-configuration__checkout-animation--check-circle {
    fill: var(--color-light-grey);
}

.ticket-configuration__checkout-animation--check-checkmark,
.ticket-configuration__checkout-animation--check-circle,
ticket-configuration__checkout-animation--check {
    animation-delay: 2.95s;
}

@keyframes ticket-configuration__checkout-animation--cart {
    0% {
        stroke-dashoffset: 100;
    }

    50% {
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dashoffset: 100;
    }
}

/* ============================================== */
/* configurator footer button animation */
/* ============================================== */

:root {
    --footer-button-text-color: white;
    --footer-button-text-opacity: 1;
}

.ticket-configuration__footer-btn-icon {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    opacity: 0;
    font-size: 1.75rem;
}

.ticket-configuration__footer-btn-icon--skier {
    animation: configuration-footer-button--jitter .5s 3 alternate, configuration-footer-button--icon-fading 1.5s cubic-bezier(0.83, 0, 0.17, 1) forwards;
}

@keyframes configuration-footer-button--jitter {
    10% {
        transform: translate(-1px, -1.5px);
    }
    20% {
        transform: translate(1.5px, 0.75px);
    }
    30% {
        transform: translate(-2px, -3px);
    }
    40% {
        transform: translate(.5px, 1.5px);
    }
    50% {
        transform: translate(-.75px, 0px);
    }
    60% {
        transform: translate(0px, 1.25px);
    }
    70% {
        transform: translate(-.75px, -2px);
    }
    80% {
        transform: translate(1.5px, 2px);
    }
    90% {
        transform: translate(-2.5px, -1.5px);
    }
    100% {
        transform: translate(0.5px, 1.5px);
    }
}

@keyframes configuration-footer-button--icon-fading {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes configuration-footer-button--text {
    from {
        opacity: 0;
    } to {
          opacity: var(--footer-button-text-opacity);
      }
}

.ticket-configuration__footer-btn-text--disabled {
    --footer-button-text-color: black;
    --footer-button-text-opacity: 0.65;
    color: var(--footer-button-text-color);
    opacity: var(--footer-button-text-opacity);
}

.ticket-configuration__footer-btn:disabled {
    color: white;
    opacity: 1;
}

.ticket-configuration__footer-btn-text {
    color: var(--footer-button-text-color);
    opacity: 0;
    animation: configuration-footer-button--text 0.5s forwards;
}

.ticket-configuration__footer-btn-text--immediate {
    animation: none;
    opacity: var(--footer-button-text-opacity);
}

/* ============================================== */
/* change-blur animation -> pricing, amount price groups, ... */
/* ============================================== */

.microanimations--change-blur {
    filter: blur(4px);
    animation: change-blur 0.75s cubic-bezier(0.83, 0, 0.17, 1) forwards;
}

@keyframes change-blur {
    from {
        filter: blur(4px);
    }
    to {
        filter: blur(0px);
    }
}

/* ============================================== */
/* cart icon + amount animation */
/* ============================================== */

.animate-cart-icon {
    animation: animate-cart-icon 2s cubic-bezier(0.83, 0, 0.17, 1) forwards;
}

@keyframes animate-cart-icon {
    0% {
        transform: scale(1) rotate(0deg);
    }
    33% {
        transform: scale(1.75) rotate(-25deg);
    }
    66% {
        transform: scale(1.75) rotate(25deg);
    }
    100% {
        transform: scale(1) rotate(0deg);
    }
}

.main-navbar__cart-count.animate-cart-amount {
    animation: animate-cart-amount .75s cubic-bezier(0.83, 0, 0.17, 1) forwards, animate-cart-amount--color .35s .75s cubic-bezier(0.83, 0, 0.17, 1) forwards;
    color: var(--color-primary);
}

@keyframes animate-cart-amount {
    0% {
        transform: scale(20);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes animate-cart-amount--color {
    0% {
        color: var(--color-primary);
    }
    100% {
        color: white;
    }
}

/* ============================================== */
/* morphing button/fullpage modal */
/* ============================================== */

:root {
    --centerShiftValueX: 50vw;
    --centerShiftValueY: 50vh;
    --baseCenterTranslation: translate(calc(-50% + var(--centerShiftValueX)), calc(-50% + var(--centerShiftValueY)));
    --buttonActualHeight: 0px;
    --circleSmallScale: 0;
    --circleLargeScale: 0;
    --clip-path-url: #skiTicketModalMask;
    --morphing-duration: 500ms;
}

.microinteractions__morphing-button-wrapper.btn-primary,
.microinteractions__morphing-button-wrapper.btn-primary::before {
    transition: .2s ease-in-out box-shadow;
}

.microinteractions__morphing-button-wrapper.btn-primary.is-animating,
.microinteractions__morphing-button-wrapper.btn-primary.is-animating::before {
    border: none;
    outline: none;
    box-shadow: none;
}

.microinteractions__morphing-button-wrapper.btn-primary {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    background: transparent;
    padding: 0;

    @media screen and (max-width: 768px){
        overflow: hidden;
    }
}

.microinteractions__morphing-button-wrapper .microinteractions__morphing-button-text {
    padding: calc(10rem/16) calc(10rem/16) calc(7rem/16);
    white-space: break-spaces;

    @media screen and (min-width: 1200px){
        padding: calc(19rem/16) calc(20rem/16) calc(17rem/16);
    }

    @media screen and (min-width: 768px){
        padding: calc(10rem/16) calc(20rem/16) calc(9rem/16);
    }
}

.microinteractions__morphing-button-background {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    background: var(--color-primary);
    width: 100%;
    height: 100%;
    max-width: 100rem;
    border-radius: 0;
    transition: border-radius var(--morphing-duration) cubic-bezier(0.83, 0, 0.17, 1), max-width var(--morphing-duration) cubic-bezier(0.83, 0, 0.17, 1);

    &.microinteractions__morphing-button-background--wide {
        max-width: 100rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        white-space: nowrap;
        margin-left: auto;
        margin-right: auto;
    }
}

.microinteractions__morphing-button-background.is-animating {
    border-radius: var(--buttonActualHeight);
    max-width: var(--buttonActualHeight);
}

.microinteractions__morphing-button-icon {
    transition: var(--morphing-duration) cubic-bezier(0.83, 0, 0.17, 1) opacity;
}

.microinteractions__morphing-button-text {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    opacity: 1;
    transition: var(--morphing-duration) cubic-bezier(0.83, 0, 0.17, 1) opacity;
}

.microinteractions__morphing-button-text.is-animating,
.microinteractions__morphing-button-icon.is-animating {
    opacity: 0;
}

.configurator-modal--morphing {
    transition: var(--morphing-duration) ease-in-out opacity;
    opacity: 0;
}

.configurator-modal--morphing.is-active {
    opacity: 1;
}

.ski-ticket-modal__global-mask-wrapper {
    height: 0;
}

.ski-ticket-modal__global-mask {
    position: fixed;
    top: 0;
    left: 0;
}

.ski-ticket-modal__global-mask-circle {
    transform-origin: center center;
}

.ski-ticket-modal__global-mask-circle.is-animating {
    animation: toFullpageCircle var(--morphing-duration) cubic-bezier(0.83, 0, 0.17, 1) forwards;
}

.ski-ticket-modal__global-mask-circle.is-animating-out {
    animation: toSmallCircle var(--morphing-duration) cubic-bezier(0.83, 0, 0.17, 1) forwards;
}

@keyframes toFullpageCircle {
    0% {
        transform: var(--baseCenterTranslation) scale(var(--circleSmallScale));
    }
    100% {
        transform: var(--baseCenterTranslation) scale(var(--circleLargeScale));
    }
}

@keyframes toSmallCircle {
    0% {
        transform: var(--baseCenterTranslation) scale(var(--circleLargeScale));
    }
    100% {
        transform: var(--baseCenterTranslation) scale(var(--circleSmallScale));
    }
}

.configurator-modal--morphing.microanimations--no-mask {
    clip-path: unset;
}