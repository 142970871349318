.weather-forecast-item {
    background: linear-gradient(180deg, #FFFFFF 0%, #F0F0F0 100%);
    padding: calc(50rem/16) calc(20rem/16) calc(20rem/16);
    color: var(--color-secondary);
    @media screen and (max-width: 767px) {
        padding: calc(15rem/16) calc(5rem/16);
    }
}

.weather-forecast-item--small {
    padding: calc(36rem/16) calc(20rem/16) calc(26rem/16);
}

.weather-forecast-item__title {
    text-align: center;
    letter-spacing: calc(5rem/16);
    font-size: calc(22rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
        letter-spacing: calc(1rem/16);
    }
}
.weather-forecast-item__date {
    letter-spacing: calc(3rem/16);
    font-size: calc(12rem/16);
    text-align: center;
    display: block;
    @media screen and (max-width: 767px) {
        letter-spacing: calc(1rem/16);
    }
}
.weather-forecast-item__title-wrapper:after {
    content: '';
    position: relative;
    background-color: var(--color-primary);
    height: calc(2rem/16);
    width: calc(25rem/16);
    display: block;
    margin: calc(10rem/16) auto calc(10rem/16);

    @media screen and (min-width: 768px){
        margin: calc(15rem/16) auto calc(30rem/16);
        height: calc(4rem/16);
        width: calc(40rem/16);
    }
}
.weather-forecast-item__list-item + .weather-forecast-item__list-item {
    margin-top: calc(20rem/16);
}
.weather-forecast-item__list-item-title {
    font-size: calc(25rem/16);
    letter-spacing: calc(4rem/16);
    line-height: calc(40/25);
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
        letter-spacing: calc(1rem/16);
    }
}
.weather-forecast-item__list-item-text {
    color: var(--color-grey);
    font-size: calc(12rem/16);
    letter-spacing: calc(3rem/16);
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
        font-size: calc(7rem/16);
        letter-spacing: calc(1rem/16);
        white-space: nowrap;
    }
}
.weather-forecast-item__img {
    width: calc(60rem/16);
    @media screen and (max-width: 767px) {
        width: calc(20rem/16);
    }
}

.weather-forecast-item__list--flex {
    display: flex;
    column-gap: calc(30rem/16);
    justify-content: center;
    & .weather-forecast-item__list-item {
        margin-top: 0;
    }
}

