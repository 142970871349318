.console{
    @media screen and (max-width: 767px){
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        align-items: flex-end;
    }
}

.console__item{
    @media screen and (max-width: 767px){
       align-items: center;
        justify-content: center;
        background-color: #fff;
    }
}

.console__item--ticket{
    @media screen and (max-width: 767px){
        background-color: var(--color-primary);
    }
}

.console__item:not(.console__item--ticket){
    @media screen and (max-width: 767px){
        height: calc(60rem/16);

    }
}
.console__link{
    height: var(--main-navbar-height-xs);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(11rem/16);
    color: var(--color-text-default);
    transition: transform 250ms ease;
    @media screen and (min-width: 1400px){
        height: var(--main-navbar-height-xxl);
        padding: calc(15rem/16);
    }

    @media screen and (min-width: 1200px){
        font-size: calc(14rem/16);
        padding: calc(15rem/16);
    }

    @media screen and (min-width: 768px){
        height: var(--main-navbar-height);
        font-size: calc(12rem/16);
        padding: calc(15rem/16) calc(10rem/16);
    }

    @media screen and (max-width: 767px){
       flex-direction: column;
        padding: 0;
        width: 100%;
    }
}

.console__link:hover{
    transform: translateY(-2px);
}

.experience-area--is-active .console__link{
    @media screen and (min-width: 768px){
       color: #fff;
        transition: all 750ms ease;
    }
}

.console__icon{
    @media screen and (min-width: 1200px){
        font-size: calc(23rem/16);
    }

    @media screen and (min-width: 768px){
        font-size: calc(19rem/16);
        margin-right: calc(6rem/16);
    }
    
    @media screen and (max-width: 767px){
        font-size: calc(21rem/16);
        margin-bottom: calc(3rem/16);
    }
}