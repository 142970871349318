.room-teaser .icon-text__icon{
    font-size: calc(30rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(36rem/16);
    }
}

.room-teaser .scrolling-area__nav{
    position: absolute;
    bottom: 0;
    right: 0;
}

.room-teaser .img-gallery-open__btn{
    transform: none;
    bottom: 0;
    top: auto;
    right: auto;
    left: 0;
    width: calc(160rem/16);
    white-space: normal;
    font-size: calc(9rem/16);
    padding: calc(9rem/16) calc(12rem/16);
    line-height: calc(12rem/16);
    letter-spacing: calc(2rem/16);
    height: calc(40rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    border-right: 1px solid #424242;
    opacity: 1;

    @media screen and (min-width: 768px){
        height: calc(80rem/16);
        letter-spacing: calc(3rem/16);
        font-size: calc(11rem/16);
    }
}

.room-teaser .img-gallery-open__btn:hover{
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.room-teaser__body{
    padding: calc(15rem/16);
    background-color: #fff;
    height: 100%;

    @media screen and (min-width: 1200px){
        padding: calc(35rem/16) calc(60rem/16);
    }

    @media screen and (min-width: 768px){
        padding: calc(25rem/16) calc(40rem/16);
    }
}

.room-teaser .icon-text__text{
    max-width: calc(100rem/16);
    font-size: calc(10rem/16);
    letter-spacing: calc(1rem/16);

    @media screen and (min-width: 768px){
        max-width: calc(155rem/16);
        font-size: calc(11rem/16);
        letter-spacing: calc(1.8rem/16);
    }
}

.room-teaser__icons{
    padding-bottom: calc(10rem/16);
    border-bottom: 1px solid var(--color-middle-grey);
    margin-bottom: calc(25rem/16);
}

.room-teaser__title{
    font-size: calc(20rem/16);
    line-height: calc(25/20);
    letter-spacing: calc(5rem/16);
    position: relative;
    padding-bottom: calc(15rem/16);
    margin-bottom: calc(18rem/16);
}

.room-teaser__title:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(4rem/16);
    width: calc(40rem/16);
    background-color: var(--color-primary);
}
.room-teaser__toptitle{
    text-transform: uppercase;
    font-size: calc(12rem/16);
    letter-spacing: calc(3rem/16);
    margin-bottom: calc(5rem/16);
}

.room-teaser__btn{
    margin-top: calc(10rem/16);
    @media screen and (min-width: 768px){
       width: 75%;
        margin-top: calc(30rem/16);
    }

    @media screen and (min-width: 1200px){
        width: 60%;
    }
}