.wysiwyg-with-media__content.is-right{
    @media screen and (min-width: 768px){
        padding-left: 15%;
    }
}

.wysiwyg-with-media__content.is-left{
    @media screen and (min-width: 768px){
        padding-right: 15%;
    }
}
