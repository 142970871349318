
.anchor-nav__item .nav-link {
    font-size: calc(12rem/16);
    letter-spacing: calc(2.7rem/16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    padding: calc(12rem/16) calc(20rem/16);
    opacity:0.7;
    color: var(--color-default-dark-grey);
    position: relative;
    background-color: transparent;
    transition: opacity 0.2s ease, background-color 0.2s ease;
    will-change: opacity, background-color;
}
.anchor-nav__item {
    margin-right: 0;
}
.anchor-nav__item .nav-link:before {
    content:'';
    width: auto;
    height: calc(3rem/16);
    bottom:0;
    z-index: 2;
    display: block;
    position: absolute;
    left: calc(20rem/16);
    right: calc(20rem/16);
    opacity:0;
    will-change: opacity;
    transition: opacity 0.2s ease;
    background-color: var(--color-primary);
}
.anchor-nav__item .nav-link.active:before {
    opacity:1;
}
.anchor-nav__item .nav-link.active {
    opacity:1;
}
.is-affix .anchor-nav__item .nav-link {
    background-color:#fff;
}
.anchor-nav__list {
    background-color:transparent;
    will-change: opacity;
    transition: opacity 0.2s ease;
    padding-left: calc(15rem/16);
    padding-right: calc(15rem/16);
}
.is-affix .anchor-nav__list {
    background-color:#fff;
}
.anchor-nav {
    z-index: 99;
    border-bottom: 1px solid #dedede;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.is-affix.anchor-nav {
    border-color:transparent;
}