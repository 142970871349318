.tooltip-inner {
    box-shadow: 0 calc(5rem/16) calc(30rem/16) 0 rgba(0,0,0,0.15);
}
.tooltip-inner > a {
    color: var(--color-primary);
}
.tooltip-inner > a:hover {
    text-decoration: underline;
}
.tooltip--left .tooltip-inner {
    text-align: left;
}
.tooltip--large .tooltip-inner {
    max-width: calc(310rem/16);
}
.tooltip__title {
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    margin-bottom: 0;
    letter-spacing: calc(3rem/16);
}
.tooltip__description {
    font-size: calc(12rem/16);
    line-height: calc(20/12);
    color: var(--color-grey);
}
.tooltip__date {
    letter-spacing: calc(2rem/16);
    font-family: var(--font-default-bold);
    font-size: calc(10rem/16);
    text-align: right;
}
.tooltip--bottom-triangle:before {
    content:'';
    position: absolute;
    bottom:0;
    left: 50%;
    transform: translateX(-50%);
    height: calc(10rem/16);
    width: calc(20rem/16);
    background-color: #fff;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
}

.tooltip__detail-price-btn:active, .tooltip__detail-price-btn:focus, .tooltip__detail-price-btn:active:focus {
    background-color: var(--color-dark);
    color: #fff;
}