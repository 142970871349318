/* Overwritting the styles of their widget */

.carnet-davantage .io-voucher-wrapper .io-voucher,
.carnet-davantage .io-voucher-wrapper .no-registration,
.carnet-davantage .io-voucher-wrapper .no-registration p{
    font-family: var(--font-default)!important;
    line-height: 1.5!important;
    font-size: 14px!important;

    @media screen and (min-width: 768px){
        font-size: 16px!important;

    }
}

.carnet-davantage .io-voucher-wrapper .no-registration p em{
    font-size: calc(13rem/16)!important;
}

.carnet-davantage .io-voucher-wrapper .io-voucher .io-voucher-card-number{
    word-break: normal;
    line-height: 1;
    font-size: 32px;
    white-space: nowrap;

    @media screen and (min-width: 1500px){
        font-size: 38px;
    }
}

.carnet-davantage .io-voucher-wrapper .io-voucher .display-3{
    letter-spacing: 1px;
    font-family: var(--font-default-bold);
    text-transform: none;
    font-size: calc(22rem/16);
}

.carnet-davantage .io-voucher-wrapper .btn{
    border-radius: 0;
    font-size: calc(12rem/16);
    font-family: var(--font-default-bold);
    letter-spacing: calc(3rem/16);
    padding: calc(13rem/16) calc(40rem/16) calc(11rem/16);
}

.carnet-davantage .io-voucher-wrapper .hero .btn{
    @media screen and (max-width: 767px){
        letter-spacing: 2px;
        padding: 8px 10px;
        font-size: 10px!important;
    }
}

.carnet-davantage .io-voucher-wrapper .io-voucher-card  .btn-primary{
    background-color: var(--color-primary)!important;
    @media screen and (min-width: 992px){
        margin-bottom: -59px;
    }
}

.carnet-davantage .io-voucher-wrapper .io-voucher .io-voucher-card{
    @media screen and (min-width: 992px){
        overflow: visible;
    }
}

.carnet-davantage .io-voucher-wrapper .io-voucher .io-voucher-card .mb-sm-4{
    @media screen and (min-width: 992px){
       margin-bottom: 0!important;
    }
}
.carnet-davantage .io-voucher-wrapper .io-voucher .io-voucher-card .display-3{
    margin-bottom: calc(5rem/16)!important;
}
.carnet-davantage .io-voucher-wrapper .col-12.col-sm-6.col-md-6.col-lg-4.col-xl-3.mb-4.mb-sm-5 {
    @media screen and (min-width: 992px){
        margin-bottom: 60px!important;

    }
}

.carnet-davantage .io-voucher-wrapper .io-voucher .io-voucher-card-number,
.carnet-davantage .io-voucher-wrapper .io-voucher .io-voucher-card-values,
.carnet-davantage .io-voucher-wrapper .io-voucher .io-voucher-card .display-3{
    text-shadow: 0 1px 5px #383838;
}

.carnet-davantage .io-voucher-card-applyto {
    line-height: 1.1;
    @media screen and (min-width: 992px) and (max-width: 1500px){
        font-size: calc(13rem/16);
    }
}

.carnet-davantage .io-voucher-wrapper .rounded{
    border-radius: 0!important;
}

.carnet-davantage .io-voucher-wrapper .io-voucher .io-voucher-card-values{
    white-space: nowrap;
}

.carnet-davantage .io-voucher-wrapper .bg-primary{
    background-color: var(--color-primary)!important;
}

.carnet-davantage .io-voucher-wrapper .io-voucher .hero-stamp{
    border-radius: 0!important;
}

.carnet-davantage .io-voucher-wrapper .io-voucher .hero-stamp p{
    font-family: var(--font-default-bold)!important;
    line-height: 1.4;
    @media screen and (min-width: 768px){
        font-size: calc(18rem/16)!important;

    }
}

.carnet-davantage .io-voucher-wrapper .io-voucher .display-1{
    letter-spacing: calc(7rem/16);
    font-family: var(--font-default-bold);
    
    @media screen and (max-width: 767px){
        font-size: 25px!important;
        margin-top: 10px!important;
    }
}

.carnet-davantage .io-voucher-wrapper .io-voucher .filters .btn{
    font-size: calc(15rem/16)!important;
    font-family: var(--font-default-bold)!important;
    letter-spacing: calc(2rem/16);
    width: auto!important;
    
    @media screen and (min-width: 768px){
        padding: 7px 13px 3px!important;
    }
    @media screen and (max-width: 767px){
        font-size: calc(11rem/16)!important;
        letter-spacing: calc(1rem/16);
        padding: 5px 6px 2px!important;
    }
}

.carnet-davantage .io-voucher-wrapper .io-voucher .filters-filter{
    font-size: 16px!important;
    letter-spacing: 1px;
}

.carnet-davantage .io-voucher-wrapper .io-voucher .filters-search .form-control{
    font-family: var(--font-default-bold);
    @media screen and (min-width: 768px){
        top: -1px!important;
        font-size: calc(16rem/16)!important;
        letter-spacing: calc(2rem/16);
    }
    @media screen and (max-width: 767px){
        font-size: 12px!important;
        top: -3px!important;
    }
}

.carnet-davantage .io-voucher .io-voucher-detail-header .display-1{
    color: var(--color-default);
    margin-top: calc(20rem/16);
    font-size: 40px!important;
    letter-spacing: 3px!important;
    text-transform: uppercase;
    @media screen and (min-width: 768px){
        letter-spacing: 5px!important;
    }
}

.carnet-davantage .io-voucher-wrapper .modal-content{
    color: var(--color-default);
}

.carnet-davantage .io-voucher-wrapper .io-voucher .io-voucher-detail p{
    font-size: calc(15rem/16)!important;
}

.carnet-davantage .modal-dialog:not(.modal-fullscreen) .modal-content{
    border-radius: 0;
    padding: 0;
}

.carnet-davantage .io-voucher-wrapper .io-voucher .io-voucher-detail .modal-close {
    right: 15px;
    top: 15px;
    width: 43px;
    padding: 8px 8px 8px 8px;
}

.carnet-davantage .io-voucher-wrapper .flickity-viewport{
    border-radius: 0;
}

.carnet-davantage .io-voucher-wrapper .white-space-pre{
    white-space: normal;
}

.carnet-davantage .io-voucher-wrapper .io-voucher .filters-dropdown .dropdown-menu{
    background-color: #fff!important;
    color: var(--color-default);
    border-radius: 0!important;
}

.carnet-davantage .io-voucher-wrapper .io-voucher .filters .custom-control-label:before {
    border: 1px solid var(--color-default);
    width: calc(18rem/16);
    height: calc(18rem/16);
    border-radius: 0;
    top: -2px;
    left: auto!important;
}

.carnet-davantage .io-voucher-wrapper .io-voucher .filters .custom-control-label:after{
    top: -2px;
}

.carnet-davantage .io-voucher-wrapper .io-voucher .filters label{
    color: var(--color-default);
}

.carnet-davantage .io-voucher-wrapper .custom-checkbox .custom-control-input:checked~.custom-control-label:before{
    background-color: var(--color-default);
}

.carnet-davantage .io-voucher-wrapper .io-voucher .filters label {
    font-size: 13px!important;
    letter-spacing: 0.5px;
}

.carnet-davantage .io-voucher-wrapper .io-voucher .filters-search button img {
    @media screen and (max-width: 767px){
        width: 16px;
        margin-top: 3px;
    }

}

.carnet-davantage .io-voucher-wrapper .io-voucher .filters-filter{
    font-family: var(--font-default-bold);
    @media screen and (min-width: 768px){
        margin-top: calc(2rem/16);
        margin-right: calc(10rem/16);
    }
}

.carnet-davantage .io-voucher-wrapper .io-voucher .form-control{
    border-radius: 0;
    padding: 6px 79px 0 28px;
    text-align: left;
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    border: 1px solid #9a9a9a;
    box-shadow: 0 5px 30px 0 rgba(0,0,0,0.1);
    /* min-width: 411px; */
}

.carnet-davantage .io-voucher-wrapper .io-voucher .form-control::placeholder {
    text-align: left;
}

.carnet-davantage .form-date-select {
    position: relative;
}

.carnet-davantage .form-date-select .vdpComponent {
    position: absolute;
    border: none;
    box-shadow: unset;
    top: 0;
    left: 0;
    width: 100%;
}

.carnet-davantage .form-date-select .vdpComponent input {
    padding-left: 0;
}

.carnet-davantage .form-date-select:has(.vdpComponent:not(.is-invalid)) span.text {
    display: none;
}

.carnet-davantage .vdpClearInput:before {
    padding-top: .2rem;
}

.carnet-davantage .btn-link {
    white-space: break-spaces;
}

.carnet-davantage .container-654 > div > .btn.btn-link {
    text-align: center;
    width: 100%;
}

.carnet-davantage .cta.px-30 .btn.btn-primary {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}

body:has(.carnet-davantage) .main-content--no-hero {
    padding-top: 1.25rem !important;
}

.carnet-davantage .io-voucher-wrapper .io-voucher .form-date-select select.form-control,
.carnet-davantage .io-voucher-wrapper .io-voucher .custom-checkbox .custom-control-label:before{
    border-radius: 0!important;
}
.carnet-davantage .io-voucher-wrapper .io-voucher .custom-checkbox .custom-control-label:before{
    width: 20px;
    height: 20px;
    left: -26px;
    border: 1px solid #9a9a9a;
}

.carnet-davantage .io-voucher-wrapper .custom-checkbox .custom-control-label:after {
    left: -25px;
    width: 19px;
    height: 19px;
}
.carnet-davantage .io-voucher-wrapper .custom-control-input:checked~.custom-control-label:before{
    border-color: var(--color-default);
}

.carnet-davantage .io-voucher-wrapper .rounded-sm{
    border-radius: 0!important;
}

.carnet-davantage .search-desktop {
    margin-bottom: -5.5rem;
}

.carnet-davantage main.pt-lg-80, .carnet-davantage main.pt-lg-160{
    @media screen and (min-width: 992px) {
        padding-top: 9rem !important;
    }
}