.badge-checkbox {
    margin-right: calc(5rem /16);
    margin-bottom: calc(5rem /16);
    background-color: #fff;
    color: var(--color-text-default);
    display: inline-flex;
    align-items: center;
    font-size: calc(10rem /16);
    text-transform: uppercase;
    line-height: 1;
    padding: calc(9rem /16) calc(12rem /16);
    cursor: pointer;
    position: relative;
}

.badge-checkbox__label {
    margin-top: calc(3rem /16);
    opacity: 0.5;
    cursor: pointer;
}

.badge-checkbox__icon{
    opacity: 0.5;
    cursor: pointer;
}

.badge-checkbox__icon:before {
    content: var(--icon-close);
    font-family: iconfont;
}

.badge-checkbox.is-checked .badge-checkbox__icon:before {
    content: var(--icon-open);
}

.badge-checkbox.is-checked .badge-checkbox__icon,
.badge-checkbox.is-checked .badge-checkbox__label {
    opacity: 1;
}

.badge-checkbox__input {
    height: 1px;
    width: 1px;
    background-color: transparent;
    color: transparent;
}
.badge-checkbox__input:after {
    content: "";
    position: absolute;
    inset: 0;
    cursor: pointer;
}