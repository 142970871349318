.telemixte-map-accordion-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    column-gap: 12rem;

    @media screen and (min-width: 768px) {
        grid-template-columns: 10fr 6fr;
        grid-template-rows: auto 1fr;
    }
}

.telemixte-map-accordion__header {
    grid-column: 1 / span 1;

    @media screen and (min-width: 768px) {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
    }
}

.telemixte-map-accordion__accordion-wrapper {
    grid-column: 1 / span 1;
    order: 3;

    @media screen and (min-width: 768px) {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
        order: unset;
    }
}

.telemixte-map-accordion__map-wrapper {
    grid-column: 1 / span 1;

    @media screen and (min-width: 768px) {
        grid-column: 2 / span 1;
        grid-row: 1 / span 2;
    }
}

.telemixte-map-accordion__title {
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    padding-bottom: calc(16rem/16);
    margin-bottom: 0;
    letter-spacing: .35rem;
    color: white;
    font-size: 2.75rem;

    @media screen and (min-width: 768px) {
        font-size: calc(80rem / 16);
    }
}

.telemixte-map-accordion__subtitle {
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    margin-bottom: 0;
    letter-spacing: .25rem;
    color: white;
    font-size: calc(20rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(32rem/16);
        padding-bottom: calc(50rem/16);
    }
}

.telemixte-map-accordion-area {
    padding-top: calc(132rem/16);
    padding-bottom: calc(132rem/16);
    background-color: #1E1E1E;
}

.telemixte-map-accordion__map {
    width: 100%;
    display: block;
    height: auto;
    margin: 2rem auto 3rem;

    @media screen and (min-width: 768px) {
        max-width: unset;
        margin-top: 0;
        margin-bottom: 3rem;
    }
}

.telemixte-container.container {
    max-width: 1630px;
}

.telemixte-map-accordion__text-content {
    padding-right: 7rem;
}

.telemixte-map-accordion-area .accordion__header-link.collapsed {
    color: white;
    background-color: transparent;
}

.telemixte-map-accordion-area .accordion .card {
    background-color: transparent;
    padding-bottom: calc(.625rem / 2);
    padding-top: calc(.625rem / 2);
    padding-top: 0;
    padding-bottom: 0;
}


.telemixte-map-accordion-area .accordion .card + .card {
    margin-top: 0;
    border-top: 2px solid color-mix(in srgb, transparent, white 20%);
}

.telemixte-map-accordion-area .accordion .icon:not(.accordion__toggle-icon){
    color: var(--color-primary);
    font-size: 2rem;
}

.telemixte-map-accordion-area .accordion__header-link {
    text-transform: unset;
    font-size: calc(14rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}

.telemixte-map-accordion-area .title-block__title,
.telemixte-map-accordion-area .wysiwyg-block__content {
    color: white;
}

.telemixte-map-accordion-area .title-block__title {
    font-size: calc(36rem/16);
    letter-spacing: calc(2.5rem/16);
}

.telemixte-map-accordion-area .wysiwyg-block__content {
    font-size: calc(16rem/16);
    letter-spacing: unset;
    line-height: 165%;
    padding-bottom: calc(40rem/16);
}

.telemixte-map-accordion-area .accordion__toggle-icon {
    transition: .35s ease-in-out transform;
    transform: rotate(45deg);
}

.telemixte-map-accordion-area .card-body {
    border: 2px solid color-mix(in srgb, transparent, white 20%);
    border-bottom: unset;
    padding-top: 3rem;
    padding-bottom: 1.875rem;
}

.telemixte-map-accordion-area .accordion .card:last-child .card-body {
    border-bottom: 2px solid color-mix(in srgb, transparent, white 20%);
}

.telemixte-map__legend {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-bottom: 2rem;
    padding-bottom: 5rem;
}

.telemixte-map__legend-icon {
    flex-shrink: 0;
}

.telemixte-map__legend-item {
    color: white;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.telemixte-map__legend-item-label {
    margin-top: .1rem;
}

.telemixte-map-accordion__affix-section {
    @media screen and (max-width: 767px) {
        position: unset!important;
    }
}