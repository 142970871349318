.datepicker-selection__day {
    color: var(--color-primary);
    font-size: calc(32rem/16);
    font-weight:bold;
    line-height:1;
    @media screen and (min-width: 768px) {
        font-size: calc(22rem/16);
    }
    @media screen and (min-width:   1699px) {
        font-size: calc(40rem/16);
    }
}
.datepicker-selection__top {
    font-size: calc(13rem/16);
    font-family: var(--font-default-bold);
    display: block;
    line-height:1;
    @media screen and (min-width:  1699px) {
        font-size: calc(16rem/16);
    }
}
.datepicker-selection__bottom {
    font-size: calc(10rem/16);
    line-height:1;
    font-family: var(--font-default-bold);
    @media screen and (min-width:  768px) {
        font-size: calc(12rem/16);
    }
}
.datepicker-selection {
    padding-top: calc(5rem/16);
    padding-bottom: calc(5rem/16);
    margin-bottom: calc(10rem/16);
    @media screen and (min-width:  768px) {
        margin-bottom: calc(30rem/16);
    }
}
.datepicker-selection__price {
    font-family: var(--font-default-bold);
    font-size: calc(40rem/16);
}