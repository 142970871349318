.facility-item__inner {
    background-color:#fff;
    padding-top: calc(15rem/16);
    padding-bottom: calc(15rem/16);
    position: relative;
    padding-left: calc(20rem/16);
    margin-top: calc(5rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
        padding: calc(5rem/16);
        margin-top:0;
    }
}
.facility-item__inner:before {
    content:'';
    position: absolute;
    left:calc(-30rem/16);
    width: calc(30rem/16);
    height:100%;
    top:0;
    background-color: #fff;
    z-index: -1;
    @media screen and (max-width: 767px) {
        left:calc(-15rem/16);
        width: calc(15rem/16);
    }
}
.facility-item__circle-icon {
    @media screen and (max-width: 767px) {
        margin-top: calc(5rem/16);
    }
}
.facility-item__title {
    font-size: calc(18rem/16);
    letter-spacing: calc(2.7rem/16);
    line-height: calc(27/18);
    margin-bottom:0;
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
        letter-spacing: calc(1rem/16);
    }
}
.facility-item__subtitle {
    @media screen and (max-width: 767px) {
        font-size: calc(10rem/16);
        letter-spacing: calc(1rem/16);
    }
}
.facility-item__status-text {
    letter-spacing: calc(2rem/16);
    font-size: calc(12rem/16);
    line-height: calc(11rem/16);
    text-transform: uppercase;
    margin-left: calc(10rem/16);
    color: var(--color-grey);
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.facility-item__more-info {
    display: block;
    font-size: calc(12rem/16);
    line-height: calc(15/12);
    letter-spacing: calc(3rem/16);
    color: var(--color-grey);
    text-transform: uppercase;
    margin-top: calc(5rem/16);
    @media screen and (max-width: 767px) {
        letter-spacing:calc(1rem/16);
        font-size: calc(10rem/16);
    }
}
.facility-item__more-info-icon {
    font-size: calc(9rem/16);
    transform: rotate(180deg);
    transition: transform 0.2s ease;
}
.facility-item__more-info.collapsed .facility-item__more-info-icon {
    transform: rotate(0deg);
}
.facility-item__more-info-content {
    padding-top: calc(20rem/16);
}
.facility-item__collapse-button {
    background: #fff;
    width: 100%;
    padding: calc(20rem/16) calc(20rem/16) calc(15rem/16);
    @media screen and (min-width: 768px) {
        padding: calc(30rem/16) calc(30rem/16) calc(25rem/16);
    }
}
.facility-item__collapse-wrapper {
    border: 2px solid #fff;
    padding: calc(20rem/16) calc(7rem/16) calc(20rem/16);
    @media screen and (min-width: 768px) {
        padding: calc(50rem/16) calc(50rem/16) calc(30rem/16);
    }
}
.facility-item__collapse-button.collapsed .facility-item__toggle-icon {
    transform: rotate(0);
    transition: transform 0.2s ease;
}
.facility-item__collapse-button .facility-item__toggle-icon {
    transform: rotate(180deg);
    transition: transform 0.2s ease;
}
.facility-item__title {
    font-size: calc(18rem / 16);
    font-family: var(--font-default-bold);
    letter-spacing: calc(2rem / 16);
    text-transform: uppercase;
    @media screen and (min-width: 768px) {
        font-size: calc(22rem / 16);
        letter-spacing: calc(3rem / 16);
    }
}