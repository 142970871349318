.pricing-slider {
    display: flex;
    margin-left: calc(-2rem/16);
    margin-right: calc(-2rem/16);
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: .25rem;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    flex-wrap: nowrap;
}
.pricing-slider-item {
    width: 40%;
    background-color:#fff;
    margin-left: calc(2rem/16);
    margin-right: calc(2rem/16);
    flex-shrink: 0;
    scroll-snap-align: start;
    flex: 0 0 auto;
    position: relative;
    padding: calc(5rem/16) calc(15rem/16);
    text-align:center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #fff;
    cursor: pointer;
    font-size: calc(12rem/16);
    height: calc(70rem/16);
    @media screen and (min-width: 768px) {
        width: 19.3%;
        padding: calc(10rem/16) calc(30rem/16);
        height: calc(90rem/16);
        font-size: calc(14rem/16);
    }
}
.pricing-slider-item:first-of-type {
    margin-left: 0.35%;
}
.pricing-slider-item--active {
    border-color: var(--color-text-default);
}
.pricing-slider-item__badge {
    background-color:#3F88C5;
    color:#fff;
    font-size: calc(8rem/16);
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(70rem/16);
    height: calc(15rem/16);
    top: 100%;
    white-space: nowrap;
    transform-origin: left top;
    transform: rotate(-90deg);
    @media screen and (min-width: 768px) {
        width: calc(90rem/16);
        height: calc(25rem/16);
        font-size: calc(10rem/16);
    }
}
.pricing-slider-item__date {
    font-family: var(--font-default-bold);
}
.pricing-slider-item__day-name {
    color: var(--color-grey);
}
.pricing-slider-item__price {
    font-size: calc(14rem/16);
    font-family: var(--font-default-bold);
    @media screen and (min-width: 768px) {
        font-size: calc(16rem/16);
    }
}
.pricing-slider-item__price-big {
    color: var(--color-primary);
    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}
.pricing-slider::-webkit-scrollbar {
    display: none;
}
.pricing-slider__button {
    background: none;
    border: none;
    box-shadow: none;
    padding: calc(10rem/16);
    display: flex;
    z-index: 9;
}
.pricing-slider-wrapper {
    position: relative;
}
.pricing-slider__button--prev {
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    left: calc(-30rem/16);
    @media screen and (min-width: 768px) {
        left:0;
    }
}
.pricing-slider__button--next {
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    right: calc(-30rem/16);
    @media screen and (min-width: 768px) {
        right:0;
    }
}
.pricing-slider__title {
    font-size: calc(14rem/16);
    letter-spacing: calc(1.5rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    @media screen and (min-width: 768px) {
        font-size: calc(16rem/16);
        letter-spacing: calc(2rem/16);
    }
}