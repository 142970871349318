.datepicker{
    position: relative;
}

.datepicker:after{
    content: var(--icon-calendar);
    font-family: iconfont;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(15rem/16);
}

.datepicker .form-control[readonly]{
    background-color: #fff;
}