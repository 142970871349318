.percentage-item {
    position: relative;
    border-radius: 50%;
    background: conic-gradient(var(--color-success) 0% 0%, var(--color-light-grey) 0% );
    height: calc(152rem / 16);
    width: calc(152rem / 16);
    margin: 0 auto;
    box-shadow: calc(2rem / 16) calc(2rem / 16) calc(15rem / 16) calc(2rem / 16) rgba(0,0,0,0.15);
    transition: background ease-in 0.5s;
}

.percentage-item.percentage-item--small {
    height: calc(100rem / 16);
    width: calc(100rem / 16);
}

.percentage-item.percentage-item--small .percentage-item__content {
    height: calc(75rem / 16);
    width: calc(75rem / 16);
}

.percentage-item.percentage-item--small .percentage-item__percentage {
    font-size: calc(22rem /16);
}

.percentage-item__content {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
    background: var(--color-white);
    height: calc(120rem / 16);
    width: calc(120rem / 16);
    box-shadow: inset calc(2rem / 16) calc(2rem / 16) calc(15rem / 16) calc(2rem / 16) rgba(0,0,0,0.15);
}

.percentage-item__percentage {
    color: var(--color-success);
    font-size: calc(32rem /16);
    line-height: 1;
    font-family: var(--font-default-bold);
}

.percentage-item__text {
    color: #8C8C8C;
    font-size: calc(9rem /16);
    letter-spacing: calc(2rem /16);
    text-transform: uppercase;
}