.dropdown--language__toggle.dropdown-toggle:after{
    font-family: iconfont-tresort;
}

.main-navbar__toggle {
    background-color:var(--color-primary);
}
.main-navbar__toggle:hover {
    background-color:var(--color-primary-dark);
}
.main-navbar__brand {
    transition:background-color .3s ease-in-out;
}
.nav-open .main-navbar__brand {
    background-color:white;
}