.order-item__header {
    background-color:#fff;
    padding: calc(20rem/16);
    @media screen and (min-width: 768px) {
        padding: calc(30rem/16);
    }
}
.order-item__body {
    border: 1px solid #fff;
    padding: calc(20rem/16);
    @media screen and (min-width: 768px) {
        padding: calc(30rem/16);
    }
}
.order-item__collapse-button.collapsed .order-item__toggle-icon {
    transform: rotate(0);
    transition: transform 0.2s ease;
}
.order-item__collapse-button .order-item__toggle-icon {
    transform: rotate(180deg);
    transition: transform 0.2s ease;
}