.sectors-map-wrapper {
    position: relative;
    max-width: 85vw;
    padding-top: calc(30rem/16);
    margin: 0 auto;
}
.sectors-map-wrapper--small {
    padding-top: 0;
    @media screen and (min-width: 1400px) {
        max-width: 65vw;
        margin: 0 auto;
    }
}
.sectors-map__svg-wrapper > svg {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 2;
}