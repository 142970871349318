.react-datepicker {
    background-color:transparent;
}
.react-datepicker-wrapper--disabled {
    opacity: 0.3;
    pointer-events: none;
}
.react-datepicker__day-name {
    font-size: calc(14rem/16);
    font-family:var(--font-default-bold);
    color: var(--color-grey);
    text-transform: uppercase;
}
.react-datepicker__current-month {
    font-size: calc(14rem/16);
    color: var(--color-secondary);
    text-align:left;
    padding-left: calc(20rem/16);
}
.react-datepicker__month-container:nth-of-type(3) {
    padding-left: calc(15rem/16);
}
.react-datepicker__month-container:nth-of-type(3) .react-datepicker__current-month {
    text-align: right;
    padding-right: calc(20rem/16);
}
.react-datepicker__navigation {
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0);
    transform: translate(0);
    border:none;
    width: calc(200rem/16);
    height: calc(20rem/16);
    left: 0;
    top: calc(5rem/16);
    font-size: calc(12rem/16);
    padding: calc(5rem/16);
}
.react-datepicker__navigation--next {
    right:0;
    left: initial;
}
.react-datepicker__navigation:before {
    content:var(--icon-arrow-dropdown);
    left: calc(-4rem/16);
    position: absolute;
    width: calc(20rem/16);
    height: calc(20rem/16);
    top:0;
    display: block;
    transform-origin: center;
    transform: rotate(90deg);
    text-indent: 0;
}
.react-datepicker__navigation--next:before {
    left: initial;
    transform: rotate(-90deg);
    right: calc(-4rem/16);
}
.react-datepicker__day-name {
    line-height: calc(20rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
        width: calc(40rem/16);
    }
}
.react-datepicker__day-names {
    margin-top: calc(20rem/16);
}
.react-datepicker__month-container {
    background-color: var(--color-light-grey);
}
.react-datepicker__day {
    background-color:#fff;
    margin: calc(2.5rem/16);
    @media screen and (max-width: 767px) {
        width: calc(40rem/16);
        line-height: calc(40rem/16);
        font-size: 1rem;
    }
}
.react-datepicker__day:hover, .react-datepicker__day:active, .react-datepicker__day:active, .react-datepicker__day--selected, .react-datepicker__day--selecting-range-start{
    background-color: var(--color-secondary);
    color:#fff;
}
.react-datepicker__day--selected {
    color: #fff;
}

.react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--range-end.react-datepicker__day--in-range, .react-datepicker__day--range-start.react-datepicker__day--in-range{
    background-color: var(--color-secondary);
    color:#fff;
}

.react-datepicker__btn-tooltip {
    text-align:center;
    width: 100%;
    line-height: calc(70rem/16);
    font-size: 1.25rem;
    letter-spacing: 0;
    @media screen and (max-width: 767px) {
        line-height: calc(40rem/16);
        font-size: 1rem;
    }
}
.react-datepicker__day--disabled {
    opacity: 0.3;
    pointer-events: none;
}
.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range{
    color:#fff;
    background-color: var(--color-grey);
}
.react-datepicker__day--selecting-range-start {
    background-color: var(--color-secondary);
    color:#fff !important;
}
.react-datepicker__status-wrapper {
    position: absolute;
    top: calc(2rem/16);
    right: calc(2rem/16);
    display: flex;
    @media screen and (min-width: 768px) {
        top: calc(5rem/16);
        right: calc(5rem/16);
    }
}
.react-datepicker__season-status  {
    margin-left: calc(3rem/16);
}
.react-datepicker__day--keyboard-selected {
    color: #000;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__day, .react-datepicker__day-name {
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        width: 5.7vw;
        line-height: 5.7vw;
        font-size: clamp(1rem, 0.7vw, 1.3rem);
    }
}
.react-datepicker__btn-tooltip {
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        line-height: 5.7vw;
        font-size: clamp(1rem, 0.7vw, 1.3rem);
    }
}
.react-datepicker__day-name {
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        width: 5.7vw;
    }
}
.react-datepicker--small .react-datepicker__day--keyboard-selected, .react-datepicker--small .react-datepicker__day, .react-datepicker--small .react-datepicker__day-name {
    @media screen and (max-width: 1699px) and (min-width: 768px) {
        width: 3.8vw;
        line-height: 3.8vw;
        font-size: clamp(1rem, 0.7vw, 1.3rem);
    }
}
.react-datepicker--small .react-datepicker__btn-tooltip {
    @media screen and (max-width: 1699px) and (min-width: 768px) {
        line-height: 3.8vw;
        font-size: clamp(1rem, 0.7vw, 1.3rem);
    }
}
.react-datepicker--small .react-datepicker__day-name {
    @media screen and (max-width: 1699px) and (min-width: 768px) {
        width: 3.8vw;
    }
}
.react-datepicker__custom-header-arrow {
    font-size: calc(12rem/16);
    padding: calc(10rem/16);
}
.react-datepicker__custom-header-arrow:disabled {
    color: var(--color-text-muted);
    cursor: initial;
}