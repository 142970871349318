.quote__body {
    background-color:var(--color-secondary);
}
.quote__body:before {
    @media screen and (min-width: 768px){
        background-color:var(--color-secondary);
    }
}
.quote__text{
    font-family:var(--font-bold);
}