.img-text-teaser{
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: all 0.2s ease;
}
.img-text-teaser__img-wrapper{
    position: relative;
}

.img-text-teaser__highlight-badge{
    position: absolute;
    z-index: 1;
    top: calc(-17rem/16);
    right: calc(-15rem/16);
    
    @media screen and (max-width: 767px){
       width: calc(50rem/16);
    }
    
    @media screen and (min-width: 768px){
        top: calc(-24rem/16);
        right: calc(-23rem/16);

    }
}

.img-text-teaser__new-badge{
    position: absolute;
    z-index: 1;
    top: calc(-17rem/16);
    right: calc(-15rem/16);

    @media screen and (max-width: 767px){
        width: calc(50rem/16);
    }

    @media screen and (min-width: 768px){
        top: calc(-24rem/16);
        right: calc(-23rem/16);

    }
}

.img-text-teaser__body{
    padding: calc(15rem/16);
    background-color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1200px){
        padding: calc(25rem/16) calc(30rem/16);
    }

    @media screen and (min-width: 768px){
        padding: calc(20rem/16);

    }
}

.img-text-teaser__title{
    font-size: calc(15rem/16);
    line-height: calc(24/20);
    letter-spacing: calc(1rem/16);
    text-transform: none;
    hyphens: auto;
    /* break words only if the word has at least 20 characters */
    -webkit-hyphenate-limit-before: 10;
    -webkit-hyphenate-limit-after: 10;
    -moz-hyphenate-limit-chars: 20 3 2;
    -webkit-hyphenate-limit-chars: 20 3 2;
    -ms-hyphenate-limit-chars: 20 3 2;
    hyphenate-limit-chars: 20 3 2;

    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
        letter-spacing: calc(2rem/16);
    }
}

.is-team .img-text-teaser__title{
    letter-spacing: calc(2.7rem/16);
}

.img-text-teaser__toptitle{
    text-transform: uppercase;
    font-size: calc(10rem/16);
    line-height: calc(15/12);
    letter-spacing: calc(3rem/16);
    margin-bottom: calc(5rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);
    }
}

@media screen and (min-width: 768px) {
    .img-text-teaser:not(.is-team):before {
        content: '';
        box-shadow: 0 calc(42rem / 16) calc(40rem / 16) 0 rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        opacity: 0;
        transition: opacity 0.3s ease;
        will-change:opacity;
    }
    .img-text-teaser:not(.is-team):hover:before {
        opacity:1;
    }
    .img-text-teaser:not(.is-team) .img-text-teaser__img {
        transform-origin: center;
        transition: transform 0.3s ease;
        will-change:transform;
    }
    .img-text-teaser:not(.is-team):hover .img-text-teaser__img {
        transform: scale(1.03);
    }
}

.img-text-teaser__contact {
    font-family: var(--font-default-bold);
    font-size: calc(14rem/16);
    line-height: calc(20/14);
}

.img-text-teaser__contact-icon {
    width: calc(30rem/16);
}

.img-text-teaser__contact-link {
    display: flex;
    align-content: center;
}

.img-text-teaser__contact-link:hover {
    color: var(--color-primary);
}

.img-text-teaser .circle-icon--medium .circle-icon__icon {
    font-size: calc(6rem/16);
    position: relative;
    left: -0.5px;
}

/* Status variante */

.img-text-teaser__status-info{
    line-height: 1;
    font-size: calc(10rem/16);
    letter-spacing: calc(1rem/16);
    text-transform: uppercase;
    margin-top: calc(10rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(15rem/16);
        letter-spacing: calc(1rem/16);
    }

    @media screen and (min-width: 1200px){
        letter-spacing: calc(2.22rem/16);
    }
}

.img-text-teaser__status-info .circle-icon{
    margin-right: calc(5rem/16);
    letter-spacing: calc(-1rem/16);

    @media screen and (min-width: 768px){
        margin-right: calc(10rem/16);
    }
}

.img-text-teaser__status-text{
    color: var(--color-grey);
}
.img-text-teaser__dropdown-menu {
    width: 100%;
    box-shadow: rgba(0,0,0,0.2) 0 calc(15rem/16) calc(35rem/16) 0;
}
.img-text-teaser__dropdown-item {
    display: grid;
    grid-template-columns: 2fr 1fr;
    white-space: initial;
}

/* icon text variante (event) */

.img-text-teaser__status-info {
    margin-top: calc(10rem / 16);
}

.img-text-teaser__status-info-text{
    line-height: 1;
}

.img-text-teaser__status-info-icon{
    font-size: calc(20rem/16);
    margin-right: calc(9rem/16);
}

.img-text-teaser__booking-btn-wrapper {
    position: absolute;
    left:0;
    bottom:0;
    right:0;
    margin-left: calc(20rem/16);
    margin-right: calc(20rem/16);
    @media screen and (min-width: 768px){
        margin-left: calc(30rem/16);
        margin-right: calc(30rem/16);
    }
}

.img-text-teaser__booking-btn{
    margin-top: calc(22rem/16);
    @media screen and (min-width: 768px){
        padding: calc(19rem/16) calc(20rem/16) calc(17rem/16);
        margin-top: calc(30rem/16);
    }
}

.img-text-teaser--has-booking-btn .img-text-teaser__body {
    padding: calc(15rem/16) calc(15rem/16) calc(40rem/16);
    margin-bottom: calc(25rem/16);

    @media screen and (min-width: 1200px){
        padding: calc(25rem/16) calc(30rem/16) calc(50rem/16);
    }
    @media screen and (min-width: 768px){
        padding: calc(20rem/16) calc(20rem/16) calc(40rem/16);
    }
}
@media screen and (min-width: 768px) {
    .img-text-teaser--has-booking-btn.img-text-teaser:before {
        bottom: calc(25rem/16);
        height: auto;
        top:0;
        box-shadow: 0 calc(20rem / 16) calc(20rem/16) 0 rgba(0, 0, 0, 0.2);
    }
}

.aside-teaser-row-area .scrolling-area--teaser{
    @media screen and (min-width: 768px){
        padding-bottom: calc(30rem/16);
        padding-left: calc(15rem/16);
        padding-right: calc(15rem/16);
        overflow: hidden;
    }
}
.aside-teaser-row-area .scrolling-area--teaser .scrolling-area__arrows{
    @media screen and (min-width: 768px){
        transform: none;
    }

    @media screen and (min-width: 1200px){
        bottom: 0;
    }
}
.aside-teaser-row-area .scrolling-area__item{
    @media screen and (min-width: 768px){
        padding-bottom: calc(80rem/16);
        padding-top: calc(10rem/16);
        width: calc(230rem/16);
    }

    @media screen and (min-width: 1200px){
        width: calc(310rem/16);

    }
}

.aside-teaser-row-area .img-text-teaser:not(.is-team):before{
    box-shadow: 0 5px 16px 0 rgb(0 0 0 / 18%);
}