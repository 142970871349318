.weather-forecast__canvas-wrapper {
    background-color: #fff;
    background: linear-gradient(0deg, #FFFFFF 0%, #F0F0F0 100%);
}
.weather-forecast__canvas {
    max-width: 70%;
    margin: 0 auto;
    height: calc(300rem/16);
    padding-top: calc(30rem/16);
    padding-bottom: calc(20rem/16);
    @media screen and (max-width: 767px) {
        height: calc(150rem/16);
        max-width: 78%;
        padding-top: calc(10rem/16);
        padding-bottom: calc(10rem/16);
    }
}
.weather-forecast__legend {
    font-size: calc(12rem/16);
    padding: calc(15rem/16);
}