.wysiwyg-block__btn-list{
    margin-top: calc(15rem/16);
    
    @media screen and (min-width: 768px){
        margin-top: calc(30rem/16);
    }
    @media screen and (max-width: 767px){
       margin-bottom: calc(30rem/16);
    }
}

.wysiwyg-block__btn-list>li{
    margin-bottom: calc(15rem/16);
}

.wysiwyg-block__btn-list .btn{
    @media screen and (min-width: 1200px){
        padding-left: calc(40rem/16);
        padding-right: calc(40rem/16);
    }
}

@media screen and (min-width: 768px) {
    .wysiwyg-block--has-indent .wysiwyg-block__content {
        padding-left: calc(110rem/16);
    }

    .wysiwyg-block--has-indent .title-block__title--red-line:after {
        left: calc(110rem/16);
    }
}
