.footer-main{
    background-color: var(--color-dark);
    color: #fff;
    padding: calc(50rem/16) calc(50rem/16) calc(30rem/16);
    
    @media screen and (min-width: 768px){
        padding: calc(50rem/16) 0 calc(30rem/16);
    }
}

.footer-main__logo-top{
    margin-bottom: calc(40rem/16);
    display: inline-block;
}

.footer-main__logo-bottom{
    margin-top: calc(40rem/16);
    display: inline-block;
}

.footer-main__btn-list{
    margin-bottom: calc(30rem/16);
}

.footer-main__btn-list .list-inline-item:not(:last-child){
    @media screen and (max-width: 767px){
       margin-bottom: calc(15rem/16);
    }
}

.footer-main__link-list{
    text-transform: uppercase;
    font-family: var(--font-default-bold);
}

.footer-main__link-list .list-inline-item:not(:last-child){
    @media screen and (max-width: 767px){
       margin-bottom: calc(10rem/16);
    }
}


.footer-social{
    background-color: var(--color-dark-grey);
    color: #fff;
    padding: calc(21rem/16) 0;
    text-align: center;
}

.footer-social__list .icon{
    font-size: calc(22rem/16);
}

.footer-social__list .list-inline-item:not(:last-child){
    margin-right: calc(18rem/16);
}

.footer-bottom{
    color: var(--color-text-muted);
    padding: calc(16rem/16) 0;
    
    @media screen and (max-width: 767px){
       text-align: center;
    }
}
.footer-bottom__logo{
    height: calc(14rem/16);
    width: auto;
}

.footer a:not(.btn){
    transition: all 150ms ease;
}
.footer a:not(.btn):hover{
    color: var(--color-grey);
}
.footer--btob {
    background-color: white;
}