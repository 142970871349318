.icon-img-svg{
    width: calc(50rem/16);
    height: calc(50rem/16);
    
    @media screen and (min-width: 768px){
        width: calc(80rem/16);
        height: calc(80rem/16);
    }
}


/*
.icon-img {
    width: calc(60rem/16);
    height: calc(60rem/16);
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url(/static/img/environment/sprite/environment-sprite.svg);
    background-size: cover;

    @media screen and (min-width: 768px){
        width: calc(80rem/16);
        height: calc(80rem/16);
    }
}
.icon-img-restaurant {
    background-position: 0 0;
}


.icon-img-gear {
    background-position: -88px 0;

    @media screen and (min-width: 768px){
        background-position: -117px 0;
    }
}
.icon-img-house {
    background-position: -176px 0;

    @media screen and (min-width: 768px){
        background-position: -235px 0;
    }
}
.icon-img-renewable-energy {
    background-position: -264px 0;
    @media screen and (min-width: 768px){
        background-position: -353px 0;
    }
}
.icon-img-snowflake {
    background-position: -353px 0;

    @media screen and (min-width: 768px){
        background-position: -469px 0;

    }
}
.icon-img-snow-groomer {
    background-position: -441px 0;

    @media screen and (min-width: 768px){
        background-position: -588px 0;
    }
}

.icon-img-train {
    background-position: -529px 0;

    @media screen and (min-width: 768px){
        background-position: -706px 0;
    }
}
*/
