.checkbox-multiple-select{
    position: relative;
    width: 100%;
}

.checkbox-multiple-select label{
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

.checkbox-multiple-select:before,
.checkbox-multiple-select:after {
    content: " ";
    display: table;
}

.checkbox-multiple-select:after {
    clear: both;
}

.checkbox-multiple-select__label{
    padding: calc(10rem/16) calc(20rem/16);
}

.checkbox-multiple-select__label:hover{
    background-color: var(--color-light-grey);
}

.checkbox-multiple-select__input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.checkbox-multiple-select__text {
    display: block;
    overflow: hidden;
    word-break: break-all;
    font-size: calc(14rem/16);
    margin-right: calc(15rem/16);
}

.checkbox-multiple-select__icon {
    visibility: hidden;
    color: var(--color-default);
    font-size: calc(9rem/16);

}
.checkbox-multiple-select__input:checked ~ .checkbox-multiple-select__icon {
    visibility: visible;
}

.checkbox-multiple-select__input:checked ~ .checkbox-multiple-select__icon ~ .checkbox-multiple-select__text{
    font-family: var(--font-default-bold);
}

.checkbox-multiple-select__count{
    color: var(--color-grey);
    font-size: calc(10rem/16);
    letter-spacing: calc(0.56rem/16);
    margin-left: calc(3rem/16);
}

.checkbox-multiple-select__label:hover .checkbox-multiple-select__icon{
    visibility: visible;
    opacity: 0.3;
}
