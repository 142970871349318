.payment-types-overview__item {
    padding-top: calc(20rem/16);
    padding-bottom: calc(20rem/16);
    border-top: calc(1rem/16) solid #fff;
}

.payment-types-overview__item--payment-overview{
    border-top: none;
    text-align: center;
    background-color: #fff;
}

@media screen and (min-width: 768px){
    .payment-types-overview__item--payment-overview{
    }
}
.payment-types-overview__payment{
    margin-right: calc(25rem/16);
    width: calc(62rem/16);
    @media screen and (max-width: 767px) {
        margin-right: 1rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: calc(50rem/16);
        margin-right: calc(20rem/16);
    }
}