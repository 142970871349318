.overlay-teaser {
    position: relative;
}

.overlay-teaser__content {
    color: #fff;
    position: absolute;
    inset: 0;
    background-color: rgba(39, 199, 233, 0.9);
    transition: background-color 0.25s ease-in-out;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.overlay-teaser__content:hover {
    background-color: rgba(39, 199, 233, 0.7);
}

.overlay-teaser__icon {
    font-size: calc(40rem / 16);
    margin-bottom: calc(20rem / 16);
    @media screen and (min-width: 768px) {
        margin-bottom: calc(30rem / 16);
    }
}


.overlay-teaser__title {
    font-size: calc(22rem / 16);
    margin-bottom: calc(10rem / 16);
    text-align: center;
    padding: 0 1.5rem;
}

.overlay-teaser__subtitle {
    font-size: calc(14rem / 16);
    text-align: center;
    padding: 0 1.5rem;
    text-transform: uppercase;
    letter-spacing: calc(3rem /16);
    margin-bottom: calc(30rem / 16);
    @media screen and (min-width: 768px) {
        margin-bottom: calc(46rem / 16);
    }
}