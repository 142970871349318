.switch {
    display: inline-flex;
    align-items: center;
    background-color:#fff;
    padding: calc(12rem/16) calc(25rem/16);
}
.switch__input{
    height: 0;
    width: 0;
    visibility: hidden;
}
.switch__switch-box {
    cursor: pointer;
    width: calc(50rem/16);
    height: calc(25rem/16);
    background: var(--color-grey);
    display: inline-block;
    border-radius: 100px;
    position: relative;
    margin-right: calc(15rem/16);
}
.switch__switch-box:after {
    content: '';
    position: absolute;
    top: calc(2rem/16);
    left: calc(2rem/16);
    width: calc(21rem/16);
    height: calc(21rem/16);
    background: #fff;
    border-radius: 90px;
    transition: 0.2s;
}
.switch__input:checked + .switch__switch-box {
    background: var(--color-primary);
}
.switch__input:checked + .switch__switch-box:after {
    left: calc(100% - 2rem/16);
    transform: translateX(-100%);
}
.switch__label {
    display: flex;
}
.switch__label-title {
    font-family: var(--font-default-bold);
    font-size: calc(18rem/16);
    text-transform: uppercase;
    line-height:1;
    margin-top: calc(4rem/16);
}