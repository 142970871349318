.audio{
    padding: calc(15rem/16);
    background-color: #fff;

    @media screen and (min-width: 768px){
        padding: calc(8rem/16) calc(30rem/16);
    }
}

.audio__title{
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    letter-spacing: calc(2.5rem/16);
    font-size: calc(15rem/16);
    line-height: calc(20/15);
    
    @media screen and (max-width: 767px){
       margin-bottom: calc(15rem/16);
    }
}