.blog-teaser{
    background-color: #fff;
    height: 100%;

}

@media screen and (min-width: 768px) {
    .blog-teaser:before {
        content: '';
        box-shadow: 0 calc(42rem / 16) calc(60rem / 16) 0 rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        opacity: 0;
        transition: opacity 0.3s ease;
        will-change:opacity;
    }
    .blog-teaser:hover:before {
        opacity:1;
    }
    .blog-teaser__img {
        transform-origin: center;
        transition: transform 0.3s ease;
        will-change:transform;
    }
    .blog-teaser:hover .blog-teaser__img {
        transform: scale(1.03);
    }
}

.blog-teaser__title{
    font-size: calc(18rem/16);
    line-height: calc(28/22);
    text-transform: none;
    letter-spacing: normal;
    margin-bottom: 0;

    @media screen and (min-width: 768px){
        font-size: calc(22rem/16);
    }
}

.blog-teaser__body{
    padding: calc(10rem/16) calc(30rem/16) calc(25rem/16);
}

.blog-teaser .author-item__img{
    margin-top: calc(-40rem/16);
    position: relative;
    z-index: 3;
}

.blog-teaser .author-item{
    margin-bottom: calc(15rem/16);
}

/* Scrolling area */

.scrolling-area--teaser .blog-teaser__title{
    @media screen and (min-width: 768px) and (max-width: 1200px){
        font-size: calc(19rem/16);
    }
}

.scrolling-area--teaser .blog-teaser__body{
    @media screen and (max-width: 1200px){
       padding: calc(10rem/16) calc(20rem/16) calc(20rem/16);
    }
}

.scrolling-area--teaser .blog-teaser .author-item__img{
    @media screen and (max-width: 767px){
       width: calc(50rem/16);
        height: auto;
        margin-right: calc(7rem/16);
    }

    @media screen and (min-width: 768px) and (max-width: 1200px){
        width: calc(60rem/16);
        height: auto;
        margin-right: calc(7rem/16);
    }
}

.scrolling-area--teaser .blog-teaser .author-item__title{

    @media screen and (max-width: 767px){
       font-size: calc(11rem/16);
        letter-spacing: calc(2rem/16);
    }
}