.cart-overlay__body {
    text-align:center;
}
.cart-overlay__icon {
    font-size: calc(90rem/16);
    margin-bottom: calc(30rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(120rem/16);
    }
}
.cart-overlay__title {
    font-size: calc(16rem/16);
    text-transform: uppercase;
    letter-spacing: calc(4rem/16);
    font-family: var(--font-default-bold);
    margin-bottom: calc(20rem/16);
    @media screen and (min-width: 768px) {
        margin-bottom: calc(30rem/16);
    }
}