.multiple-selects-dropdown__button{
    text-align: left;
    font-family: var(--font-default);
    padding: 1.25rem 1.25rem .5rem;
}

.multiple-selects-dropdown__label{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(15rem/16);

    @media screen and (max-width: 767px){
       font-size: calc(12rem/16);
        padding-right: calc(28rem/16);
        line-height: 1.2;
    }
    
    @media screen and (min-width: 768px){
        padding-right: calc(25rem/16);
        line-height: 1;
    }
}

.has-value .multiple-selects-dropdown__label{
    transform: translateY(calc(-17rem / 16)) scale(.65);
    text-transform: uppercase;
    letter-spacing: calc(2rem/16);
    color: var(--color-grey);
    left: calc(5rem/16);
    padding-right: 0;

    @media screen and (min-width: 768px){
        transform: translateY(calc(-21rem / 16)) scale(.65);
    }
}

.multiple-selects-dropdown__values{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 170px;
    display: block;
}

.multiple-selects-dropdown__icon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(15rem/16);
    font-size: calc(9rem/16);
}


.multiple-selects-dropdown__menu{
    width: 100%;
    padding: 0;
}


.multiple-selects-dropdown__list{
    max-height: calc(215rem/16);
    overflow-y: auto;
}


.multiple-selects-dropdown__submit-btn{
    height: calc(50rem/16);
    
    @media screen and (max-width: 767px){
       font-size: calc(10rem/16);
        letter-spacing: calc(2.5rem/16);
        line-height: 1.2;
        height: calc(40rem/16);
    }
}