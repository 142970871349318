.profile-overlay {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    gap: 0 0;
    grid-template-areas:
    "profile-overlay__nav"
    "profile-overlay__news";
    overflow: hidden;
    @media screen and (min-width: 768px) {
        grid-template-columns: 5fr 2fr;
        grid-template-rows: 1fr;
        gap: 0 0;
        grid-template-areas:
    "profile-overlay__news profile-overlay__nav";
        height: calc(340rem / 16);
    }
}

.profile-overlay__nav {
    grid-area: profile-overlay__nav;
    height: 100%;
    border-left: calc(1rem / 16) solid rgba(0, 0, 0, .15);
    padding-top: calc(15rem / 16);
}

.profile-overlay__nav-item {
    padding: calc(16rem / 16) calc(30rem / 16) calc(14rem / 16);
    font-size: calc(12rem / 16);
    line-height: calc(15 / 12);
    letter-spacing: calc(3rem / 16);
    text-transform: uppercase;
}

.profile-overlay__nav-link {
    transition: opacity 0.125s ease-in-out;
}

.profile-overlay__nav-link:hover {
    opacity: 0.5;
}

.profile-overlay__nav-item--light {
    opacity: 0.5;
}

.profile-overlay__news {
    grid-area: profile-overlay__news;
    background-color: #F0F0F0;
    @media screen and (min-width: 768px) {
        overflow-y: scroll;
        overflow-x: hidden;
        padding-top: calc(20rem / 16);
    }
}

.profile-overlay__item {
    padding: calc(5rem / 16) calc(30rem / 16);
}

.profile-overlay__title {
    text-transform: uppercase;
    display: inline-block;
    font-size: calc(12rem / 16);
    font-family: var(--font-default-bold);
    letter-spacing: calc(3rem / 16);
    position: relative;
    margin-bottom: calc(8rem / 16);
    transition: color .2s ease-in;
}

.profile-overlay__title:after {
    content: "";
    position: absolute;
    width: 100%;
    height: calc(2rem / 16);
    background: var(--color-default);
    bottom: 0;
    left: 0;
    right: 0;
    transition: background-color .2s ease-in;
}

.profile-overlay__title:hover {
    color: var(--color-primary);
}

.profile-overlay__title:hover:after {
    background-color: var(--color-primary);
}

.profile-overlay__progress {
    border-top: calc(1rem / 16) solid rgba(0, 0, 0, .15);
    padding: calc(15rem / 16) 0;
    margin-top: calc(10rem / 16);
}

.profile-overlay__collapse-button {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: calc(3rem /16);
    padding: calc(20rem /16) calc(30rem /16) calc(4rem /16);
    font-family: var(--font-default-bold);
    font-size: calc(16rem /16);
}

.profile-overlay__collapse-icon {
    margin-left: calc(8rem /16);
    margin-bottom: calc(3rem /16);
    transition: transform 250ms ease-in-out;
    transform: rotate(180deg);
    font-size: calc(12rem /16);
}
.profile-overlay__collapse-button.collapsed .profile-overlay__collapse-icon {
    transform: rotate(0deg);
}
