.cart-summary {
    width: 100%;
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}
.cart-summary__title {
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    margin-bottom: calc(20rem/16);
    @media screen and (min-width: 768px) {
        letter-spacing: calc(2rem/16);
        font-size: calc(16rem/16);
    }
}
.cart-summary__total--big {
    font-size: calc(32rem/16);
    margin-bottom: calc(10rem/16);
    font-family: var(--font-default-bold);
    @media screen and (min-width: 768px) {
        font-size: calc(40rem/16);
        margin-bottom: calc(20rem/16);
    }
}
.cart-summary__total-currency {
    font-family: var(--font-default-bold);
}
.cart-summary__item {
    padding-top: calc(20rem/16);
    padding-bottom: calc(20rem/16);
    border-top: calc(1rem/16) solid #fff;
}

.cart-summary__item.cart-summary__item--payment {
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 768px){
    .cart-summary__item.cart-summary__item--payment {
        justify-content: center;
    }
}


.cart-summary__item:last-of-type {
    padding-bottom: 0;
}
.cart-summary__btn--is-fixed-mobile {
    @media screen and (max-width: 767px) {
        position: fixed;
        bottom:0;
        width: 100%;
        left:0;
        height: calc(60rem/16);
        z-index: 9;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.cart-summary--cart-overlay .cart-summary__item:last-of-type {
    border-bottom: none;
}
.cart-summary--cart-overlay .cart-summary__item:first-of-type {
    border-top: calc(1rem/16) solid #fff;
}
.cart-summary__btob-container {
    @media screen and (min-width: 768px) {
        max-height: 60vh;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: calc(10rem/16);
    }
}


.body--cart-summary-bar {
@media screen and (min-width: 768px) {
    margin-bottom: calc(150rem/16);
}
}
.cart-summary-bar {
    position: fixed;
    bottom:0;
    width: 100%;
    left:0;
    right:0;
    z-index: 100;
    max-height: 100%;
    overflow-y: auto;
}
.cart-summary-bar__top {
    padding-top: calc(10rem/16);
    padding-bottm: calc(10rem/16);
    background-color:#fff;
    font-size: calc(12rem/16);
    height: calc(60rem/16);
@media screen and (min-width: 768px) {
    height: calc(150rem/16);
    padding-top: calc(20rem/16);
    padding-bottom: calc(20rem/16);
}
@media screen and (min-width: 1200px) {
    font-size: calc(14rem/16);
    padding-top: calc(30rem/16);
    padding-bottom: calc(30rem/16);
}
}

.checkout-summary-bar__title  {
    font-family: var(--font-default-bold);
    text-transform:uppercase;
    letter-spacing: calc(1rem/16);
@media screen and (min-width: 1200px) {
    font-size: calc(16rem/16);
    letter-spacing: calc(2rem/16);
}
}

.checkout-summary-bar__price{
    font-family: var(--font-default-bold);
}

.cart-summary__payment{
    margin-right: calc(25rem/16);
    width: calc(42rem/16);
}