.author-item__img{
    border-radius: 50%;
    border: 3px solid #fff;
    margin-right: calc(15rem/16);
    flex: 0 0 auto;
    width: calc(80rem/16);
    height: calc(80rem/16);
}

.author-item__img img{
    border-radius: 50%;

}

.author-item__title{
    text-transform: uppercase;
    font-size: calc(12rem/16);
    letter-spacing: calc(3rem/16);
}

.author-item__date{
    font-size: calc(12rem/16);
    color: var(--color-grey);
    letter-spacing: calc(1rem/16);
}