.cta-slide {
    position: relative;
    padding-bottom: calc(48rem/16);
    @media screen and (max-width: 767px) {
        padding-bottom: 0;
    }
    
    @media screen and (min-width: 768px){
        height: calc(450rem/16);
    }
}
.cta-slide__img {
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    width: 100%;
    height: calc(450rem/16);
    z-index: -1;
    @media screen and (max-width: 767px) {
        position: relative;
        margin-top: -10vh;
        height: auto;
    }
}
.cta-slide__box {
    background-color: #fff;
    padding: calc(30rem/16) calc(50rem/16);
}
.cta-slide__logo {
    margin-bottom: calc(20rem/16);
}
.cta-slide__title {
    margin-bottom: calc(13rem/16);
    text-transform: none;
    font-size: calc(32rem/16);
    letter-spacing:0;
    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
    }
}
.cta-slide__wysiwyg {
    margin-bottom: calc(20rem/16);
}