.headline-with-lines {
    text-align:center;
    display: grid;
    grid-template-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
    grid-template-rows: calc(20rem/16) 0;
    grid-gap: calc(20rem/16);
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}
.headline-with-lines:before, .headline-with-lines:after {
    content: " ";
    display: block;
    border-bottom: 1px solid var(--color-grey);
}