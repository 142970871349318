.accordion .card{
    border: 0;
}
.accordion .card+.card{
    margin-top: calc(10rem/16);
}


.accordion .card-header{
    padding: 0;
    border: 0;
}

.accordion .card-body{
    padding: calc(20rem/16) calc(30rem/16);
}

.accordion__header-link{
    font-size: calc(13rem/16);
    letter-spacing: calc(1rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(20/15);
    text-transform: uppercase;
    display: block;
    padding: calc(12rem/16) calc(15rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(15rem/16);
        letter-spacing: calc(2rem/16);
        padding: calc(20rem/16) calc(30rem/16);
    }

    width: 100%;
    text-align: left;
    border: none;
    background-color: var(--color-dark-grey);
    color: #fff;
    transition: background-color 250ms ease, color 250ms ease;
}

.accordion__header-link.collapsed{
    background-color: #fff;
    color: var(--color-dark-grey);
}

.accordion__header-link.collapsed:hover{
    background-color: var(--color-dark-grey);
    color: #fff;
}

.accordion__toggle-icon{
    font-size: calc(12rem/16);
}

.accordion__header-link .accordion__toggle-icon{
    transform: rotate(180deg);
}

.accordion__header-link.collapsed .accordion__toggle-icon{
    transform: rotate(0deg);
}