.lightbox-item{
    display: block;
    position: relative;
}


.lightbox-item:after{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    opacity: 0;
    z-index: 1;
    transition: opacity 250ms ease;
}

.lightbox-item:hover:after{
    opacity: 0.3;
}
.lightbox-item__btn{
    position: absolute;
    right: calc(15rem/16);
    top: calc(15rem/16);
    z-index: 2;
    transform: translate(15px, -15px) scale(0);
    transition: transform 250ms ease;
    color: #fff;
}
.lightbox-item:hover .lightbox-item__btn{
    transform: translate(0,0) scale(1);
}

.lg-outer .lg-img-wrap{
    padding-bottom: calc(60rem/16);
}

.lg-sub-html{
    background: linear-gradient(180deg, rgba(44,43,46,0) 0%, rgba(44,43,46,0.7) 100%);
    padding-top: 30px;
}

.lg-toolbar .lg-close:after{
    content: var(--icon-close);
    font-family: iconfont;
    font-size: calc(16rem/16);
}

.lg-actions .lg-prev:after {
    content: var(--icon-arrow-long);
    font-family: iconfont;
    font-size: calc(14rem/16);
}

.lg-actions .lg-next:before {
    content: var(--icon-arrow-long);
    font-family: iconfont;
    transform: rotate(180deg);
    display: inline-block;
    font-size: calc(14rem/16);

}

.lg-toolbar .lg-download:after{
    content: var(--icon-download);
    font-family: iconfont;
    font-size: calc(18rem/16);
}