.account__image {
    border-radius: 50%;
    box-shadow: 0 calc(20rem / 16) calc(40rem / 16) 0 rgba(0, 0, 0, 0.2);
}

.account__image-wrapper {
    width: calc(160rem / 16);
    @media screen and (min-width: 768px) {
        width: calc(180rem / 16);
    }
    @media screen and (max-width: 767px) {
        margin: 0 auto;
    }
}

.account__pretitle {
    font-size: calc(20rem / 16);
    letter-spacing: calc(3rem / 16);
    @media screen and (min-width: 768px) {
        font-size: calc(32rem / 16);
        letter-spacing: calc(10rem / 16);
    }
}

.account__title {
    font-size: calc(32rem / 16);
    letter-spacing: calc(2rem / 16);
    @media screen and (min-width: 768px) {
        font-size: calc(70rem / 16);
        letter-spacing: calc(10rem / 16);
    }
}

.account-info__icon {
    font-size: calc(18rem /16);
}