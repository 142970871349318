.account-icon-teaser {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    padding: calc(40rem / 16) calc(30rem /16);
    @media screen and (min-width:768px){
        padding: calc(42rem / 16) calc(22rem /16) calc(38rem / 16);
    }
}
.account-icon-teaser__icon {
    font-size: calc(30rem /16);
    margin-bottom: calc(20rem / 16);
    @media screen and (min-width:768px){
        margin-bottom: calc(28rem / 16);
    }
}

.account-icon-teaser__subtitle {
    text-transform: uppercase;
    margin-bottom: calc(30rem / 16);
    letter-spacing: calc(3rem /16);
    @media screen and (min-width:768px){
        margin-bottom: calc(36rem / 16);
    }
}
.account-icon-teaser__btn {
    position: static;
}
.account-icon-teaser__btn:hover:before {
    opacity: 0!important;
}