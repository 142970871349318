.hero-cutout {
    position:relative;
    @media screen and (min-width: 768px) {
        min-height: calc(650rem/16);
    }
}
.hero-cutout:after{
    content:'';
    position: absolute;
    width: 100%;
    height: calc(220rem/16);
    left:0;
    bottom:calc(-1rem/16);
    z-index: 1;
    background: linear-gradient(180deg, rgba(240,240,240,0) 0.04%, #F0F0F0 100%);
    @media screen and (max-width: 767px) {
        height: calc(70rem/16);
    }
}
.hero-cutout__image {
    @media screen and (min-width: 768px) {
        min-height: calc(500rem/16);
    }
}
.hero-cutout__sub-title, .hero-cutout__title, .hero-cutout__quickfinder {
    width: 48%;
    @media screen and (max-width: 1199px) {
        width: 65%;
    }
}
.hero-cutout__overlay {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: calc(228rem/16);
    will-change: background-position-y;
    background-image: var(--image-src-xs) !important;
    @media screen and (min-width: 768px) {
        background-image: var(--image-src) !important;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
    @media screen and (min-width: 1330px) {
        width: calc(100% - calc((100vw - 1310px)/2));
    }
    @media screen and (min-width: 1921px) {
        background-size: cover;
    }
}
.hero-cutout__overlay:before {
    content: "";
    position: absolute;
    width: 38%;
    opacity: .15;
    left: 0;
    top: 0;
    background: linear-gradient(to right, rgb(0 0 0) 0%,rgb(0 0 0) 92%,rgb(0 0 0 / 0%) 100%);
    z-index: 0;
    height: 100%;
}

.hero-cutout__video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;

}

.hero-cutout__grid {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr;
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: 1fr 1fr 1fr;
    grid-template-rows: auto 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
        "subtitle"
        "title"
        "quickfinder";
    @media screen and (min-width: 1330px) {
        left: calc((100vw - 1310px)/2);
    }
    @media screen and (max-width: 767px) {
        -ms-grid-rows: 40% 60%;
        grid-template-rows: 30% 70%;
        /*padding-top: calc(60rem/16);*/
        grid-template-areas:
        "subtitle"
        "title";
    }
}
.hero-cutout__sub-title {
    grid-area: subtitle;
    background: linear-gradient(to right, rgba(240,240,240,1) 0%,rgba(240,240,240,0.8) 72%,rgba(240,240,240,0) 100%);
    margin-bottom:0;
    font-size: calc(14rem/16);
    line-height: 1.5;
    letter-spacing: calc(5rem/16);
    font-family: var(--font-default);
    display: flex;
    align-items: flex-end;
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    z-index: 2;
    padding-top: calc(160rem/16);
    @media screen and (max-width: 1399px) and (min-width: 768px) {
        padding-left: calc(15rem/16);
    }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        padding-top: calc(140rem/16);
    }
    @media screen and (max-width: 767px) {
        padding-left: calc(15rem/16);
        font-size: calc(11rem/16);
        letter-spacing: calc(2.5rem/16);
        padding-top: calc(0rem/16);
        z-index: 3;
        background: linear-gradient(to right, rgba(240,240,240,1) 0%,rgba(240,240,240,1) 80%,rgba(240,240,240,0) 100%);
    }
}
.hero-cutout__title {
    grid-area: title;
    margin-bottom:0;
    color: #000;
    letter-spacing: calc(14rem/16);
    font-size: clamp(calc(50rem/16), 6.5vw, calc(120rem/16));
    line-height:1;
    mix-blend-mode: lighten;
    -ms-grid-column: 1;
    -ms-grid-row: 2;
    padding-top: calc(23rem/16);
    position: relative;
    padding-bottom: calc(40rem/16);
    @media screen and (max-width: 767px) {
        margin-left:0;
        padding-left: calc(13rem/16);
        font-size: calc(36rem/16);
        letter-spacing: calc(4rem/16);
        position: relative;
        top: calc(-1rem/16);
        padding-top: calc(10rem/16);
    }
    @media screen and (max-width: 1399px) {
        padding-left: calc(15rem/16);
        margin-left:0;
    }
}
.hero-cutout__title:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: block;
    mix-blend-mode: color;
    pointer-events: none;
    background: linear-gradient(to right, rgba(240,240,240,1) 0%,rgba(240,240,240,0.8) 72%,rgba(240,240,240,0) 100%);
    @media screen and (max-width: 767px) {
        background: linear-gradient(to right, rgba(240, 240, 240, 1) 0%, rgba(240, 240, 240, 1) 80%, rgba(240, 240, 240, 0) 100%);
    }
}
.hero-cutout__quickfinder {
    grid-area: quickfinder;
    z-index: 3;
    background: linear-gradient(to right, rgba(240,240,240,1) 0%,rgba(240,240,240,0.8) 72%,rgba(240,240,240,0) 100%);
    -ms-grid-column: 1;
    -ms-grid-row: 3;
    @media screen and (max-width: 1399px) {
        padding-left: calc(15rem/16);
    }
}

.hero-cutout__quickfinder .multiple-selects-dropdown__submit-btn{
    @media screen and (min-width: 768px){
       display: none;
    }
}

.hero-cutout__quickfinder-form {
    @media screen and (min-width: 768px) {
        box-shadow: 0 calc(10rem/16) calc(80rem/16) 0 rgba(0,0,0,0.2);
    }
}
.hero-cutout__quickfinder-form .form-control {
    position: relative;
    z-index: 1;
    @media screen and (max-width: 767px) {
        box-shadow: 0 calc(5rem/16) calc(50rem/16) 0 rgba(0,0,0,0.08);
    }
}
.hero-cutout__quickfinder-link {
    text-transform: uppercase;
    display: inline-flex;
    font-family: var(--font-default-bold);
    font-size: .75rem;
    letter-spacing: .1875rem;
    position: relative;
    transition: color 0.2s ease;

    &:before {
        content:'';
        transition: background-color 0.2s ease;
        position: absolute;
        bottom: calc(-1rem/16);
        left: 0;
        background-color:var(--color-secondary);
        width: 100%;
        height: calc(2rem/16);
    }
}
.hero-cutout__quickfinder-link:hover {
    color: var(--color-primary);

    &:before {
        background-color: var(--color-primary);
    }
}
.hero-cutout__quickfinder-link__icon {
    font-size: calc(16rem/16);
    margin-top: calc(-2rem/16);
}

.hero-cutout__usps {
    margin-top: calc(50rem/16);
    @media screen and (max-width: 1399px) {
        margin-top: calc(20rem/16);
    }
}

.hero-cutout__quickfinder-form .btn{
    line-height: calc(17/12);
    @media screen and (max-width: 767px){
        height: calc(44rem/16);
    }
}

.hero-cutout__quickfinder .datepicker{
    @media screen and (min-width: 768px){
       width: calc(130rem/16);
    }
}