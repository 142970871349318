.teaser-badge{
    padding: calc(7rem/16) calc(12rem/16);
    background-color: #fff;
    color: var(--color-default);
    line-height: 1;
    
    font-size: calc(10rem/16);
    text-transform: uppercase;
    letter-spacing: calc(2.22rem/16);
    
    position: absolute;
    top: calc(15rem/16);
    left: 0;
    z-index: 3;

    @media screen and (min-width: 768px){
        padding: calc(10rem/16) calc(15rem/16);
    }
}

.teaser-badge--price{
    padding: calc(5rem/16) calc(12rem/16) calc(3rem/16);

    @media screen and (min-width: 768px){
        padding: calc(8rem/16) calc(15rem/16) calc(6rem/16);
    }
}

.teaser-badge--price{
    text-transform: none;
    letter-spacing: normal;
    font-size: calc(12rem/16);
}

.teaser-badge__price{
    font-size: calc(16rem/16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    letter-spacing: normal;
    display: inline-block;
    margin-left: calc(3rem/16);
}

/* Triangle badge */

.triangle-badge{
    width: calc(83rem/16);
    height: calc(83rem/16);
    transform: rotate(45deg);
    position: absolute;
}

.triangle-badge.is-right{
    top: 0;
    right: 0;
}

.triangle-badge__text{
    color: #fff;
    z-index: 1;
    position: absolute;
    text-align: center;
    width: 80%;
    transform-origin: center;
    bottom: 58%;
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    font-size: calc(10rem/16);
    letter-spacing: calc(2rem/16);
    left: 50%;
    transform: translateX(-50%);
}

.triangle-badge:before{
    content: '';
    position: absolute;
    border-color: transparent var(--color-primary) transparent transparent;
    border-bottom: 83px solid transparent;
    border-right: 83px solid var(--color-primary);
    border-top: 0 solid transparent;
    transform: rotate(-45deg);
    border-left: 0 solid transparent;
}