.text-with-two-img-area .text-img-grid-gallery__title{
    font-size: calc(32rem/16);
    line-height: calc(40/32);
    letter-spacing: calc(10rem/16);
}

.text-with-two-img__big{
    position: relative;
    z-index: 0;
}


.text-with-two-img__small{
    width: 58%;
    left: 50%;
    transform: translateX(-50%) translateY(-65px);
    position: relative;
    box-shadow: 0 20px 40px 0 rgba(0,0,0,0.3);
    z-index: 5;
}

.text-with-two-img__wysiwyg{

    @media screen and (min-width: 768px){
       margin-top: calc(75rem/16);
    }
}