.video-overlay__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 0 0 #dcdcdc;
    transition: all 250ms ease;
    z-index: 4;
    cursor: pointer;
}

.video-overlay {
    position: relative;
    cursor: pointer;
}
.video-overlay:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    background-color: #000;
    opacity: 0;
    transition: opacity 250ms ease;
}

.video-overlay:hover:after {
    opacity: 0.2;
}

.video-overlay:hover .video-overlay__icon {
    box-shadow: 0 0 40px 3px #dcdcdc;

}
.video-overlay__overlay {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    transition: opacity 0.2s ease;
}

.overlay-hide .video-overlay__overlay,
.overlay-hide .video-overlay__icon,
.video-overlay:after {
    display: none;
    pointer-events: auto;
}

.video-overlay.overlay-hide{
    pointer-events: auto;
}