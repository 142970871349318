.icon-text{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.icon-text__icon{
    font-size: calc(40rem/16);
    color: var(--color-dark);
    @media screen and (min-width: 768px){
        font-size: calc(50rem/16);
    }
}

.icon-text__text{
    font-size: calc(10rem/16);
    line-height: calc(15/12);
    letter-spacing: calc(2rem/16);
    color: var(--color-grey);
    text-transform: uppercase;
    margin-top: calc(12rem/16);
    text-align: center;

    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);
        letter-spacing: calc(3rem/16);
    }
}

.icon-text-list .list-inline-item:not(:last-child){
    margin-right: calc(25rem/16);
}

.icon-text-list .icon-text{
    @media screen and (min-width: 768px){
       min-width: calc(140rem/16);
    }
}