.search{
    position: absolute;
    left: 0;
    right: 0;
    background-color: var(--color-dark-grey);
    color: #fff;
    z-index: 100;
    padding: calc(100rem/16) calc(25rem/16) calc(70rem/16);
    text-align: center;

    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    transform-origin: right top;
    transition: opacity .1s .1s,visibility .1s .1s,transform .3s ease-in-out;

    @media screen and (min-width: 768px){
        padding: calc(125rem/16) calc(50rem/16) calc(200rem/16);
    }
}

.search.show-overlay{
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: opacity .1s .2s,visibility .1s .1ms,transform .4s ease-in-out;

}

.search__toptitle{
    font-size: calc(11rem/16);
    line-height: calc(15/12);
    letter-spacing: calc(2rem/16);
    text-transform: uppercase;
    margin-bottom: calc(15rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);
        letter-spacing: calc(3rem/16);
    }
}

.search__title{
    font-size: calc(30rem/16);
    letter-spacing: calc(6rem/16);
    line-height: 1;
    margin-bottom: calc(40rem/16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px){
        font-size: calc(75rem/16);
        letter-spacing: calc(15rem/16);
    }
}

.search__form{
    width: 100%;
    max-width: calc(640rem/16);
    margin: 0 auto;
    position: relative;
}


.search__btn{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: calc(30rem/16);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    
    font-size: calc(18rem/16);
}

.search__btn-text{
    font-size: calc(11rem/16);
    letter-spacing: calc(2rem/16);
    text-transform: uppercase;
    margin-left: calc(12rem/16);
    display: inline-block;
    position: relative;
    top: calc(2rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);
    }
}




/* Site SEarch */

.site-search .search__form{
    max-width: 100%;
    box-shadow: 0 20px 50px 0 rgba(0,0,0,0.15);
}


.site-search .search__btn{
    background-color: var(--color-dark-grey);
    color: #fff;
    
    @media screen and (max-width: 767px){
       padding: calc(20rem/16);
    }
}

.search__input {
    border: none;
    width: 100%;
    padding: calc(23rem/16) calc(15rem/16) calc(21rem/16);

    @media screen and (min-width: 768px){
        padding: calc(29rem/16) calc(30rem/16) calc(26rem/16);
    }
}

.site-search .floating-label{
    @media screen and (min-width: 768px){
       left: calc(30rem/16);
    }
}

.form-control.has-value~.floating-label.search__form-label,
.form-control:focus~.floating-label.search__form-label,
.form-group.has-value .floating-label.search__form-label,
.form-group:focus .floating-label.search__form-label{
    transform: translateY(-21px) scale(.62);

    @media screen and (min-width: 768px){
       transform: translateY(-24px) scale(.62);
    }
}