.account-teaser-row__heading {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.account-teaser-row__btn {
    margin-top: calc(20rem /16);
    @media screen and (min-width: 768px) {
        margin-left: calc(40rem / 16);
        margin-top: 0;
    }
}

.account-teaser-row__ctas {
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px){
        margin-bottom: calc(20rem /16);
    }
}