
.benefits-teaser__body{
    padding: calc(20rem/16);
    background: linear-gradient(180deg,#fff,#f0f0f0);

    @media screen and (min-width: 768px){
        padding: calc(30rem/16) calc(50rem/16) calc(35rem/16);
    }
}

.benefits-teaser__title{
    position: relative;
    padding-bottom: calc(14rem/16);
    font-size: calc(22rem/16);
    letter-spacing: calc(5.5rem/16);
    margin-bottom: calc(35rem/16);
}

.benefits-teaser__title:after{
    content: '';
    position: absolute;
    height: calc(4rem/16);
    width: calc(40rem/16);
    background-color: var(--color-primary);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.list-benefits>li{
    position: relative;
    padding-left: calc(40rem/16);
}

.list-benefits>li:not(:last-child){
    margin-bottom: calc(20rem/16);
}

.list-benefits>li:before{
    content: '';
    position: absolute;
    left: 0;
    width: calc(30rem/16);
    height: calc(30rem/16);
    border-radius: 50%;
    border: 2px solid var(--color-default-dark-grey);
    top: calc(-6rem/16);
}

.list-benefits>li:after{
    content: var(--icon-open);
    font-family: iconfont;
    position: absolute;
    color: var(--color-default-dark-grey);
    font-size: calc(11rem/16);
    left: calc(9rem/16);
    top: calc(2rem/16);
}