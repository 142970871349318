.selection__item label {
    display: flex;
}
.selection__item-title {
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
}
.selection__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.selection__input-text {
    width: 100%;
    border: calc(2rem/16) solid #fff;
    border-radius: 0;
    padding: calc(19rem/16) calc(30rem/16) calc(17rem/16);
    background: #fff;
    cursor: pointer;
    font-size: calc(14rem/16);
    line-height: 1;
    transition: border-color 0.3s cubic-bezier(0.280, 0.840, 0.420, 1);
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16) calc(15rem/16);
    }
}
.selection__input:checked ~ .selection__input-text, .selection__input:not(:disabled):hover ~ .selection__input-text {
    border-color: var(--color-dark);
}
.selection__input:disabled ~ .selection__input-text {
    opacity:0.5;
    pointer-events: none;
}
.selection__item + .selection__item {
    margin-top: calc(10rem/16);
}
.selection__list{
    animation: fadeIn 0.4s ease-in-out forwards;
}

@keyframes fadeIn {
    0%   { opacity: 0;}
    100% { opacity:1; }
}
