.floating-img-text-area{
    position: relative;
    padding-top: calc(25rem/16);

    @media screen and (min-width: 768px){
        padding-top: calc(65rem/16);
    }
}

.floating-img-text__style-text{
    font-size: calc(36rem/16);
    letter-spacing: calc(5rem/16);
    color: #fff;
    text-transform: uppercase;
    font-family: var(--font-default-bold);

    position: absolute;
    top: 0;
    left: calc(15rem/16);
    z-index: -1;

    @media screen and (min-width: 1600px){
        left: calc(-120rem/16);
    }

    @media screen and (min-width: 768px){
        font-size: calc(90rem/16);
        letter-spacing: calc(28rem/16);
    }
}

.floating-img-text__bottom-row{
    margin-top: calc(-40rem/16);
    @media screen and (min-width: 768px){
        margin-top: -22%;
    }
}

.floating-img-text__img-right{
    position: relative;
    z-index: 3;
    margin-top: calc(-70rem/16);

    @media screen and (min-width: 1200px){
        margin-top: calc(-40rem/16);
    }

    @media screen and (min-width: 768px){
        margin-top: calc(40rem/16);
    }
}

.floating-img-text__img-middle{
    @media screen and (min-width: 768px){
        transform: translateX(-21%);
    }
}

.floating-img-text__img-left{
    position: relative;
    z-index: 2;

    margin-top: calc(20rem/16);

    @media screen and (min-width: 1200px){
        margin-top: 12%;
    }

    @media screen and (min-width: 768px){
        margin-top: 5%;
    }
}
.floating-img-text-img-shadow{
    box-shadow: 0 42px 50px 0 rgba(0,0,0,0.2);
}