:root {
    --color-primary: #FF0000;
    --color-secondary: #2C2B2E;
    --color-third: #27C7E9;
    --color-text-default: #191919;
    --color-text-muted: #808082;

    --color-default: #191919;
    --color-white: #FFF;
    --color-light-grey: #f0f0f0;
    --color-grey: #B1B1B1;
    --color-middle-grey: #dedede;
    --color-dark-grey: #2C2B2E;
    --color-default-dark-grey: #2C2B2E;
    --color-dark: #191919;
    --color-turquoise: #27C7E9;

    --color-success: #58C45D;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #CA0D0D;

    --color-season-peak: var(--color-turquoise);
    --color-season-pre: #434371;
    --color-season-off: #D4C2FC;

    --color-special-primary: #f8e217;
    --color-special-secondary: #f89300;
    --color-special-third: #98115a;

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-turquoise-dark: #21a8c4;
    --color-primary-dark: #d30000;
    --color-secondary-dark: #171619;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #151417;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-turquoise-light: #42e5fe;
    --color-primary-light: #FF3200;
    --color-secondary-light: #3d3c3f;
    --color-success-light: #74EA7B;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #FF0000;
    --color-light-light: #fff;
    --color-dark-light: #474649;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;
    --color-red-light:#FF4C4C;
    --color-footer-socials-bg:var(--color-primary);

    --color-success-muted: #747475;

    --font-default: "DINPro-Regular", sans-serif;
    --font-default-bold: "DINPro-Bold", sans-serif;
    --font-size-default: calc(14rem/16);
}

