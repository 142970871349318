.booking-quickfinder-area {
    background-color: var(--color-default-dark-grey);
    padding: calc(60rem/16) 0;
    position: relative;
    z-index: 5;

    @media screen and (min-width: 768px){
        padding: calc(150rem/16) 0;
    }
}

.booking-quickfinder-area .title-block{
    color: #fff;
    margin-bottom: calc(10rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(40rem/16);
    }
}

.quickfinder-large{
    @media screen and (min-width: 768px){
       background-color: #fff;
    }
}
.quickfinder-large .form-group--large .form-control{
    font-family: var(--font-default-bold);
    
    @media screen and (min-width: 768px) {
        height: calc(120rem/16);
        padding: calc(35rem/16) calc(80rem/16) calc(12rem/16);
        font-size: calc(20rem/16);
        letter-spacing: calc(3rem/16);
    }
    
    @media screen and (max-width: 767px){
       height: calc(65rem/16);
        font-size: calc(16rem/16);
        padding: calc(28rem/16) calc(15rem/16) calc(10rem/16) calc(40rem/16);
    }
}

.quickfinder-large .form-group--large .floating-label{
    @media screen and (min-width: 768px){
       left: calc(80rem/16);
    }
}

.quickfinder-large .form-group--large .form-control.has-value~.floating-label,
.quickfinder-large .form-group--large .form-control:focus~.floating-label,
.quickfinder-large .form-group--large.form-group.has-value .floating-label,
.quickfinder-large .form-group--large.form-group:focus .floating-label{
    @media screen and (min-width: 768px){
        transform: translateY(-24px) scale(.75);
    }

    transform: translateY(-20px) scale(.75);
}

.quickfinder-large .datepicker .form-control{
    padding-left: calc(37rem/16);
    @media screen and (min-width: 768px){
       padding-left: calc(110rem/16);
    }
}

.quickfinder-large .datepicker:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: auto;
    left: calc(15rem/16);
    font-size: calc(15rem/16);
    margin-top: calc(4rem/16);

    @media screen and (min-width: 768px){
        left: calc(80rem/16);
        font-size: calc(20rem/16);
        margin-top: calc(8rem/16);
    }
    
    @media screen and (max-width: 767px){
        margin-top: calc(6rem/16);
    }
}

.quickfinder-large__submit{
    font-size: calc(12rem/16);
    letter-spacing: calc(2rem/16);
    @media screen and (max-width: 767px){
       height: calc(65rem/16);
        padding: calc(8rem/16) calc(10rem/16);
    }

    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
        letter-spacing: calc(4rem/16);
    }
}

.quickfinder-large__submit .icon{
    font-size: calc(23rem/16);
    vertical-align: calc(-6rem/16);
}

.dropdown-quickfinder{
    height: 100%;
    width: 100%;
}

.dropdown-quickfinder .dropdown-toggle{
    width: 100%;
    height: calc(65rem/16);
    background-color: #fff;
    padding: calc(20rem/16) calc(15rem/16) 0;

    @media screen and (min-width: 768px){
        height: 100%;
        padding: calc(20rem/16) calc(30rem/16) 0;
    }
}

.dropdown-quickfinder .dropdown-toggle:after{
    display: none;
}

.dropdown-quickfinder__label{
    font-size: calc(9rem/16);
    letter-spacing: calc(1rem/16);
    color: var(--color-grey);
    text-transform: uppercase;
    position: absolute;
    top: calc(13rem/16);

    @media screen and (min-width: 768px){
        top: calc(37rem/16);
        font-size: calc(11rem/16);
    }
    
    @media screen and (max-width: 767px){
       left: calc(15rem/16);
        line-height: calc(1rem/16);
    }
}

.dropdown-quickfinder__value-wrapper{
    @media screen and (max-width: 767px){
       font-size: calc(10rem/16);
    }
}

.dropdown-quickfinder__value{
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    margin-right: calc(3rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
    }
}

.dropdown-quickfinder .dropdown-menu{
    position: absolute;
    left: 50%!important;
    transform: translateX(-50%);
    width: calc(200rem/16);
    padding: 0;
    box-shadow: 0 10px 80px 0 rgba(0,0,0,0.2);

    @media screen and (min-width: 768px){
        transform: translateX(-50%) translateY(120px)!important;
    }
}

.dropdown-quickfinder .dropdown-menu:before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: calc(11rem/16) calc(11rem/16) 0 calc(11rem/16);
    border-color: #ffffff transparent transparent transparent;
    bottom: calc(-10rem/16);
    left: 50%;
    transform: translateX(-50%);

    @media screen and (min-width: 768px){
        border-width: 0 calc(14rem/16) calc(15rem/16) calc(14rem/16);
        border-color: transparent transparent #ffffff transparent;
        top: calc(-15rem/16);
        bottom: auto;
    }
}

.quickfinder-large .dropdown-quickfinder .dropdown-menu{
    width: 100%;
    transform: translateX(-50%) !important;

    @media screen and (min-width: 768px){
        transform: translateX(-50%) translateY(118px)!important;
        top: 0!important;
    }
}

.quickfinder__dropdown-selects{
    padding: calc(30rem/16) calc(17rem/16) calc(8rem/16) calc(28rem/16);

    @media screen and (min-width: 768px){
        padding: calc(30rem/16) calc(40rem/16) calc(8rem/16);
    }
}

.quickfinder__childs-wrapper{
    padding: calc(30rem/16) calc(17rem/16) calc(20rem/16) calc(28rem/16);
    border-top: 1px solid var(--color-light-grey);
    
    @media screen and (min-width: 768px){
        padding: calc(30rem/16) calc(40rem/16) calc(20rem/16);
    }
}

.dropdown-quickfinder .dropdown-menu .form-control{
    padding: calc(13rem/16) 0 calc(5rem/16);
    height: calc(42rem/16);
    margin-bottom: calc(8rem/16);
    
    @media screen and (max-width: 767px){
       font-size: calc(13rem/16);
    }
}

.dropdown-quickfinder .dropdown-menu .quickfinder__childs-wrapper .form-control{
    margin-bottom: calc(14rem/16);
}

.dropdown-quickfinder .dropdown-menu .has-value .floating-label{
    left: 0;
    transform: translateY(-24px) scale(.62);
}


/* Quickfinder Hero */

.quickfinder-hero{
    background-color: #fff;
}

.quickfinder-hero .form-control{
    height: calc(55rem/16);

    @media screen and (min-width: 768px){
        height: calc(80rem/16);
    }
}

.quickfinder-hero .dropdown-quickfinder__value{
    font-size: calc(14rem/16);
    font-family: var(--font-default);
}

.quickfinder-hero .dropdown-quickfinder .dropdown-toggle{
    font-size: calc(9rem/16);
    padding: calc(9rem/16) calc(15rem/16) 0;

    @media screen and (min-width: 768px){
        padding: calc(9rem/16) calc(30rem/16) 0;
    }
    
    @media screen and (max-width: 767px){
       height: calc(55rem/16);
    }
}

.quickfinder-hero .dropdown-quickfinder__label{
    font-size: calc(8rem/16);
    top: calc(15rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(9rem/16);
        top: calc(24rem/16);
    }
}

.quickfinder-hero .dropdown-quickfinder .dropdown-menu{
    transform: translateX(-50%) translateY(16px)!important;
}

.quickfinder-hero .dropdown-quickfinder .dropdown-menu:before{
    border-width: 0 calc(11rem/16) calc(12rem/16) calc(11rem/16);
    top: calc(-12rem/16);
}

.quickfinder-hero .quickfinder-large__submit{
    background-color: var(--color-dark);
    border-color: var(--color-dark);
    
    @media screen and (max-width: 767px){
       height: calc(55rem/16);
    }
}

.quickfinder-hero .quickfinder-large__submit .icon{
    display: none;
}

.quickfinder-hero .quickfinder__dropdown-selects{
    padding: calc(30rem/16) calc(30rem/16) calc(8rem/16);
}


.dropup-xs .dropdown-menu {
    @media screen and (max-width: 767px){
        top: auto;
        bottom: 98%;
        margin-top: 0;
        margin-bottom: .125rem;
    }
}