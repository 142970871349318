:root {
    --style-text-width: calc(1635rem/16);
    --container-width: calc(1310rem/16);
}


.img-text-tabs-area{
    padding-top: calc(20rem/16);
    position: relative;

    @media screen and (min-width: 768px){
        padding-top: calc(45rem/16);
    }
}

.img-text-tabs-area .wysiwyg-block{
    position: relative;
    z-index: 2;
    @media screen and (min-width: 768px){
       padding-left: 19%;
    }
}

.img-text-tabs__bg{
    background-size: cover;
    height: calc(460rem/16);
    filter: grayscale(100%);
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: calc(-15rem/16);
    right: calc(-15rem/16);

    @media screen and (min-width: 768px){
        left: 0;
        right: 0;

        height: calc(432rem/16);

    }
}


.img-text-tab-item__style-text{
    @media screen and (min-width: 768px){
        color: #fff;
        font-size: calc(140rem/16);
        letter-spacing: calc(122rem/16);
        font-family: var(--font-default-bold);
        text-transform: uppercase;
        line-height: calc(180/140);
        white-space: nowrap;

        max-width: var(--style-text-width);

        position: absolute;
        left: 0;
        top: calc(235rem/16);
        opacity: 0.65;
    }
    @media screen and (min-width: 1500px){
        left: calc(((var(--style-text-width) - var(--container-width) - 122px) / 2) * -1);
    }
    @media screen and (max-width: 767px){
       display: none;
    }
}