.nav-overlay {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    height: 100%;
    transform: scale(0);
    transform-origin: right top;
    z-index: 20;
    transition: opacity .1s .1s,visibility .6s .1s,transform .3s ease-in-out;
    background: var(--color-default);
    color: #fff;
    @media screen and (min-width: 768px){
        padding: calc(177rem/16) 0;
        background: linear-gradient(180deg, #191919 0%, rgba(25,25,25,0.9) 100%);
    };
    
    @media screen and (max-width: 767px){
        padding: calc(80rem/16) calc(30rem/16) calc(135rem/16);
    }

    @media screen and (min-width: 768px) and (max-height: 800px){
        padding: calc(100rem/16) 0 calc(30rem/16);
    }

    @media screen and (min-width: 768px) and (max-height: 700px){
       padding: calc(90rem/16) 0;
    }
}


.nav-overlay.show-overlay{
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: opacity .1s .2s,visibility .6s .1ms,transform .4s ease-in-out;

}

.nav-overlay-container{
    max-width: calc(938rem/16);
    width: 100%;
    margin: 0 auto;
    
    @media screen and (min-width: 768px){
       height: 100%;
    }
    
    @media screen and (max-width: 767px){
       height: 100%;
        overflow-y: auto;
    }
}

.nav-overlay__close-btn{
    border: 2px solid #fff;
    color: #fff;
    padding: calc(15rem/16) calc(20rem/16);
    background-color: transparent;
    transition: all 250ms ease;
    font-size: calc(12rem/16);
    text-transform: uppercase;
    letter-spacing: calc(3rem/16);
    font-family: var(--font-default-bold);
    line-height: 1;
    opacity: 0.5;
    margin-top: calc(80rem/16);

    @media screen and (min-width: 768px) and (max-height: 700px){
        display: none;
    }

}

.nav-overlay__close-btn:hover{
    background-color: #fff;
    color: var(--color-dark);
    opacity: 1;
}

.nav-overlay__close-btn .icon{
    font-size: calc(10rem/16);
    vertical-align: calc(-1rem/16);
}