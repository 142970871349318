.message{
    background-color: var(--color-turquoise);
    color: #fff;
    padding: calc(10rem/16) calc(55rem/16) calc(10rem/16) 0;
}

.message__sign{
    width: calc(26rem/16);
    height: calc(26rem/16);
    border-radius: 50%;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px){
        width: calc(32rem/16);
        height: calc(32rem/16);
    }
}

.message__title{
    font-family: var(--font-default-bold);
    font-size: calc(13rem/16);
    letter-spacing: calc(2rem/16);
    text-transform: uppercase;
    margin-left: calc(10rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(15rem/16);
        letter-spacing: calc(2.5rem/16);
    }
}

.message__text{
    @media screen and (min-width: 768px){
        margin-left: calc(10rem/16);
    }
    @media screen and (max-width: 767px){
       font-size: calc(13rem/16);
    }
}

.message__link{
    text-transform: uppercase;
    letter-spacing: calc(2rem/16);
    font-size: calc(11rem/16);
    font-family: var(--font-default-bold);
    padding-bottom: calc(2rem/16);
    position: relative;
    white-space: nowrap;
    margin-left: calc(10rem/16);

    @media screen and (min-width: 768px){
        letter-spacing: calc(3rem/16);
        font-size: calc(12rem/16);
        margin-left: calc(15rem/16);
    }
}

.message__link:after{
    content: '';
    position: absolute;
    left: 0;
    right: calc(1rem/16);
    bottom: 0;
    height: calc(1.5rem/16);
    background-color: #fff;
}

.message__close-btn{
    position: absolute;
    top: calc(11rem/16);
    right: calc(15rem/16);
    width: calc(30rem/16);
    height: calc(30rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
}