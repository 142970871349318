html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default);
    /*word-break: break-word;*/
    line-height: calc(20/14);
}
.font-default {
    font-family: var(--font-default);
}

b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
    text-transform: uppercase;
}

h1, .h1 {
    font-size: calc(35rem/16);
    line-height: calc(38/35);
    letter-spacing: calc(4.5rem/16);
    font-family: var(--font-default-bold);
}
h2, .h2 {
    font-size: calc(23rem/16);
    line-height: calc(32/28);
    letter-spacing: calc(4rem/16);
    font-family: var(--font-default-bold);
}
h3, .h3 {
    font-size: calc(20rem/16);
    line-height: calc(25/20);
    letter-spacing: calc(4rem/16);
    font-family: var(--font-default-bold);
}
h4, .h4 {
    font-size: calc(12rem/16);
    line-height: calc(15/12);
    letter-spacing: calc(3rem/16);
    font-family: var(--font-default);
}
h5, .h5 {
    font-size: calc(12rem/16);
    line-height: calc(15/12);
    letter-spacing: calc(2.5rem/16);
    font-family: var(--font-default);
}
h6, .h6 {
    font-size: calc(12rem/16);
    font-family: var(--font-default);

}

@media screen and (min-width: 768px){
    h1, .h1 {
        font-size: calc(75rem/16);
        letter-spacing: calc(15rem/16);
        line-height: 1;
    }
    h2, .h2 {
        font-size: calc(30rem/16);
        letter-spacing: calc(8rem/16);
        line-height: calc(40/32);
    }
    h3, .h3 {
        font-size: calc(22rem/16);
        letter-spacing: calc(5.5rem/16);
        line-height: calc(25/22);
    }

}

table{
    word-break: initial;
}