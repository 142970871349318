:root {
    --checkmark-background: transparent;
    --checkmark-foreground: black;
    --checkmark-curve: cubic-bezier(0.83, 0, 0.17, 1);
}

.checkmark__red {
    --checkmark-background: white;
    --checkmark-foreground: red;
}

.checkmark {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: var(--checkmark-foreground);
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px var(--checkmark-background);
    animation: fill .4s var(--checkmark-curve) .4s forwards var(--checkmark-curve), scale .3s var(--checkmark-curve) .9s both;
    animation-delay: calc(var(--microanimations-stagger-factor) * var(--microanimations-stagger-base));
    margin-right: .75rem;
    flex-shrink: 0;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-miterlimit: 10;
    fill: var(--checkmark-background);
    animation: stroke .6s var(--checkmark-curve) forwards;
    animation-delay: calc(var(--microanimations-stagger-factor) * var(--microanimations-stagger-base));
    stroke: var(--checkmark-foreground);
    stroke-width: 5;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke .3s var(--checkmark-curve) .8s forwards;
    animation-delay: calc(var(--microanimations-stagger-factor) * var(--microanimations-stagger-base));
    stroke-width: 5px;
    scale: .85;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }
    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px var(--checkmark-background);
    }
}
