.webcam-area{
    position: relative;
}

.webcam-area__toggle {
    height: calc(40rem /16);
    width: calc(40rem /16);
    position: absolute;
    top: calc(10rem /16);
    right: calc(10rem /16);
    z-index: 3;
    transform: translateZ(-1px);
    @media screen and (min-width:768px){
        height: calc(50rem /16);
        width: calc(50rem /16);
    }
}

.webcam-area__overlay {
    position: absolute;
    z-index: 5;
    inset: 0;
    background-color:var(--color-light-grey);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: calc(1rem / 16) solid #DEDEDE;
}

.webcam-area__icon {
    font-size: calc(40rem / 16);
    margin-bottom: calc(20rem / 16);
    @media screen and (min-width: 768px) {
        margin-bottom: calc(30rem / 16);
    }
}

.webcam-area__title {
    font-size: calc(20rem / 16);
    margin-bottom: calc(10rem / 16);
}

.webcam-area__subtitle {
    font-size: calc(12rem / 16);
    text-transform: uppercase;
    letter-spacing: calc(3rem /16);
    margin-bottom: calc(30rem / 16);
    @media screen and (min-width: 768px) {
        margin-bottom: calc(46rem / 16);
    }
}

.webcam-area__toggle {
    font-size: calc(20rem / 16);
}