.img-text{
    text-align: center;
}

.img-text__img{
    width: calc(30rem/16);
    height: calc(21rem/16);
}

.img-text--payment .img-text__img {
    width: calc(46rem/16);
    height: calc(46rem/16);
}

.img-text__text{
    font-size: calc(11rem/16);
    text-transform: uppercase;
    color: var(--color-grey);
    letter-spacing: calc(2rem/16);
    margin-top: calc(10rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);
        letter-spacing: calc(3rem/16);
    }
}

.img-text--payment .img-text__text{
    margin-top: calc(5rem/16);
}