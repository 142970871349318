.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: calc(15rem/16);
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a:not(.btn) {
    color: var(--color-primary);
}
.wysiwyg a:hover:not(.btn),
.wysiwyg a:focus:not(.btn),
.wysiwyg a:active:not(.btn) {
    color: var(--color-primary);
    text-decoration: underline;
}

.wysiwyg{
    line-height: calc(21/14);
}