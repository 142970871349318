.form-control{
    padding: calc(20rem/16) calc(12rem/16) calc(8rem/16);
    color: var(--color-default-dark-grey);
    border: 0;
    height: calc(44rem/16);

    @media screen and (min-width: 768px){
        padding: calc(20rem/16) calc(20rem/16) calc(8rem/16);
        height: calc(50rem/16);
    }
    
    @media screen and (max-width: 767px){
       font-size: calc(12rem/16);
    }
}
.form-control--large {
    @media screen and (min-width: 768px) {
        height: calc(80rem/16);
        padding: calc(30rem/16) calc(20rem/16) calc(20rem/16);
    }
}
.form-control--medium {
    @media screen and (min-width: 768px) {
        height: calc(60rem/16);
    }
}

.form-control[readonly]{
    background-color: #fff;
}

.form-control[readonly]:focus{
    outline: none;
    box-shadow: none;
}
.form-control--has-shadow {
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
}
.form-control.disable{
    pointer-events: none;
}

textarea.form-control{
    height: auto;
}

.has-error .form-control{
    border: 1px solid var(--color-danger);
}

.select {
    position: relative;
}
.select select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.select select::-ms-expand {
    display: none;
}
.select:before {
    font-family: "iconfont";
    content: var(--icon-arrow-dropdown);
    color: var(--color-dark);
    speak: none;
    position: absolute;
    pointer-events: none;
    font-size: calc(9rem / 16);
    top: 50%;
    right: calc(10rem / 16);
    transform: translateY(-50%);
    z-index: 2;
    line-height: 1;
    @media screen and (min-width: 768px){
        right: calc(20rem / 16);
    }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: var(--color-dark);
}
::-moz-placeholder { /* Firefox 19+ */
    color: var(--color-dark);
}
:-ms-input-placeholder { /* IE 10+ */
    color: var(--color-dark);
}
:-moz-placeholder { /* Firefox 18- */
    color: var(--color-dark);
}

.input-group__icon{
    display: flex;
    align-items: center;

    justify-content: center;
    line-height: 1;
    letter-spacing: normal;
    padding: 15px;
    background-color: #fff;
}
.date-input--static .input-group {
    display: grid;
    grid-template-columns: 1fr auto;
}

.form-group--icon__wrapper, .form-group--btob {
    position: relative;
}

.form-group--icon__wrapper:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: calc(30rem/16);
    background-color: #fff;
    transform: translateX(-100%);
    z-index: -1;
}

.form-group--icon__wrapper .form-control{
    padding-left: calc(15rem/16);
}

.form-control__img-wrapper {
    border-radius: 50%;
    width: calc(70rem /16);
    @media screen and (min-width: 768px){
        width: calc(80rem /16);
    }
}

.form-control__img {
    border-radius: 50%;
}

.form-control__info-text {
    color: #848484;
    max-width: calc(120rem /16);
    line-height: 1;
    font-size: calc(11rem /16);
    margin-left: calc(8rem /16);
    text-align: right;
}

#addressForm .form-group.has-value.has-success,
#personalizationModal .form-group.has-value.has-success{
    position: relative;
}

#addressForm .form-group.has-value.has-success .form-control,
#personalizationModal .form-group.has-value.has-success .form-control{
    border: calc(1rem/16) solid var(--color-success);
}

#addressForm .form-group.has-value.has-success > :not(.select):after,
#personalizationModal  .form-group.has-value.has-success > :not(.select):after{
   content: var(--icon-check);
    font-family: iconfont;
    position: absolute;
    right: calc(10rem/16);
    top: calc(20rem/16);
    color: var(--color-success);
    @media screen and (max-width: 767px){
        font-size: calc(10rem/16);
    }
}