.sector-teaser {
    background-color:#fff;
    position: relative;
    padding: calc(20rem/16);
    border: calc(2rem/16) solid transparent;
    cursor:pointer;
    transition: border-color 0.2s ease;
    @media screen and (min-width: 768px) {
        padding: calc(30rem/16);
    }
}
.sector-teaser:hover, .sector-teaser--is-active {
    border-color: var(--color-primary);
}
.sector-teaser--is-highlighted:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    pointer-events: none;
    box-shadow: 0 calc(5rem/16) calc(30rem/16) 0 rgba(0,0,0,0.15);
}
.sector-teaser__title {
    font-size: 1rem;
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    letter-spacing: calc(3rem/16);
}
.sector-teaser__feature {
    font-size: calc(14rem/16);
    display: flex;
    align-items: center;
}
.sector-teaser__feature-icon {
    font-size: calc(22rem/16);
    margin-right: calc(3rem/16);
}
.sector-teaser__price {
    letter-spacing: calc(3rem/16);
    font-size: calc(24rem/16);
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    @media screen and (min-width: 768px) {
        font-size: calc(32rem/16);
        letter-spacing: calc(5rem/16);
    }
}
.sector-teaser__currency {
    letter-spacing: calc(5rem/16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    font-size: calc(14rem/16);
    color: var(--color-default);
}
.sector-teaser__small-print {
    color: var(--color-grey);
    font-size: calc(12rem/16);
    margin-top: calc(10rem/16);
    margin-bottom:calc(10rem/16);
    line-height: calc(20rem/14);
    @media screen and (min-width: 768px) {
        margin-bottom: calc(20rem/16);
    }
}
.sector-teaser__description {
    line-height: calc(20rem/14);
    font-size: calc(14rem/16);
    margin-bottom: calc(10rem/16);
    @media screen and (min-width: 768px) {
        line-height: calc(28rem/14);
        margin-bottom: calc(30rem/16);
    }
}
.scrolling-area--sector-teaser {
    @media screen and (max-width: 767px){
        margin-right: calc(-15rem/16);
    }
}
.scrolling-area--sector-teaser .scrolling-area__item{
    @media screen and (max-width: 767px){
        width: 90%;
    }
}
.sector-teaser--is-disabled {
    cursor: initial;
}
.sector-teaser--is-disabled:before {
    content:'';
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: var(--color-light-grey);
    opacity: .7;
    z-index: 8;
}
.sector-teaser--is-disabled:hover {
    border-color:#fff;
}
.sector-teaser__tooltip-btn {
    position: absolute;
    right: calc(4rem/16);
    top: calc(4rem/16);
    z-index: 9;
    text-indent: calc(-1rem/16);
}
.sector-teaser__tooltip-btn:active, .sector-teaser__tooltip-btn:focus, .sector-teaser__tooltip-btn:active:focus {
    background-color: var(--color-dark);
    color: #fff;
}
.sector-teaser__tooltip .tooltip-inner{
    font-size: calc(10rem/16);
    text-transform: uppercase;
    padding: calc(7rem/16);
    max-width: inherit;
    letter-spacing: calc(1.7rem/16);
}
.sector-teaser__validity-date {
    font-size: calc(10rem/16);
    text-transform: uppercase;
    letter-spacing: calc(1.7rem/16);
    position: absolute;
    top: 100%;
    right:0;
    width: 100%;
}
.sector-teaser__tooltip-teaser-btn {
    position: absolute !important;
    z-index: 9 !important;
    bottom: calc(8rem/16);
    height: calc(25rem/16);
    width: calc(25rem/16);
    right: calc(8rem/16);
}
.sector-teaser__tooltip-teaser-btn:focus, .sector-teaser__tooltip-teaser-btn:active, .sector-teaser__tooltip-teaser-btn:focus:active {
    box-shadow: none;
    background-color: var(--color-dark);
    color:#fff;
    border: var(--color-dark);
}
.sector-teaser__btn {
    place-self: start;
    min-width: calc(180rem/16);
}