.number-spinner__button {
    width:  calc(18rem/16);
    height: calc(18rem/16);
    background-color: var(--color-primary);
    color:#fff;
    font-size: calc(14rem/16);
    display: inline-flex;
    justify-content: center;
    align-content: center;
    letter-spacing: 0;
    text-align:center;
    line-height:1;
    border-radius: 50%;
    padding: calc(1rem/16);
}
.number-spinner__button:hover, .number-spinner__button:active, .number-spinner__button:focus {
    color:#fff;
}
.number-spinner__input {
    display: flex;
    width: calc(38rem/16);
    height: 20px;
    border: none;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.number-spinner__input::-webkit-outer-spin-button,
.number-spinner__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.number-spinner__input[type=number] {
    -moz-appearance: textfield;
}