.ribbon {
    width: calc(200rem/16);
    text-align:center;
    position: absolute;
    top: calc(25rem/16);
    color:#fff;
    text-transform: uppercase;
    font-size: calc(12rem/16);
    font-family: var(--font-default-bold);
    letter-spacing: calc(3rem/16);
    background-color: var(--color-primary);
    box-shadow: 0 calc(5rem/16) calc(30rem/16) 0 rgba(104,0,0,0.3);
}
.ribbon--right {
    padding: calc(12rem/16) calc(27rem/16) calc(12rem/16) calc(45rem/16);
    transform: rotate(45deg);
    right: calc(-45rem/16);
}
.ribbon--left {
    padding: calc(12rem/16) calc(45rem/16) calc(12rem/16) calc(27rem/16);
    transform: rotate(-45deg);
    left: calc(-45rem/16);
}