.img-teaser {
    position: relative;
}
.img-teaser--has-max-width {
    max-width: calc(416rem/16);
}
.img-teaser--has-shadow {
    box-shadow: 0 40px 50px 0 rgba(0,0,0,0.25);
}
.img-teaser__body{
    color: #fff;
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: flex-end;
    padding: calc(25rem/16);
    @media screen and (max-width: 767px){
        padding: calc(10rem/16);
    }
}
.img-teaser__toptitle{
    @media screen and (max-width: 767px){
        margin-bottom: calc(3rem/16);
        font-size: calc(11rem/16);
        letter-spacing: calc(2.5rem/16);
    }
}
.img-teaser__title{
    text-transform: none;
    letter-spacing: 0;
    margin-bottom:0;

    @media screen and (max-width: 767px){
        font-size: calc(15rem/16);
    }
}
.img-teaser__icon {
    font-size: calc(30rem/16);
    margin-bottom: calc(13rem/16);
}

.img-teaser__icon.icon-restaurant{
    font-size: calc(20rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(23rem/16);
    }
}

.img-teaser-grid-area .img-teaser__icon{
    font-size: calc(25rem/16);
    margin-bottom: calc(15rem/16);
}

.img-teaser__img-wrapper {
    overflow: hidden;
}
.img-teaser__img-wrapper:after {
    content:'';
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    left:0;
    top:0;
    right:0;
    transition: opacity 0.2s ease;
}
.img-teaser--has-grey-border-bottom .img-teaser__body:after {
    content:'';
    position: absolute;
    bottom:0;
    width: 100%;
    height: calc(60rem/16);
    left:0;
    z-index: -1;
    transform: translateY(90%);
    background-color: var(--color-dark);
    transform-origin: bottom;
    transition: 0.2s ease;
    @media screen and (max-width: 767px) {
        transform: translateY(95%);
    }
}
@media screen and (min-width: 768px) {
    .img-teaser:before {
        content:'';
        box-shadow: 0 calc(42rem/16) calc(60rem/16) 0 rgba(0,0,0,0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        left:0;
        right:0;
        opacity:0;
        transition: opacity 0.3s ease;
    }
    .img-teaser--has-grey-border-bottom .img-teaser__body:before {
        content:'';
        position: absolute;
        bottom: calc(60rem/16);
        width: 0;
        left:0;
        height: 0;
        transform: translateY(calc(93rem/16));
        border-style: solid;
        border-width: 0 0 calc(35rem/16) calc(550rem/16);
        display: block;
        z-index: -1;
        transition: 0.2s ease;
        border-color: transparent transparent var(--color-dark) transparent;
    }
    .img-teaser--has-grey-border-bottom:hover .img-teaser__body:after, .img-teaser--has-grey-border-bottom:hover .img-teaser__body:before {
        transform: translateY(0);
    }
    .img-teaser:hover .img-teaser__img-wrapper:after {
        opacity:0.3;
    }
    .img-teaser:hover:before {
        opacity:1;
    }
    .img-teaser__img {
        transform-origin: center;
        transition: transform 0.3s ease;
    }
    .img-teaser:hover .ratio-cover-centered .img-teaser__img {
        transform: translateX(-50%) translateY(-50%) scale(1.03);
    }
    .img-teaser:hover .img-teaser__img {
        transform: scale(1.03);
    }
}

/* Img teaser small */

.img-teaser-row-area .img-teaser--small{
    @media screen and (min-width: 1400px){
       width: calc(240rem/16);
    }
    
    @media screen and (min-width: 1200px){
        width: calc(190rem/16);
    }
    
    @media screen and (min-width: 768px){
        width: calc(175rem/16);
    }
}

.img-teaser--small .img-teaser__title{
    font-size: calc(18rem/16);
    letter-spacing: 0.5px;
    line-height: calc(23/18);
}

.img-teaser--small .img-teaser__body{
    padding: calc(15rem/16);
}