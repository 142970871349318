:root {
    --circle-gradient-white: linear-gradient(0deg, #FFFFFF 0%, #CFCFCF 100%);
    --circle-gradient-white-reverse: linear-gradient(180deg, #FFFFFF 0%, #CFCFCF 100%);
    --circle-gradient-green: linear-gradient(0deg, var(--color-success-light) 0%, var(--color-success) 100%);
    --circle-gradient-green-reverse: linear-gradient(180deg, var(--color-success-light)  0%, var(--color-success) 100%);
    --circle-gradient-red: linear-gradient(0deg, var(--color-danger-light) 0%, var(--color-danger) 100%);
    --circle-gradient-red-reverse: linear-gradient(180deg, var(--color-danger-light)  0%, var(--color-danger) 100%);
    --circle-gradient-black: linear-gradient(0deg, #49484C 0%, #1A1A1B 100%);
    --circle-gradient-black-reverse: linear-gradient(180deg, #49484C  0%, #1A1A1B 100%);
    --circle-gradient-blue: linear-gradient(0deg, #26C0E0 0%, #1E9BB6 100%);
    --circle-gradient-blue-reverse: linear-gradient(180deg, #26C0E0  0%, #1E9BB6 100%);
    --circle-gradient-yellow: linear-gradient(0deg, #FAE35A 0%, #F0C709 100%);
    --circle-gradient-yellow-reverse: linear-gradient(180deg, #FAE35A  0%, #F0C709 100%);
    --circle-gradient-orange: linear-gradient(0deg, #FC996D 0%, #F9722D 100%);
    --circle-gradient-orange-reverse: linear-gradient(180deg, #FC996D  0%, #F9722D 100%);
    --circle-gradient-primary: linear-gradient(0deg, var(--color-primary) 0%, var(--color-secondary) 100%);
    --circle-gradient-primary-reverse: linear-gradient(180deg, var(--color-primary)  0%, var(--color-secondary) 100%);
    --circle-gradient-grey: linear-gradient(0deg, #A6A6A6 0%, #848484 100%);
    --circle-gradient-grey-reverse: linear-gradient(180deg, #A6A6A6 0%, #848484 100%);
}

.circle-icon{
    width: calc(64rem/16);
    height: calc(64rem/16);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.circle-icon__inner{
    width: calc(58rem/16);
    height: calc(58rem/16);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle-icon__icon{
    font-size: calc(23rem/16);
}

.circle-icon--small {
     width: calc(13rem/16);
     height: calc(13rem/16);
 }

.circle-icon--small .circle-icon__inner {
    width: calc(11rem/16);
    height: calc(11rem/16);
}

.circle-icon--small .circle-icon__icon {
    font-size: calc(7rem/16);
}

.circle-icon--medium-large {
    width: calc(40rem/16);
    height: calc(40rem/16);
}

.circle-icon--medium-large .circle-icon__inner {
    width: calc(37rem/16);
    height: calc(37rem/16);
}

.circle-icon--medium-large .circle-icon__icon {
    font-size: calc(15rem/16);
}

.circle-icon--medium {
    width: calc(22rem/16);
    height: calc(22rem/16);
}

.circle-icon--medium .circle-icon__inner {
    width: calc(19rem/16);
    height: calc(19rem/16);
}
.circle-icon--medium .circle-icon__icon{
    font-size: calc(8rem/16);
}

.circle-icon--large {
    width: calc(80rem/16);
    height: calc(80rem/16);
    @media screen and (max-width: 767px) {
        width: calc(35rem/16);
        height: calc(35rem/16);
    }
}

.circle-icon--large .circle-icon__inner {
    width: calc(74rem/16);
    height: calc(74rem/16);
    @media screen and (max-width: 767px) {
        width: calc(30rem/16);
        height: calc(30rem/16);
    }
}

.circle-icon--large .circle-icon__icon {
    font-size: calc(32rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
    }
}

.circle-icon--account {
    width: calc(35rem/16);
    height: calc(35rem/16);
}

.circle-icon--account .circle-icon__inner {
    width: calc(32rem/16);
    height: calc(32rem/16);
}

.circle-icon--account .circle-icon__icon {
    font-size: calc(14rem/16);
}

.circle-icon--large .circle-icon__icon.icon-download{
    font-size: calc(21rem/16);
}

.circle-icon__icon.icon-play{
    position: relative;
    top: calc(1rem/16);
    left: calc(2rem/16);
}

/* Colors */

.circle-icon--white{
    color: var(--color-dark-grey);
    background: var(--circle-gradient-white);
}

.circle-icon--white .circle-icon__inner{
    background: var(--circle-gradient-white-reverse);
}

.circle-icon--green{
    color: #fff;
    background: var(--circle-gradient-green);
}

.circle-icon--green .circle-icon__inner{
    background: var(--circle-gradient-green-reverse);
}

.circle-icon--red{
     color: #fff;
     background: var(--circle-gradient-red);
 }

.circle-icon--red .circle-icon__inner{
    background: var(--circle-gradient-red-reverse);
}

.circle-icon--black{
    color: #fff;
    background: var(--circle-gradient-black);
}

.circle-icon--black .circle-icon__inner{
    background: var(--circle-gradient-black-reverse);
}

.circle-icon--blue{
    color: #fff;
    background: var(--circle-gradient-blue);
}

.circle-icon--blue .circle-icon__inner{
    background: var(--circle-gradient-blue-reverse);
}

.circle-icon--yellow{
    color: #fff;
    background: var(--circle-gradient-yellow);
}

.circle-icon--yellow .circle-icon__inner{
    background: var(--circle-gradient-yellow-reverse);
}

.circle-icon--orange{
    color: #fff;
    background: var(--circle-gradient-orange);
}

.circle-icon--orange .circle-icon__inner{
    background: var(--circle-gradient-orange-reverse);
}
.circle-icon--primary{
    color: #fff;
    background: var(--circle-gradient-primary);
}
.circle-icon--primary .circle-icon__inner{
    background: var(--circle-gradient-primary-reverse);
}

.form-group .circle-icon__icon.icon-user{
    font-size: calc(16rem/16);
}

.form-group .circle-icon__icon.icon-mail{
    font-size: calc(12rem/16);
}

.form-group .circle-icon__icon.icon-lock{
    font-size: calc(14rem/16);
}

.form-group .circle-icon__icon.icon-bill-adress,
.form-group .circle-icon__icon.icon-delivery-adress{
    font-size: calc(20rem/16);
    margin-left: calc(2rem/16);
}

.circle-icon--blue-black {
    background: url('/static/img/circle-background/blue-black.svg');
    background-size:cover;
    color:#fff;
}
.circle-icon--red-black {
    background: url('/static/img/circle-background/red-black.svg');
    background-size:cover;
    color:#fff;
}
.circle-icon--red-blue {
    background: url('/static/img/circle-background/red-blue.svg');
    background-size:cover;
    color:#fff;
}