.partner-small-area{
    padding: calc(30rem/16) calc(15rem/16);
    background-color: #fff;

    @media screen and (min-width: 768px){
        padding: calc(30rem/16) calc(50rem/16);
    }
}

.partner-small__item{
    @media screen and (min-width: 768px){
       padding: 0 calc(32rem/16);
    }
}

.partner-small__item img{
    height: auto;
    max-width: calc(55rem/16);
    @media screen and (min-width: 768px){
        max-width: calc(75rem/16);
    }
}