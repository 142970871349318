.ab-webcam {
    & .ab-webcam--hide {
        display: none;
    }

    & .ab-webcam--hide-cta-slide ~ .ab-webcam--hide-sibling .cta-slide {
        display: none !important;
    }

    & .ab-webcam--show-cta-slide .cta-slide {
        display: block !important;
    }

    & .ab-webcam--show {
        display: block !important;
    }
}

.ab-webcam--show-cta-slide .cta-slide {
    display: none !important;
}


.ab-mobile-image-slider-area--show{
    display: none;
}

.ab-payment-types__cart {
    display: none;
}

.ab-icon--hide {
    display: block;
}

.ab-progress-bar__item-num {
    display: none;
}

.ab-progress-bar__title-block--show {
    display: none;
}

.ab-progress-bar__title-block--hide {
    display: block;
}

.ab-usp-cart__list--hide {
    display: block;
}

.ab-usp-cart__list--show {
    display: none;
}

@media screen and (max-width: 767px){
    .box-button--is-fixed-mobile {
        transform: translateY(100%);
        transition: transform 300ms ease-in-out;
        &.shown {
            transform: translateY(0);
        }
    }

    .ab-progress-bar {
        .ab-progress-bar__item {
            width: calc(35rem/16) !important;
            height: calc(35rem/16) !important;
            background-color: white !important;
            z-index: 1;

            display: flex ;
            justify-content: center;
            align-items: center;
            position: relative;
        }

        .ab-progress-bar__item-num {
            display: block;
            z-index: 10;
            color: black;
            font-size: calc(14rem/16);
            color: var(--color-grey);
        }

        .ab-progress-bar__item-num--black {
            color: black;
        }

        .ab-progress-bar__icon {
            background-color: var(--color-primary);
            color: white !important;
            font-size: calc(6rem/16);

            border-radius: 50%;
            border: none;
            width: calc(12rem/16);
            height: calc(12rem/16);
            padding: calc(6rem/16);
            z-index: 10;

            display: flex !important;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 0;
            bottom: 0;
        }

        .ab-icon--hide {
            display: none;
        }

        .ab-progress-bar__title-block--hide {
            display: none;
        }

        .ab-progress-bar__title-block--show {
            display: block;
            margin-top: calc(32rem/16);
        }

        .ab-progress-bar__text {
            display: block !important;
            margin-top: calc(55rem/16);
            margin-left: calc(-25rem/16);
        }
    }

    .ab-usp-cart {
        .ab-usp-cart__list--hide {
            display: none;
        }

        .ab-usp-cart__list--show {
            display: block;
            background-color: white;
            padding: calc(12rem/16) calc(10rem/16) calc(20rem/16);
        }

        .ab-usp-cart__list-text {
            font-weight: bold;
            letter-spacing: 1px;
            margin-top: calc(7rem/16);
        }

        .ab-usp-cart__icon {
            border: none;
            font-size: calc(24rem/16);
            color: var(--color-primary);
        }
    }
}

.ab-availability {
    & .ab-availability--hide {
        display: none !important;
    }

    & .ab-availability--show {
        display: block !important;
    }
}

/* task: WF_1700845*/

    #main-content:not(.ab-product-info) .ab-product-info--collapsed{
        display: flex;
        visibility: visible;
        height: auto;
        align-items: center;
    }

    @media (min-width: 768px){
        .collapse.dont-collapse-md {
            display: flex;
            visibility: visible;
            height: auto;
            align-items: center;
        }
    }

.ab-product-info {
     .ab-profile-info-button__wrapper {
         padding-top: calc(10rem/16);
         position: relative;
         width: 100%;
     }
     .ab-profile-info-button__wrapper::after {
         content: '';
         opacity: 1;
         transition: opacity 0.1s ease-in-out;
         position: absolute;
         top: -30px;
         left: 0;
         width: 100%;
         height: 130%;
         background: linear-gradient(to top, white, transparent);
         pointer-events: none;
     }
     .collapse.show ~ .ab-profile-info-button__wrapper::after,
     .collapsing ~ .ab-profile-info-button__wrapper::after {
         opacity: 0;
         transition: opacity 0.1s ease-in-out;
     }
     .ab-product-info--show-sm {
         display: none;
     }
     .ab-product-info--hide {
         display: none;
     }
 }

@media screen and (min-width: 768px){
    .ab-product-info .ab-product-info--show-md {
        display: block !important;
    }
}
@media screen and (max-width: 767px){
    .ab-product-info .ab-profile-info-button__wrapper {
        padding-top: calc(10rem/16);
    }
    .ab-product-info .ab-product-info--show-sm {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        padding-bottom: 0;
    }
    .ab-product-info .ab-product-info--show-md {
        display: none;
    }
    .ab-product-info .ab-product-info__title-block .title-block__title{
        font-size: calc(25rem/16);
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .ab-product-info .ab-product-info__content-block{
        margin-top: calc(10rem/16);
    }
}
/* task: WF_1700845 end*/
