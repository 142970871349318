.contact-card__title{
    font-size: calc(18rem/16);
    letter-spacing: calc(2.7rem/16);
    line-height: calc(23/18);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    margin-bottom: calc(5rem/16);
}

.contact-card__subtitle{
    font-size: calc(12rem/16);
    letter-spacing: calc(3rem/16);
    text-transform: uppercase;
    color: var(--color-grey);
    font-family: var(--font-default);
}

.contact-card .title-block__title--red-line{
    padding-bottom: calc(12rem/16);
}

.contact-block{
    font-family: var(--font-default-bold);
}

.contact-block .btn{
    text-transform: none;
    letter-spacing: normal;
}