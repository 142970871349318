.gondola-item{
    text-align: center;
    width: calc(305rem/16);
    height: calc(420rem/16);
    background-image: url('/static/img/gondola-fill.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: calc(160rem/16);

    @media screen and (min-width: 991px) {
        width: clamp(calc(229rem/16), 16.5vw, calc(305rem/16));
        height: clamp(calc(300rem/16), 21vw, calc(420rem/16));
        padding-top: clamp(calc(100rem/16), 7vw, calc(160rem/16));
    }
    
    @media screen and (max-width: 767px){
       margin: 0 auto;
    }
}

.gondola-item--chairlift {
    background-image: url('/static/img/chairlift-fill.svg');
}

.gondola-slider:has(.gondola-item.gondola-item--chairlift) .scrolling-area__item:has(.gondola-item.gondola-item--chairlift) {
    @media screen and (min-width: 768px) {
        margin-left: 5rem;
        margin-right: 2.5rem;
    }
}

.gondola-slider:has(.gondola-item.gondola-item--chairlift) .scrolling-area__item:has(.gondola-item:not(.gondola-item--chairlift)) {
    @media screen and (min-width: 768px) {
        transform: translateY(-2rem);
    }
}

.gondola-slider:has(.gondola-item.gondola-item--chairlift) .scrolling-area__item:has(.gondola-item:not(.gondola-item--chairlift)) .gondola-item__body {
    @media screen and (min-width: 768px) {
        transform: translateY(2rem);
    }
}


.gondola-item__icon{
    font-size: calc(36rem/16);
    margin-bottom: calc(25rem/16);
    @media screen and (min-width: 1199px) {
        font-size: clamp(calc(20rem/16), 3vw, calc(36rem/16));
    }
}

.gondola-item__icon.icon-plane,
.gondola-item__icon.icon-train,
.gondola-item__icon.icon-mountain{
    font-size: calc(72rem/16);
    margin-bottom: calc(8rem/16);
    margin-top: calc(-10rem/16);
    @media screen and (min-width: 991px) {
        font-size: clamp(calc(30rem/16), 3.5vw, calc(72rem/16));
    }
}


.gondola-item__title{
    position: relative;
    font-size: calc(22rem/16);
    letter-spacing: calc(4rem/16);
    line-height: calc(25/22);
    padding: 0 calc(15rem/16) calc(15rem/16);
    @media screen and (min-width: 991px) {
        font-size: clamp(calc(16rem/16), 1vw, calc(22rem/16));
        letter-spacing: clamp(calc(1rem/16), 0.25vw, calc(4rem/16));
    }
}

.gondola-item__title:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(40rem/16);
    height: calc(4rem/16);
    background-color: var(--color-primary);
}

.gondola-item__text{
    color: var(--color-grey);
    margin-top: calc(20rem/16);
    padding: 0 calc(15rem/16);
}

.gondola-item__link {
    text-transform: uppercase;
    font-size: calc(12rem / 16);
    letter-spacing: calc(3rem / 16);
    margin-top: auto;
    margin-bottom: calc(40rem / 16);
    font-family: var(--font-default-bold);
    padding-bottom: calc(3rem/16);
    position: relative;
}

.gondola-item__link:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: calc(2rem/16);
    height: calc(2rem/16);
    background-color: var(--color-default);
}

.gondola-slider{
    position: relative;
}

.gondola-slider:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(20rem/16);
    background-color: #fff;
}