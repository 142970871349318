:root {
    --main-navbar-btn-width-xxl: calc(100rem/16);
    --main-navbar-btn-width: calc(70rem/16);
    --main-navbar-btn-width-xs: calc(58rem/16);

    --main-navbar-height-xxl: calc(100rem/16);
    --main-navbar-height: calc(70rem/16);
    --main-navbar-height-xs: calc(60rem/16);

    --main-navbar-checkout-height-xs: calc(100rem/16);
}

.main-navbar{
    height: auto;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: block;

    @media screen and (min-width: 768px){
        position: fixed;
        height: var(--main-navbar-height);
    }

    @media screen and (min-width: 1400px){
        height: var(--main-navbar-height-xxl);
    }

    @media screen and (max-width: 767px){
        /*max-height: var(--main-navbar-height-xs);*/
        background-color: #fff;
    }
}


.main-navbar-container.is-affix{
    @media screen and (max-width: 767px){
        position: fixed;
        top: 0;
    }
}

.main-navbar-container{
    display: flex;
    flex-direction: row;
    width: 100%;

    @media screen and (max-width: 767px){
        max-height: var(--main-navbar-height-xs);
        background-color: #fff;
    }
}

.main-navbar--checkout .main-navbar-container {
    @media screen and (max-width: 767px){
        max-height: var(--main-navbar-checkout-height-xs);
    }
}

.affix-navbar{
    transform: none!important; /* because of affix */
    @media screen and (min-width: 768px){
        will-change: background;
        background: linear-gradient(to bottom,  rgba(240,240,240,0.9) 0%,rgba(240,240,240,0.85) 27%,rgba(240,240,240,0.8) 42%,rgba(240,240,240,0.55) 70%,rgba(240,240,240,0.35) 84%,rgba(240,240,240,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

}

.experience-area--is-active .affix-navbar {
    @media screen and (min-width: 768px){
        background: linear-gradient(180deg, rgba(25, 25, 25, 0.8) 0%, rgba(240,240,240,0) 100%);
    }
}
.experience-area--is-active .dropdown--navbar {
    color:#fff;
}

.main-navbar__left{
    @media screen and (max-width: 767px){
       display: flex;
        align-items: center;
    }
}

.main-navbar__brand{
    position: relative;
    z-index: 30;
    display: block;
}

.main-navbar__brand img{
    @media screen and (min-width: 1400px){
        height: var(--main-navbar-height-xxl);
    }
    @media screen and (min-width: 1200px){
        height: var(--main-navbar-height);
        width: auto;
    }
    @media screen and (min-width: 768px) and (max-width: 1200px){
        width: calc(170rem/16);
        height: auto;
    }
    
    @media screen and (max-width: 767px){
        width: auto;
        max-width: calc(140rem/16);
        height: calc(60rem/16);
        max-height: calc(60rem/16);
    }

    /* Ausnahme */
    @media screen and (max-width: 360px){
        width: 100%;
        height: auto;
    }
}

.main-navbar__right{
    display: flex;
}

.main-navbar__btn-list-item{
    @media screen and (max-width: 767px){
        min-height: calc(58rem/16);
        border-left: 1px solid #f0f0f0;
    }
}

.main-navbar__btn-list-item:not(:has(.dropdown)) {
    overflow: hidden;
}

.main-navbar__btn{
    width: var(--main-navbar-btn-width-xs);
    height: 100%;
    border: none;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: calc(10rem/16);
    transition: background-color 0.2s ease;
    position: relative;

    @media screen and (min-width: 1400px){
        width: var(--main-navbar-btn-width-xxl);
        height: var(--main-navbar-height-xxl);
    }

    @media screen and (min-width: 768px){
        width: var(--main-navbar-btn-width);
        height: var(--main-navbar-height);
        font-size: calc(18rem/16);
        border-right: 1px solid #f0f0f0;
    }

    @media screen and (max-width: 767px){
       height: var(--main-navbar-height-xs);
    }
}
.main-navbar__btn--big {
    @media screen and (min-width: 1400px){
        width: auto;
        padding-left: calc(80rem/16);
        padding-right: calc(80rem/16);
    }
    @media screen and (min-width: 768px){
        width: auto;
        padding-left: calc(10rem/16);
        padding-right: calc(10rem/16);
        display: flex;
        flex-direction: row;
        padding-bottom:0;
    }
}
.main-navbar__btn--big .main-navbar__btn-text {
    @media screen and (min-width: 768px) {
        position: relative;
        bottom: 0;
        padding-left: calc(10rem / 16);
    }
}

.main-navbar__btn-text{
    font-family: var(--font-default-bold);
    letter-spacing: calc(1.5rem/16);
    text-transform: uppercase;
    font-size: calc(8rem/16);
    position: absolute;
    bottom: calc(7rem/16);
    color: var(--color-grey);
    transition: color 0.3s ease;
    text-align: center;

    @media screen and (min-width: 1400px){
        font-size: calc(10rem/16);
        bottom: calc(16rem/16);
    }

    @media screen and (min-width: 768px){
        font-size: calc(9rem/16);
        bottom: calc(10rem/16);
    }
}

.main-navbar__btn.is-open{
    z-index: 200;
    position: relative;
}

.main-navbar__btn .icon-svg{
    width: 18px;
    height: 18px;
}

.main-navbar__link-list {
    display: flex;
}
.main-navbar__link-list-item {
    font-size: calc(16rem/16);
    letter-spacing: calc(2rem/16);
    text-transform: uppercase;
    padding-left: calc(60rem/16);
    font-family: var(--font-default-bold);
    color: var(--color-text-default);
    opacity: .2;
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.main-navbar__link-list-item.is-active {
    opacity: 1;
}

.main-navbar__cart-count,
.main-navbar__notification-count{
    height: calc(16rem/16);
    width: calc(16rem/16);
    background-color: var(--color-primary);
    color:#fff;
    border-radius: 50%;
    font-size: calc(10rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-default-bold);
    position: absolute;
    top: calc(5rem/16);
    right: calc(5rem/16);
    @media screen and (min-width: 768px) {
        top: calc(20rem/16);
        right: calc(20rem/16);
    }
}

.main-navbar__profile-img {
    flex: 0 0 auto;
    width: calc(30rem /16);
    height: calc(30rem /16);
    @media screen and (min-width: 1400px) {
        width: calc(50rem /16);
        height: calc(50rem /16);
    }
}

.main-navbar__profile-img img {
    border-radius: 50%;
}

.main-navbar__overlay {
    position: fixed;
    bottom:0;
    box-shadow: 0 calc(42rem / 16) calc(60rem / 16) 0 rgba(0, 0, 0, 0.5);
    right: 0;
    height: auto;
    overflow-y: auto;
    width: 100%;
    max-width: calc(610rem/16);
    background-color: var(--color-light-grey);
    z-index: 100;
    padding: calc(30rem/16) calc(20rem/16) calc(40rem/16);
    top: calc(60rem/16);
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    transform-origin: right top;
    transition: opacity .1s .1s,visibility .1s .1s,transform .3s ease-in-out;

    @media screen and (min-width: 768px){
        top: calc(70rem/16);
        padding: calc(60rem/16) calc(30rem/16) calc(50rem/16);
    }

    @media screen and (min-width: 1400px){
        top: calc(100rem/16);
    }
}

.main-navbar__overlay--profile {
    padding: 0!important;
    position: fixed;
    box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(0, 0, 0, 0.2);
    right: 0;
    overflow-y: auto;
    width: 100%;
    max-width: calc(650rem/16);
    background-color: #fff;
    z-index: 100;
    top: calc(60rem/16);
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    transform-origin: right top;
    transition: opacity .1s .1s,visibility .1s .1s,transform .3s ease-in-out;

    @media screen and (min-width: 768px){
        right: calc(210rem/16);
        height: fit-content;
    }

    @media screen and (min-width: 1400px){
        top: calc(100rem/16);
        right: calc(300rem/16);
    }
}

.main-navbar__overlay.show-overlay {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: opacity .1s .2s,visibility .1s .1ms,transform .4s ease-in-out;
}

.ticket-btn{
    border: none;
    box-shadow: none;
    font-family: var(--font-default-bold);
    font-size: calc(12rem/16);
    letter-spacing: calc(2.5rem/16);
    text-transform: uppercase;
    transition: background-color 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--main-navbar-height);
    padding: calc(15rem/16) calc(10rem/16);


    @media screen and (min-width: 1400px){
        height: var(--main-navbar-height-xxl);
        padding: calc(15rem/16) calc(43rem/16);
        font-size: calc(14rem/16);
        letter-spacing: calc(4.38rem/16);
    }

    @media screen and (min-width: 768px) {
        font-family: var(--font-default-bold);
        font-size: calc(12rem/16);
        letter-spacing: calc(3rem/16);
        text-transform: uppercase;

        height: var(--main-navbar-height);
        padding: calc(15rem/16) calc(20rem/16);
    }

    @media screen and (max-width: 767px){
       display: flex;
        flex-direction: column;
        width: calc(100rem/16);
        align-items: center;
        justify-content: center;
        height: calc(80rem/16);
    }
}

.ticket-btn .icon{
    font-size: calc(25rem/16);
    vertical-align: calc(-8rem/16);
    margin-right: calc(5rem/16);

    @media screen and (min-width: 1400px){
        font-size: calc(29rem/16);
    }
    
    @media screen and (max-width: 767px){
       margin-bottom: calc(9rem/16);
        font-size: calc(23rem/16);
    }
}

.ticket-btn .icon-svg{
    width: calc(25rem/16);
    height: calc(25rem/16);
    fill: #fff;
    vertical-align: calc(-8rem/16);
    margin-right: calc(5rem/16);

    @media screen and (min-width: 1400px){
        width: calc(30rem/16);
        height: calc(30rem/16);
    }

    @media screen and (max-width: 767px){
        width: calc(23rem/16);
        height: calc(23rem/16);
    }
}

/* dropdown-navbar */

.dropdown--navbar .dropdown-toggle{
    transition: none;
}

.dropdown--navbar .dropdown-toggle.show{
    z-index: 11;
    position: relative;
}

.dropdown--navbar .dropdown-toggle:focus{
    box-shadow: none;
    outline: 0;
}

.dropdown--navbar .dropdown-menu{
    padding: calc(30rem/16) 0;
    box-shadow: 0 10px 80px 0 rgba(0,0,0,0.2);
    z-index: 10;
}

.dropdown--navbar .dropdown-item{
    padding: calc(16rem/16) calc(30rem/16) calc(14rem/16);
    font-size: calc(12rem/16);
    line-height: calc(15/12);
    letter-spacing: calc(3rem/16);
    text-transform: uppercase;
}

/* dropdown language */

.dropdown--language{
    @media screen and (min-width: 1400px){
        margin-left: calc(18rem/16);
    }
    
    @media screen and (max-width: 767px){
        text-align: center;
        display: inline-block;
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 99;
    }
}

.dropdown--language .dropdown-toggle{
    font-size: calc(12rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: normal;
    font-family: var(--font-default-bold);

    @media screen and (min-width: 1400px){
        width: var(--main-navbar-btn-width-xxl);
        height: var(--main-navbar-height-xxl);
    }


    @media screen and (min-width: 768px){
        width: var(--main-navbar-btn-width);
        height: var(--main-navbar-height);
        font-size: calc(14rem/16);
        font-family: var(--font-default);
    }
    
    @media screen and (max-width: 767px){
       letter-spacing: calc(3rem/16);
        padding: calc(15rem/16) calc(30rem/16);
        border: 2px solid #fff;
    }
}

.dropdown--language__toggle.dropdown-toggle:after{
    content: var(--icon-arrow-dropdown);
    font-family: iconfont;
    border: 0;
    font-size: calc(9rem/16);
    margin-left: calc(6rem/16);
    @media screen and (min-width: 768px){
       width: calc(9rem/16);
    }
}

.dropdown--language .dropdown-toggle.show{
    background-color: #fff;
}

.dropdown--language .dropdown-item{
    text-align: center;
}

.dropdown--btob-language {
    @media screen and (max-width: 767px){
        text-align: center;
        display: block;
        position: relative;
        bottom: 0;
        left: 0;
        height: calc(60rem/16);
        transform: none;
    }
}
.dropdown--btob-language .dropdown--language__toggle {
    @media screen and (max-width: 767px){
        height: calc(60rem/16);
    }
}

.dropdown--language__flag{
    width: calc(18rem/16);
    height: calc(13rem/16);
}

/* dropdown profil */

.dropdown--profil .dropdown-menu{
    padding: calc(15rem/16) 0 calc(3rem/16);

    @media screen and (min-width: 768px){
        min-width: calc(200rem/16);
    }
}



.main-navbar__toggle{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;

    background-color: var(--color-dark-grey);
    color: #fff;
    border: none;
    position: relative;
    z-index: 30;
    transition: background-color 0.2s ease;
}
.main-navbar__toggle:hover {
    background-color: #000;
    color: var(--color-dark);
}


.is-open.main-navbar__toggle{
    background-color: #fff;
    color: var(--color-dark);
}

.main-navbar__toggle-text{
    font-family: var(--font-default-bold);
    letter-spacing: calc(1rem/16);
    text-transform: uppercase;
    font-size: calc(7rem/16);
    position: absolute;
    bottom: calc(7rem/16);
    color: var(--color-grey);
    transition: color 0.3s ease;

    @media screen and (min-width: 1400px){
        font-size: calc(10rem/16);
        bottom: calc(16rem/16);
        letter-spacing: calc(2rem/16);
    }

    @media screen and (min-width: 768px){
        font-size: calc(8rem/16);
        bottom: calc(10rem/16);
        letter-spacing: calc(1rem/16);
    }
}

.main-navbar__toggle-text.close-text{
    display: none;
}

.is-open .main-navbar__toggle-text{
    display: none;
}

.is-open .main-navbar__toggle-text.close-text{
    display: block;
}

.main-navbar__toggle-line {
    background-color: #fff;
    width: calc(20rem/16);
    height: calc(2rem/16);
    margin-bottom: calc(3rem/16);
    transition: transform 0.2s ease;
}

.is-open .main-navbar__toggle-line:nth-child(1){
    transform: rotate(-45deg) translateY(1px) translateX(-3px);
    width: 21px;
}

.is-open .main-navbar__toggle-line:nth-child(2){
    transform: rotate(45deg) translateY(0px) translateX(-2px);
    width: 21px;
}

.is-open .main-navbar__toggle-line:nth-child(3){
   opacity: 0;
}

.is-open .main-navbar__toggle-line{
    background-color: var(--color-dark);
}

.main-navbar__shop-claim {
    font-family:var(--font-default-bold);
    font-size: calc(12rem/16);
    max-width: 23vw;
    padding-left: calc(20rem/16);
    @media screen and (min-width: 1400px){
        font-size: calc(16rem/16);
        padding-right: calc(20rem/16);
        padding-left: calc(20rem/16);
    }
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.main-navbar--light-grey, .main-navbar--light-grey .main-navbar-container{
    @media screen and (max-width: 767px) {
        background-color: var(--color-light-grey);
    }
}

.main-navbar__back-button {
    border: none;
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    padding: calc(10rem/16);
    font-size: calc(12rem/16);
    letter-spacing: calc(3rem/16);
    background-color: transparent;
    @media screen and (min-width: 1400px){
        padding:calc(30rem/16);
    }
    @media screen and (max-width: 767px) {
        margin-left: auto;
    }
}
.main-navbar__back-button-icon {
    font-size: calc(10rem/16);
}

.main-navbar--checkout .main-navbar__brand img{
    @media screen and (min-width: 768px) and (max-width: 1200px){
        margin-top: 0;
    }
}

.main-navbar-search__close-icon.icon, .main-navbar-cart__close-icon.icon{
    display: none;
}

.is-open>.main-navbar-search__close-icon.icon, .is-open>.main-navbar-cart__close-icon.icon{
    display: block;
}

.is-open>.main-navbar-search__icon, .is-open>.main-navbar-cart__icon{
    display: none;
}

.main-navbar-search__close-text, .main-navbar-search__open-text,
.main-navbar-cart__close-text, .main-navbar-cart__open-text{
    font-size: calc(7rem/16);
    letter-spacing: calc(1rem/16);
    line-height: 1;
    font-family: var(--font-default-bold);
    color: var(--color-grey);
    text-transform: uppercase;
    position: absolute;
    bottom: calc(7rem/16);
    display: none;

    @media screen and (min-width: 1400px){
        font-size: calc(10rem/16);
        bottom: calc(16rem/16);
    }

    @media screen and (min-width: 768px){
        font-size: calc(9rem/16);
        bottom: calc(10rem/16);
    }

}

.main-navbar-search__open-text, .main-navbar-cart__open-text {
    display: block;
}
.is-open>.main-navbar-search__open-text, .is-open>.main-navbar-cart__open-text{
    display:none;
}
.is-open>.main-navbar-search__close-text, .is-open>.main-navbar-cart__close-text{
    display: block;
}