.wysiwyg table > thead {
    background-color:var(--color-secondary);
}
.wysiwyg table > thead th {
    background-color:var(--color-secondary);
    text-transform:none;
    letter-spacing:0;
}
.wysiwyg table > thead th span:not(.icon) {
    font-family:var(--font-default);
    text-transform:uppercase;
}