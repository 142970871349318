.vip-pass__info {
    line-height: calc(20rem / 16);
}

.vip-pass__input {
    display: inline-block;
    position: relative;
    width: 100%;
    @media screen and (min-width: 768px) {
        width: calc(520rem / 16);
        max-width: calc(520rem / 16);
    }
    @media screen and (max-width: 767px){
        margin-bottom: calc(16rem /16);
    }
}

.vip-pass__btn {
    @media screen and (min-width: 768px){
        margin-left: calc(30rem / 16);
    }
    padding: calc(15rem/16) calc(72rem/16) calc(12rem/16);
}
.vip-pass__input-group {
    @media screen and (min-width: 768px){
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.vip-pass__headline,
.vip-pass__subline {
    text-transform: uppercase;
    letter-spacing: calc(2rem/16);
    font-size: calc(16rem/16);
}

.vip-pass__headline {
    font-family: var(--font-default-bold);
}

.vip-pass__item {
    position: relative;
    margin-top: calc(20rem / 16);
    @media screen and (min-width: 768px) {
        margin-top: calc(40rem / 16);
    }
}

.vip-pass__item + .vip-pass__item {
    margin-top: calc(40rem /16);
}

.vip-pass__delete {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(12rem / 16);
    display: flex;
    align-items: center;
    padding: calc(8rem /16);
}

.vip-pass__additional-text {
    margin-top: calc(20rem /16);
}

.vip-pass__help-text{
    font-size: calc(12rem /16);
    text-transform: uppercase;
    letter-spacing: calc(3rem /16);
    font-family: var(--font-default-bold);
    line-height: 1;
    @media screen and (min-width: 768px){
        margin-left: calc(30rem / 16);
    }
}

.vip-pass--valid .vip-pass__help-text {
    color: var(--color-success);
}

.vip-pass--valid .vip-pass__input {
    box-shadow: 0 0 0 calc(2.5rem /16) var(--color-success);
}

.vip-pass--invalid .vip-pass__help-text {
    color: var(--color-red-light);
}

.vip-pass--invalid .vip-pass__input {
    box-shadow: 0 0 0 calc(2.5rem /16) var(--color-red-light);
}

.vip-pass__time-info-hl {
    color: var(--color-red-light);
    font-size: calc(12rem /16);
    text-transform: uppercase;
    letter-spacing: calc(3rem /16);
    font-family: var(--font-default-bold);
    line-height: 1;
    margin-top: calc(40rem /16);
}
.vip-pass__time-info-text {
    margin-top: calc(15rem /16);
    line-height: 2;
    opacity: 0.5;
}