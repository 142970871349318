.webcam-item__threesixty-wrapper {
    overflow: hidden;
    position: relative;
}
.webcam-item__threesixty-img {
    background-repeat: repeat-x;
    height: 100%;
    width: 900%;
    background-size: contain;
    animation: webcamSlide 60s linear infinite;
    @media screen and (max-width: 767px) {
        height: 100%;
        width: 900%;
        background-size: contain;
        animation: webcamSlideMobile 60s linear infinite;
    }
}
.webcam-item:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
    top:0;
    left:0;
    transition: opacity 0.2s ease;
    z-index: 1;
}
.webcam-item:hover:before {
    opacity:0.6;
}
.webcam-item__body {
    z-index: 2;
    position: absolute;
    color:#fff;
    bottom:0;
    width: 100%;
    display: flex;
    align-items:flex-end;
    height: 100%;
    padding: calc(20rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(8rem/16);
    }
}
.webcam-item__title {
    font-size: calc(22rem/16);
    font-family: var(--font-default-bold);
    margin-bottom:0;
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
        letter-spacing: 0;
    }
}
.webcam-item__subtitle {
    letter-spacing: calc(2.8rem / 16);
    font-size: calc(12rem / 16);
    margin-bottom: calc(8rem / 16);
    @media screen and (max-width: 767px) {
        margin-bottom:0;
    }
}
.webcam-item__icon {
    font-size: calc(35rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
    }
}

@keyframes webcamSlide{
     0%{
         transform: translate3d(0, 0, 0);
     }
     100%{
         transform: translate3d(-1920px, 0, 0);
     }
}
@keyframes webcamSlideMobile{
    0%{
        transform: translate3d(0, 0, 0);
    }
    100%{
        transform: translate3d(-560px, 0, 0);
    }
}