.experience-area {
    transition: background-color 0.2s linear;
    will-change: background-color;
    padding: calc(200rem/16) 0;
    @media screen and (max-width: 767px) {
        padding: calc(100rem/16) 0;
    }
}
.experience-area--is-active {
    background-color:var(--color-dark);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.experience-area--is-active .experience-area__title {
    color:#fff;
}
.experience-area__headlines {
    height: calc(100vh - 200px);
}
.experience-area__title {
    max-width: calc(1514rem/16);
    margin: 0 auto;
    font-size: clamp(calc(22rem/16), 4.1vw, calc(80rem/16));
    text-transform: uppercase;
    letter-spacing: 2.07vw;
    padding-left: calc(15rem/16);
    padding-right: calc(15rem/16);
}
.experience-area__bg-claim {
    position: absolute;
    opacity:1;
    bottom: 0;
    width: 100%;
    @media screen and (max-width: 767px) {
        font-size: 18vw;
    }
}
.experience-area__title--second-line {
    text-align: right;
    display: block;
}
.experience-area__inner {
    position: relative;
    z-index: 9;
    margin-top: -25vh;
    padding: 0 calc(15rem/16);
    @media screen and (max-width: 767px) {
        margin-top: -50vh;
    }
}
.experience-area__col--left {
    padding-top: 15vh;
}
@media screen and (min-width: 768px) {
    .experience-area__col--left .experience-area__item:nth-of-type(even) {
        transform: translateX(-3vw);
    }
    .experience-area__col--right .experience-area__item:nth-of-type(even) {
        transform: translateX(3vw);
    }
}
.experience-area__col {
    margin-top: -10vh;
    @media screen and (max-width: 767px) {
        margin-top: calc(-10rem/16);
    }
}
.experience-area__col > .experience-area__item {
    margin-top: 10vh;
    @media screen and (max-width: 767px) {
        margin-top: calc(10rem/16);
    }
}