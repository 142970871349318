.qr-scanner__video-wrapper {
    margin-top: calc(10rem/16);
}
.qr-scanner__video {
    object-fit:cover;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
.qr-scanner__btn-wrapper {
    padding:0;
    border: none;
}
.qr-scanner__btn {
    height: 100%;
    transition: background-color 0.2s ease, color 0.2s ease;
}

@media screen and (max-width: 767px) {
    .qr-scanner__btn-text {
        display: none;
        margin-left: 0;
    }
}

.qr-scanner__btn-text {
    font-size: calc(10rem / 16);
    margin-left: calc(5rem / 16);
}

.qr-scanner__btn:hover {
    color: #fff;
    background-color: #343a40;
}
.qr-scanner__preview {
    position: relative;
}
.qr-scanner__preview-close {
    position: absolute;
    padding: calc(10rem/16);
    font-size: calc(20rem/16);
    height: calc(38rem/16);
    line-height:1;
    width: calc(38rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    right:calc(5rem/16);
    z-index: 9;
    color: black;
    border: none;
    border-radius: 0;
    top: calc(5rem/16);
}

.qr-scanner__preview-close .icon{
    font-size: 14px;
}
.qr-scanner__link-wrapper {
    color: #94999f;
    font-size: calc(14rem/16);
    border: none;
    box-shadow: none;
    background-color: transparent;
    margin-left: auto;
    display:block;
    text-decoration: underline;
    transition: color 0.2s ease;
    margin-top: calc(5rem/16);
}
.qr-scanner__link-wrapper:hover {
    color: #171619;
}

.qr-scanner .scan-region-highlight,
.qr-scanner .code-outline-highlight,
.qr-scanner .scan-region-highlight-svg{
    stroke: var(--color-primary) !important;
}

.qr-scanner .scan-region-highlight-svg {
    scale: 0.45;
    stroke-width: 8 !important;
}


