.newsletter-area {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    gap: 0 0;
    grid-template-areas:
    "newsletter-area__left"
    "newsletter-area__right";

    @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 0 0;
        grid-template-areas:
    "newsletter-area__left newsletter-area__right";
    }
    overflow: hidden;
}

.newsletter-area.newsletter-area--not-registered {
    overflow: visible;
}

.newsletter-area__left {
    z-index: 1;
    @media screen and (min-width: 768px) {
        box-shadow: 0 calc(42rem / 16) calc(60rem / 16) 0 rgba(0, 0, 0, 0.5);
    }
}

.newsletter-area--not-registered .newsletter-area__left {
    background-color: var(--color-default);
    color: #fff;
    padding: calc(15rem / 16);
    position: relative;
    overflow: hidden;
    @media screen and (min-width: 768px) {
        padding: calc(78rem / 16) clamp(calc(60rem / 16), 7vw, calc(112rem / 16));
    }
}

.newsletter-area__badge {
    position: absolute;
    top: calc(-9rem / 16);
    right: calc(-69rem / 16);
    width: calc(180rem / 16);
    height: calc(60rem / 16);
    transform: rotate(45deg);
    transform-origin: 50% 50%;
    pointer-events: auto;
    background-color: var(--color-primary);
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.newsletter-area__badge__text {
    color: #fff;
    text-transform: uppercase;
    padding: calc(20rem / 16) 0 calc(5rem /16);
    font-size: calc(10rem /16);
    line-height: calc(12rem /16);
    letter-spacing: calc(2rem /16);
    word-wrap: break-word;
    hyphens: auto;
    max-width: calc(50rem /16);
    text-align: center;
}

.newsletter-area__right {
    background-color: #fff;
    padding: calc(15rem / 16);
    @media screen and (min-width: 768px) {
        padding: calc(56rem / 16) clamp(calc(50rem / 16), 5vw, calc(82rem / 16)) calc(56rem / 16) clamp(calc(60rem / 16), 6.5vw, calc(112rem / 16));
    }
}

.newsletter-area--not-registered .newsletter-area__right {
    padding: 0;
    @media screen and (min-width: 768px) {
        margin: calc(35rem / 16) 0;
    }
}

.newsletter-area__preview-text {
    position: relative;
}

.newsletter-area--registered .newsletter-area__preview-text:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: calc(72rem / 16);
    background: linear-gradient(360deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}