.nav-tabs--primary .nav-link{
    font-size: calc(12rem/16);
    text-transform: uppercase;
    letter-spacing: calc(2.73rem/16);
    font-family: var(--font-default-bold);
    padding: calc(7rem/16) calc(17rem/16) calc(10rem/16);
    position: relative;
    overflow: hidden;
    opacity: 0.7;
    
    @media screen and (min-width: 768px){
        padding: calc(10rem/16) calc(23rem/16) calc(13rem/16);
    }
}

.nav-tabs--primary .nav-item:first-child .nav-link{
    padding-left: 0;
}


.nav-tabs--primary .nav-link:after{
    content: '';
    position: absolute;
    background-color: var(--color-primary);
    height: calc(4rem/16);
    left: calc(17rem/16);
    right: calc(17rem/16);
    bottom: 0;
    transition: transform 250ms ease;
    transform: translateY(115%);

    @media screen and (min-width: 768px){
        left: calc(23rem/16);
        right: calc(23rem/16);
    }

}

.nav-tabs--primary .nav-item:first-child .nav-link:after{
    left: 0;
}

.nav-tabs--primary .nav-link.active,
.nav-tabs--primary .nav-link:hover{
    opacity: 1;
}

.nav-tabs--primary .nav-link.active:after,
.nav-tabs--primary .nav-link:hover:after{
    transform: translateY(0);
}

.nav-tabs--btn-style {
    display: flex;
    align-items: center;
    border: none;
    @media screen and (min-width: 768px) {
        padding-left: calc(15rem/16);
        padding-right: calc(15rem/16);
    }
}
.nav-tabs--big-btn-style .nav-item__icon {
    font-size: calc(45rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(30rem/16);
    }
}
.nav-tabs--btn-style .nav-link {
    background-color:#fff;
    border-radius:0;
    border:none;
    font-size: calc(11rem/16);
    letter-spacing: calc(3rem/16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    color: var(--color-default-dark-grey);
    line-height:1;
    padding: calc(13rem/16) calc(25rem/16);
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align:center;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16) calc(15rem/16);
    }

    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);
    }
}
.nav-tabs--big-btn-style .nav-link {
    padding: calc(15rem/16) calc(25rem/16);
}

.nav-tabs--btn-style .nav-link.active {
    background-color: var(--color-default-dark-grey);
    color:#fff;
    padding: calc(18rem/16) calc(25rem/16);
    box-shadow: 0 calc(10rem/16) calc(20rem/16) 0 rgba(0,0,0,0.3);
    @media screen and (max-width: 767px) {
        box-shadow: 0 calc(10rem/16) calc(10rem/16) 0 rgba(0,0,0,0.2);
        padding: calc(13rem/16) calc(15rem/16);
    }
}
.nav-tabs--big-btn-style .nav-link.active {
    padding: calc(30rem/16);
}

.nav-tabs--btn-style .nav-link:hover{
    @media (hover: hover){
        background-color: var(--color-default-dark-grey);
        color: #fff;
    }
}

.nav-tabs--btn-style .nav-item{
    position: relative;
}

/*
.nav-tabs--btn-style .nav-link:before {
    content: '';
    position: absolute;
    top: calc(-5rem/16);
    left: 0;
    right: 0;
    bottom: calc(-6rem/16);
    background-color: var(--color-default-dark-grey);
    z-index: 1;
    transform: scaleY(0);
    transition: all 250ms ease;
    pointer-events: none;
}

.nav-tabs--btn-style .nav-link:not(.active):hover:before {
    @media (hover: hover){
        transform: scaleY(1);
    }
}

.nav-tabs--btn-style .nav-item__text{
    position: relative;
    z-index: 5;
}
*/

.nav-tabs--scroll{
    overflow-x: auto;
    overflow-y:hidden;
    flex-wrap: nowrap;
    white-space: nowrap;
    position: relative;
    z-index: 3;
}