.alert-heading {
    font-family: var(--font-default-bold);
    font-size: calc(14rem/16);
}
.alert .close {
    position: absolute;
    right:0;
    background: #fff;
    color: var(--color-text-default);
    box-shadow: none;
    border: none;
    font-size: calc(20rem/16);
    width: calc(30rem/16);
    height: calc(30rem/16);
    top:0;
}
.alert-notification--fixed {
    z-index: 9999 !important;
}
.alert {
    pointer-events: all;
}