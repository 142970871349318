.info-card {
    position: relative;
    padding-left: calc(14rem/16);
    @media screen and (min-width: 768px) {
        padding-left: calc(28rem/16);
    }
}
.info-card__inner {
    background-color:#fff;
    padding: calc(25rem/16) calc(30rem/16) calc(30rem/16) calc(30rem/16);
    @media screen and (min-width: 768px) {
        padding: calc(25rem/16) calc(40rem/16) calc(35rem/16) calc(74rem/16);
    }
}
.info-card__title {
    font-size: calc(20rem/16);
    letter-spacing: calc(5rem/16);
    line-height: calc(25/20);
    font-family: var(--font-default-bold);
    margin-bottom: calc(14rem/16);
    text-transform: uppercase;
}
.info-card__toptitle {
    font-size: calc(12rem/16);
    letter-spacing: calc(3rem/16);
    font-family: var(--font-default);
    color: var(--color-grey);
    text-transform: uppercase;
    margin-bottom: calc(5rem/16);
}
.info-card__circle-icon {
    position: absolute;
    left:0;
    top:0;
}