.partner-row__item {
    padding: calc(40rem/16);
    filter: grayscale(100%);
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
    }
}
.partner-row__item:hover {
    filter: grayscale(0);
}
.partner-row__item {
    border-left: 1px solid var(--color-middle-grey);
}
.partner-row__item:first-child {
    border-left: none;
}
.partner-row + .partner-row {
    border-top: 1px solid var(--color-middle-grey);
}

.partner-row__item-inner{
    @media screen and (min-width: 768px){
       max-width: calc(180rem/16);
        margin: 0 auto;
    }
}