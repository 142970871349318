.account-completion__area {
    padding: calc(10rem / 16) calc(15rem / 16);
    @media screen and (min-width: 768px){
        padding: calc(20rem / 16) calc(15rem / 16);
    }
}

.account-completion__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: 767px){
        padding-left: calc(30rem / 16);
    }
}

.account-completion__area.account-completion__area--percentage {
    @media screen and (max-width: 767px){
        padding-top: calc(20rem / 16);
        padding-bottom: calc(20rem / 16);
    }
}

.account-completion__item + .account-completion__item {
    margin-top: calc(20rem / 16);
}

.account-completion__title {
    font-family: var(--font-default-bold);
}

.account-completion__text {
    color: #8C8C8C;
}

.account-completion__circle-icon {
    margin-right: calc(10rem / 16);
}

.account-completion__title--open {
    text-decoration: underline;
}