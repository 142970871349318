.usp-list{
    @media screen and (min-width: 768px){
       max-width: calc(760rem/16);
    }
}

.usp-item {
    display: inline-flex;
    align-items: center;
}

.usp-item__circle {
    width: calc(30rem/16);
    height: calc(30rem/16);
    border: 2px solid var(--color-text-default);
    border-radius: 50%;
    margin-right: calc(12rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(11rem/16);
    min-width: calc(30rem/16);
}

.usp-item__icon {
    border: none;
    font-size: calc(24rem/16);
}

.usp-item__text {
    font-size: calc(12rem/16);
    line-height: calc(15/12);
}

.usp-item__text--bold {
    display: block;
    font-weight: bold !important;
    letter-spacing: 1px;
    margin-top: calc(7rem/16);
    font-size: calc(14rem/16) !important;
}

.usp-icon--show {
    color: var(--color-primary);
}

.usp-icon--smaller {
    font-size: calc(13rem/16);
}

.usp-item--padding {
    padding-left: calc(25rem/16) !important;
}