.pagination-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination{
    display: inline-flex;
}

.page-item{
    position: relative;
}


.page-link{
    position: relative;
    font-family: var(--font-default-bold);
    font-size: calc(12rem/16);
    border: 0;
    width: calc(30rem/16);
    height: calc(30rem/16);
    color: var(--color-dark);
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 768px){
        width: calc(40rem/16);
        height: calc(40rem/16);
    }
}

.page-item.active:not(.page-item--arrow) .page-link:before,
.page-item:not(.page-item--arrow) .page-link:hover:before{
    opacity: 1;
}

.page-item.active .page-link,
.page-item .page-link:hover{
    background-color: var(--color-dark);
    border: 0;
    color: #fff;
}

.page-item+.page-item{
    margin-left: calc(4rem/16);
}


.page-item--arrow .page-link:hover{
    background-color: transparent;
    color: var(--color-secondary);
}

.page-item--arrow{
    margin: 0  calc(4rem/16);
    font-size: calc(9rem/16);
}